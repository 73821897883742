import getConfig from 'next/config';
import { Session } from 'next-auth';

import { AhotuEdition, AhotuEditionPayload } from '../types';

const { publicRuntimeConfig } = getConfig();
const { RAILS_API_URL } = publicRuntimeConfig;

const getEdition = async (session: Session, editionId: string): Promise<AhotuEdition | number> => {
  if (session.user) {
    const response = await fetch(`${RAILS_API_URL}/v2/a_editions/${editionId}`, {
      method: 'GET',
      headers: {
        'x-user-email': session.user.email,
        'x-user-token': session.authenticationToken,
        'Content-Type': 'application/json'
      }
    });

    if (response?.ok) {
      return await response.json();
    }

    return Promise.reject(response.status);
  }
};

const saveEdition = async (
  session: Session,
  editionPayload: AhotuEditionPayload
): Promise<AhotuEditionPayload | { status: number; payload: AhotuEdition }> => {
  if (session.user) {
    const { id } = editionPayload.a_edition;
    const url = id ? `${RAILS_API_URL}/v2/a_editions/${id}` : `${RAILS_API_URL}/v2/a_editions`;

    const response = await fetch(url, {
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify(editionPayload),
      headers: {
        'x-user-email': session.user.email,
        'x-user-token': session.authenticationToken,
        'Content-Type': 'application/json'
      }
    });

    if (response?.ok) {
      return await response.json();
    }

    return Promise.reject({ status: response.status, payload: (await response.json()) as AhotuEdition });
  }
};

export { getEdition, saveEdition };
