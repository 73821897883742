import lowerCase from 'lodash/lowerCase';
import { useTranslation } from 'next-i18next';

interface ICurrencyProps {
  currency: string;
  className?: string;
}
const Currency = ({ currency, className }: ICurrencyProps) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <span className={'antialiased  py-1 px-2 inline-block mr-2'}>{currency}</span>
      <span>{t(`footer:currency-${lowerCase(currency)}`)}</span>
    </div>
  );
};
export default Currency;
