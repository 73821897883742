export enum Size {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'base' = 'base',
  'lg' = 'lg',
  'xl' = 'xl',
  'xl2' = '2xl',
  'xl3' = '3xl',
  'xl4' = '4xl',
  'xl5' = '5xl',
  'xl6' = '6xl',
  'xl7' = '7xl',
  'xl8' = '8xl',
  'xl9' = '9xl'
}

export enum Unit {
  'km' = 'km',
  'm' = 'm',
  'mi' = 'mi',
  'ft' = 'ft',
  'hour' = 'hour',
  'day' = 'day',
  'step' = 'step',
  'story' = 'story',
  'yard' = 'yard',
  'min' = 'min'
}

export enum Heading {
  'h1' = 'h1',
  'h2' = 'h2',
  'h3' = 'h3',
  'h4' = 'h4',
  'h5' = 'h5',
  'h6' = 'h6'
}

export enum Paragraph {
  'p1' = 'p1',
  'p2' = 'p2',
  'p3' = 'p3'
}

export enum TextSize {
  'text-sm' = 'text-sm',
  'text-base' = 'text-base',
  'text-lg' = 'text-lg',
  'text-xl' = 'text-xl',
  'text-2xl' = 'text-2xl'
}

export enum FontWeight {
  'normal' = 'normal',
  'semibold' = 'semibold',
  'bold' = 'bold'
}

export enum Side {
  'right' = 'right',
  'left' = 'left'
}

export enum StatusLevel {
  'info' = 'info',
  'warning' = 'warning'
}
