import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { routes } from '../../utils/routes';

interface NewsletterSubmittedProps {
  isKnownUser: boolean;
}
const NewsletterSubmitted = ({ isKnownUser }: NewsletterSubmittedProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <div className="ah-newsletter-container">
      <p className="text-center">
        <span className="inline font-bold ">{t('common:newsletter-submitted-title')}</span>
        {!isKnownUser && (
          <span
            className="block ah-newsletter-sign-in"
            dangerouslySetInnerHTML={{
              __html: t('common:newsletter-submitted-sign-in', { url: routes.members.registration.chooseRole[locale] })
            }}></span>
        )}
      </p>
    </div>
  );
};

export default NewsletterSubmitted;
