import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';

import { COOKIE_CURRENCY_KEY, MenuLevel } from '../../../lib/globale/constantes';
import { Side } from '../../../types/enums';
import { SportType } from '../../../types/referenceTypes';
import AhotuLink from '../../atoms/AhotuLink';
import AhotwoProperty from '../../atoms/AhotwoProperty';
import { Separator } from '../../atoms/Separator';
import { ArrowRightThinIcon } from '../../icons/arrow';
import { GlobeIcon } from '../../icons/icons';
import AccountNavigation from '../AccountNavigation';
import { AuthButtonGroup } from '../AuthButtonGroup';
import SettingButton from '../SettingButton';

const FirstLevelMobileNav = ({ setMobileMenu, setLevel, menu, setSportType }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const currency = getCookie(COOKIE_CURRENCY_KEY);
  const { data: session } = useSession();

  return (
    <>
      <div className="space-y-6 flex flex-col justify-between 0 h-full">
        <div className="space-y-6 flex flex-col">
          {Object.keys(menu).map((sportType: SportType, i) => (
            <AhotuLink key={i} href={menu[sportType].url} passHref prefetch={false}>
              <div
                onClick={e => {
                  e.preventDefault();
                  setLevel(MenuLevel.SPORTS);
                  setSportType(sportType);
                }}
                className="w-fit">
                <AhotwoProperty icon={ArrowRightThinIcon} iconPosition={Side.right} className="mb-0">
                  {menu[sportType].name}
                </AhotwoProperty>
              </div>
            </AhotuLink>
          ))}
          <Separator className="block" />
          {session && (
            <>
              <div>
                <AccountNavigation disablePx />
              </div>
              <Separator className="block" />
            </>
          )}
          <div onClick={() => setLevel(MenuLevel.LOCALES)}>
            <SettingButton iconLeft={GlobeIcon} iconRight={ArrowRightThinIcon} text={t(`languages:${router.locale}`)} />
          </div>
          <div onClick={() => setLevel(MenuLevel.CURRENCIES)}>
            <SettingButton symbole="$" iconRight={ArrowRightThinIcon} text={currency} />
          </div>
        </div>
        {!session && (
          <div className="absolute bottom-10 right-10 left-10">
            <AuthButtonGroup setMobileMenu={setMobileMenu} />
          </div>
        )}
      </div>
    </>
  );
};

export default FirstLevelMobileNav;
