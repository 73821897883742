const defaultLocale = 'en';
const DEBUG = process.env.NEXT_PUBLIC_SHOW_MISSING_TRANSLATIONS === 'true';

export const getLocalizedProperty = (content: { [key: string]: any }, locale: string, defaultContent = '') =>
  (content &&
    (content[locale] ||
      (content[defaultLocale] && DEBUG ? `[[ ${content[defaultLocale]} ]]` : content[defaultLocale]))) ||
  defaultContent;

export const getLocalizedObject = (
  content: { [key: string]: object },
  locale: string,
  defaultContent = {}
): { [key: string]: { [key: string]: string } } => {
  return content && (content[locale] || content[defaultLocale] || defaultContent);
};

export const getLocalizedPropertyWithinKey = (
  content: { [key: string]: any },
  key: string,
  locale: string,
  defaultLocale = 'en'
) =>
  content &&
  (content[getLocalizedKeyName(key, locale)] ||
    (DEBUG
      ? `[[ ${content[getLocalizedKeyName(key, defaultLocale)]} ]]`
      : content[getLocalizedKeyName(key, defaultLocale)]));

export const getLocalizedKeyName = (key: string, locale: string) => `${key}_${locale}`;
