import Link from 'next/link';

import { AhotuButton, IAhotuButtonProps } from './AhotuButton';

interface ILinkButtonProps extends IAhotuButtonProps {
  href: string;
  target?: '_self' | '_parent' | '_blank' | '_top';
}

export const LinkButton = ({
  fullwidth = true,
  text,
  disabled = false,
  variant = 'default',
  type = 'link',
  isLoading = false,
  target = '_self',
  className,
  iconLeft,
  iconRight,
  href,
  onClick
}: ILinkButtonProps) => {
  return (
    <Link href={href} passHref>
      <a target={target}>
        <AhotuButton
          fullwidth={fullwidth}
          text={text}
          type={type}
          disabled={disabled}
          variant={variant}
          isLoading={isLoading}
          className={className}
          iconLeft={iconLeft}
          iconRight={iconRight}
          onClick={onClick}
        />
      </a>
    </Link>
  );
};
