import { SignInResponse } from 'next-auth/react';
import { FormEvent } from 'react';

import clsxm from '../../lib/clsxm';
import * as gtag from '../../lib/ga4/gtag';
import { Icon } from '../../types/Icon';
import { AhotuLoadingSpinner } from './AhotuLoadingSpinner';

export interface IAhotuButtonProps {
  fullwidth?: boolean;
  text?: string;
  type: 'button' | 'submit' | 'reset' | 'link';
  variant?: 'default' | 'stroke' | 'white';
  disabled?: boolean;
  slim?: boolean;
  isLoading?: boolean;
  onClick?: (e: FormEvent) => void | Promise<SignInResponse> | Promise<boolean> | Promise<void>;
  className?: string;
  iconClassName?: string;
  iconLeft?: Icon;
  iconRight?: Icon;
  trackingData?: { [key: string]: string };
}

export const AhotuButton = ({
  fullwidth = true,
  text,
  type,
  onClick,
  disabled = false,
  variant = 'default',
  isLoading = false,
  className,
  iconClassName,
  iconLeft,
  iconRight,
  slim = false,
  trackingData
}: IAhotuButtonProps) => {
  const IconLeft = iconLeft;
  const IconRight = iconRight;
  const color = variant === 'default' ? '#FFFFFF' : '#202228';
  const Comp = type === 'link' ? 'div' : 'button';

  const buttonClick = (event: FormEvent) => {
    gtag.sendEvent('ahotu_button_click', { className, type, text, ...trackingData });
    return onClick && onClick(event);
  };

  return (
    <Comp
      className={clsxm(
        'h-14 px-12 rounded-full font-semibold',
        slim && 'h-10 px-4',
        type === 'link' && 'flex justify-center items-center cursor-pointer',
        fullwidth && 'w-full',
        !disabled && [
          variant === 'default' && 'bg-softblack hover:bg-gray-90 text-white',
          (variant === 'stroke' || variant === 'white') && 'bg-white text text-softblack hover:bg-gray-10',
          variant === 'stroke' && 'border-2 border-softblack'
        ],
        disabled === true && 'bg-gray-40 text-white',
        className
      )}
      {...(type !== 'link' ? { type: type } : {})}
      disabled={disabled || isLoading}
      onClick={buttonClick}>
      <div className="flex justify-center">
        {isLoading ? (
          <AhotuLoadingSpinner />
        ) : (
          <div className="flex flex-row flex-nowrap items-center space-x-2">
            {IconLeft && <IconLeft className={clsxm('h-5 w-5', iconClassName)} fill={color} />}
            {text && <div className="whitespace-nowrap">{text}</div>}
            {IconRight && <IconRight className={clsxm('h-5 w-5', iconClassName)} fill={color} />}
          </div>
        )}
      </div>
    </Comp>
  );
};
