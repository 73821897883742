import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { getLocalizedProperty } from '../../lib/localization';
import { routes } from '../../utils/routes';
import { AhotuButton } from '../atoms/AhotuButton';
import { LinkButton } from '../atoms/LinkButton';
import { Paragraph } from '../atoms/Paragraph';

export const AuthButtonGroup = ({ setMobileMenu }) => {
  const { t } = useTranslation();
  const { data: session } = useSession();
  const router = useRouter();

  const logout = () => {
    setMobileMenu(false);
    signOut({ callbackUrl: router.asPath });
  };

  return (
    <>
      {!session && (
        <>
          <Paragraph>{t('session:join-us')}</Paragraph>
          <div className="flex flex-row w-full space-x-4">
            <div className="w-1/2">
              <LinkButton
                href={getLocalizedProperty(routes.members.signIn, router.locale)}
                variant="stroke"
                type="link"
                text={t('session:log-in')}
                onClick={() => setMobileMenu(false)}
              />
            </div>
            <div className="w-1/2">
              <LinkButton
                href={getLocalizedProperty(routes.members.registration.chooseRole, router.locale)}
                type="link"
                text={t('session:create-account')}
                onClick={() => setMobileMenu(false)}
              />
            </div>
          </div>
        </>
      )}
      {session && (
        <div className="flex flex-row w-full space-x-4">
          <div className="w-1/2">
            <LinkButton
              variant="stroke"
              type="link"
              href={getLocalizedProperty(routes.profile, router.locale) + '/' + session.permalink}
              text={t('session:my-account')}
              onClick={() => setMobileMenu(false)}
            />
          </div>
          <div className="w-1/2">
            <AhotuButton type="button" onClick={logout} text={t('session:log-out')} />
          </div>
        </div>
      )}
    </>
  );
};
