import { Country, Region } from '../types';

const getCountries = async (search?: string, locale?: string, filterBy?: 'iso2' | 'iso3'): Promise<Country[]> => {
  const query =
    search && locale && filterBy ? `?${new URLSearchParams({ search, lang: locale, filterBy }).toString()}` : '';

  const response = await fetch(`/api/search/countries${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.json();
};

const getRegions = async (search): Promise<Region[]> => {
  const query = `?${new URLSearchParams({ search }).toString()}`;

  const response = await fetch(`/api/search/regions${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.json();
};

export { getCountries, getRegions };
