'use strict';

import { NextPageContext } from 'next/types';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import getServerSideTranslation from '~/src/utils/getServerSideTranslation';
import translatedUrl from '~/src/utils/translatedUrl';

import { clientFactory } from '../../clients/urql/client';
import { LandingPageContent } from '../../components/organisms/LandingPageContent';
import { LandingPageQueryQuery, LandingPageQueryQueryVariables } from '../../gql/graphql';
import { landingPageQuery } from '../../gql-queries/queries/landingPageQuery';
import { translateWithFallback } from '../../gql-queries/translateWithFallback';
import { withErrorHandling } from '../../hoc/withErrorHandling';
import config from '../../lib/config';
import getLocalesData from '../../lib/getLocalesData';
import { getPartnerLogos, PartnerLogo } from '../../lib/getPartnerLogos';
import AhotuLayout from '../../templates/AhotuLayout';
import getTopNavigationMenu from '../../utils/getTopNavigationMenu';
import HomepageData from '../../utils/homePageData';
import _locale from './_routes.json';

export const landingPageRouteLocalizations = _locale;

const LandingPage = ({
  content,
  topNavigationMenu,
  otherLanguagesUrls,
  description,
  title,
  locale,
  partnerLogos
}: localProps['props']) => {
  return (
    <AhotuLayout
      partnerLogos={partnerLogos}
      animateNavigation={false}
      topNavigationMenu={topNavigationMenu}
      headProps={{
        title,
        description: description,
        schemaData: HomepageData.schemaData
      }}
      otherLanguagesUrls={otherLanguagesUrls}>
      <div>
        <LandingPageContent content={content} locale={locale} />
      </div>
    </AhotuLayout>
  );
};

export default LandingPage;

export async function getStaticPaths() {
  return {
    paths: [], // No pages pre-rendered at build time.
    fallback: 'blocking'
  };
}

export const getStaticProps = withErrorHandling(
  async (context: NextPageContext & { params: { landingPageSlug: string } }) => {
    const { locale, locales } = context;

    const client = clientFactory();

    const pageResult = await client
      .query<LandingPageQueryQuery, LandingPageQueryQueryVariables>(landingPageQuery, {
        sponsorSlug: context.params.landingPageSlug
      }) //TODO: optimize by passing locale and fetching only relevant translations
      .toPromise()
      .then(result => result);

    if (pageResult.error) {
      throw new Error(JSON.stringify(pageResult.error));
    }

    if (
      (pageResult?.data?.partners ?? 0) === 0 ||
      !pageResult?.data?.partners?.[0]?.landingPages?.length ||
      pageResult?.data?.partners?.[0]?.landingPages?.length === 0
    ) {
      return {
        redirect: {
          destination: `${translatedUrl('eventSignup', locale!)}/${context.params.landingPageSlug}`,
          permanent: true
        }
      };
    }

    const lpContent: LandingPageContentType =
      pageResult?.data?.partners[0].landingPages?.[0]?.LandingPages_id?.LandingPageContent;

    if (!lpContent) {
      throw new Error('No landing page content');
    }

    const translations = await serverSideTranslations(context.locale!, [
      'common',
      'footer',
      'header',
      'session',
      'validation'
    ]);

    if (!pageResult?.data?.partners[0].landingPages?.[0]?.LandingPages_id?.translations) {
      throw new Error('Falsy translations');
    }

    const result: localProps = {
      props: {
        locale: locale!,
        title:
          translateWithFallback(locale!, pageResult.data.partners[0].landingPages[0].LandingPages_id.translations!)
            ?.pageTitle ?? 'CMS Page title missing',
        description:
          translateWithFallback(locale!, pageResult.data.partners[0].landingPages[0].LandingPages_id.translations!)
            ?.metaPageDescription ?? 'CMS description missing',
        content: lpContent,
        partnerLogos: await getPartnerLogos(),
        topNavigationMenu: await getTopNavigationMenu(locale),
        otherLanguagesUrls: (
          await Promise.all(
            locales!.map(async lang => {
              const languageName = await getServerSideTranslation(lang, `languages`, lang);
              return {
                href: `/${_locale['/'][`${lang}`]}/${context.params.landingPageSlug}`,
                locale: lang,
                name: languageName,
                selected: locale === lang
              };
            })
          )
        ).map(result => ({
          href: result.href,
          locale: result.locale,
          name: result.name,
          selected: result.selected
        })),
        ...(translations as Translations)
      },
      revalidate: config.isrRevalidateInSeconds
    };

    return result;
  }
);

type Flatten<T> = T extends any[] ? T[number] : T;

export type ValuesOf<T extends readonly any[]> = T[number];

export type LandingPageContentType = NonNullable<
  NonNullable<Flatten<LandingPageQueryQuery['partners'][0]['landingPages']>>['LandingPages_id']
>['LandingPageContent'];

export type NonNullableFields<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

type Translations = NonNullableFields<
  Awaited<Promise<PromiseLike<ReturnType<NonNullable<typeof serverSideTranslations>>>>>
>;

type localProps = {
  props: {
    locale: string;
    title: string;
    description: string;
    content: LandingPageContentType;
    topNavigationMenu: Awaited<Promise<PromiseLike<ReturnType<typeof getTopNavigationMenu>>>>;
    otherLanguagesUrls: Awaited<Promise<PromiseLike<ReturnType<typeof getLocalesData>>>>;
    partnerLogos: PartnerLogo[];
  } & Translations;
  revalidate: number;
};
