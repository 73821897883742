import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import clsxm from '../../lib/clsxm';

interface IPanelSliderMobile {
  children: React.ReactNode;
  side: 'right' | 'left';
  id?: string;
  className?: string;
}

const animation = {
  right: {
    initial: { x: '100%' },
    exit: { x: '100%' }
  },
  left: {
    initial: { x: '-100%' },
    exit: { x: '-100%' }
  }
};

const PanelSliderMobile = ({ side, children, id = '', className = '' }: IPanelSliderMobile) => {
  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const resize = () => {
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resize);
    setHeight(window.innerHeight);

    return () => {
      window.removeEventListener('resize', resize);
      document.body.classList.remove('search-open-mobile');
    };
  }, []);

  return (
    <motion.div
      initial={animation[side].initial}
      animate={{ x: 0 }}
      id={id}
      transition={{
        duration: 0.3,
        ease: 'easeInOut'
      }}
      onAnimationStart={() => {
        //search open mobile class need to be remove before the panel disapear
        if (document.body.classList.value.match('search-open-mobile')) {
          document.body.classList.remove('search-open-mobile');
        }
      }}
      onAnimationComplete={() => {
        if (!document.body.classList.value.match('search-open-mobile') && isOpen === false) {
          //panel just open
          document.body.classList.add('search-open-mobile');
          setIsOpen(true);
        } else if (isOpen === true) {
          //isComplete & isOpen => panel just closed
          setIsOpen(false);
        }
      }}
      exit={animation[side].exit}
      className={clsxm('fixed w-screen top-0 left-0 px-4 pt-4 bg-white z-20 overflow-y-auto', className)}
      style={{ height: height, minHeight: height }}>
      {children}
    </motion.div>
  );
};

export default PanelSliderMobile;
