import { createContext } from 'react';

import { Member } from '../../types';

export type User = Member & {
  role?: string;
  organizations: Member[];
  location: string;
};

export type Organization = Member;

interface UserContextData {
  user: User | null;
  setUserData: (data: User) => void;
}

const UserContext = createContext<UserContextData | null>(null);

export default UserContext;
