import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NEWSLETTER_EMAIL_KEY } from '../../constants';
import { AhotuSection } from '../../templates/AhotuSection';
import NewsletterForm from '../molecules/NewsletterForm';
import NewsletterSubmitted from '../molecules/NewsletterSubmitted';

const NewsletterSection = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isKnownUser, setIsKnownUser] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = async (values, setFieldError) => {
    setIsSubmitting(true);
    //save email

    const response = await fetch('/api/auth/newsletter', {
      method: 'POST',
      body: JSON.stringify({
        email: values.email
      })
    });

    if (response.status > 400) {
      setFieldError('email', t('footer:error-newsleter-signup'));
      setSubmitted(false);
      setIsSubmitting(false);
      return;
    }
    const body = await response.json();
    const isKnownUser = !!body?.email;

    //TODO: change condition => if no account exist with this email
    if (!isKnownUser) {
      //Set email in session storage so if user clicks on sign up, we can get it
      window.sessionStorage.setItem(NEWSLETTER_EMAIL_KEY, values.email);
    }
    setSubmitted(true);
    setIsKnownUser(isKnownUser);
    setIsSubmitting(false);
  };

  return (
    <AhotuSection id="newsletterForm" layout="container" className="lg:mx-4 mb-16">
      {submitted ? (
        <NewsletterSubmitted isKnownUser={isKnownUser} />
      ) : (
        <NewsletterForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
      )}
    </AhotuSection>
  );
};
export default NewsletterSection;
