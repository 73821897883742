'use strict';

import { NewTabIcon } from '../../icons/icons';
import { landingMaxWidths } from '../../organisms/LandingPageContent';
import { LinkButton } from '../LinkButton';

export const Button = ({ alignment, destination, text }: ContentButton) => {
  return (
    <div
      className={`
        flex flex-col ${landingMaxWidths}
        ${alignment === 'left' ? 'items-start' : ''}
        ${alignment === 'center' ? 'items-center' : ''}
        ${alignment === 'right' ? 'items-end' : ''}
        `}>
      <div className="w-min">
        <LinkButton
          href={destination}
          text={text}
          type={'link'}
          variant="default"
          iconRight={NewTabIcon}
          target="_blank"
        />
      </div>
    </div>
  );
};

export type ContentButton = {
  alignment: string;
  destination: string;
  text: string;
};
