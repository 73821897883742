import React from 'react';

import { translateWithFallback } from '../../gql-queries/translateWithFallback';
import { LandingPageContentType } from '../../pages/landing/[landingPageSlug]';
import { Button } from '../atoms/landing-page-content/Button';
import { Images } from '../atoms/landing-page-content/Images';
import { Intro } from '../atoms/landing-page-content/Intro';
import LinkGrid from '../atoms/landing-page-content/LinkGrid';
import { Text } from '../atoms/landing-page-content/RichText';

type imageMap = { url: string; alt: string };

export const LandingPageContent = ({ content, locale }: { locale: string; content: LandingPageContentType }) => {
  return (
    <>
      {content?.map((c, i): JSX.Element => {
        if (!c?.item?.__typename) return null;
        const typename = c?.item?.__typename;
        switch (typename) {
          case 'SpacerContent':
            return (
              <div
                key={`spacer-${i}`}
                className={`
          ${c.item?.Spacing === '16px' ? 'min-h-[16px]' : ''}
          ${c.item?.Spacing === '32px' ? 'min-h-[32px]' : ''}
          ${c.item?.Spacing === '40px' ? 'min-h-[40px]' : ''}
          ${c.item?.Spacing === '88px' ? 'min-h-[88px]' : ''}
          `.replace(/^\s+|\s+$/g, '')}
              />
            );
          case 'RichTextContent': {
            const localizedValues = translateWithFallback(locale, c.item?.translations);
            return (
              <Text
                key={`rich-${i}`}
                extraWide={!!c.item?.extraWide}
                alignment={c.item?.alignment as string}
                richText={localizedValues?.RichText as string}
              />
            );
          }
          case 'ExternalLinkButtonContent': {
            const localizedValues = translateWithFallback(locale, c.item.translations);
            return (
              localizedValues?.url && (
                <Button
                  alignment={c.item.alignment as string}
                  text={localizedValues?.buttonLabel as string}
                  destination={localizedValues?.url as string}
                />
              )
            );
          }
          case 'IntroContent': {
            const localizedValues = translateWithFallback(locale, c.item.translations);
            return (
              <Intro
                header={localizedValues?.header as string}
                subtext={localizedValues?.subtext as string}
                textAboveLogo={localizedValues?.textAboveLogo as string}
                image={c.item?.logo?.id as string}
                imageAlt={c.item?.logo?.description as string}
              />
            );
          }
          case 'ImageSetContent':
            return (
              <Images
                images={
                  c.item?.ImageSet?.map(e => ({
                    url: e?.directus_files_id?.id,
                    alt: e?.directus_files_id?.description //Not great, not localized. However, we are not aware of any sensible way to handle this in the CMS at the time of writing
                  })) as imageMap[]
                }
                padding={!c.item?.fullwidth}
                isFirstContentBlock={i === 0}
              />
            );
          case 'ImageAndLinkGrid': {
            return (
              <LinkGrid
                imagesAndLinks={c.item.GridContents.map(e => ({
                  alt: e.item.Image.description,
                  imageId: e.item.Image.id,
                  link: e.item.URL
                }))}
              />
            );
          }
        }
        return assertUnreachable(typename);
      })}
    </>
  );
};
export const landingMaxWidthsExtraWide = /*tw*/ 'mx-auto px-4 xl:max-w-[1128px]';
export const landingMaxWidths = /*tw*/ 'mx-auto px-4 lg:px-0 lg:max-w-[728px]';

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here", x);
}
