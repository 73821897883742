import { useEffect, useMemo, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

// Copy & pasted from "usehooks.ts" React hooks library => https://usehooks-ts.com/
// Adapted to our requirements with Tailwind

const config = resolveConfig(tailwindConfig);
const breakpoints = config.theme.screens;
type Breakpoints = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const useMediaQuery = (breakpoint: Breakpoints): boolean | undefined => {
  const [matches, setMatches] = useState<boolean | undefined>(undefined);
  const query = useMemo(() => {
    return `(max-width: ${Number(breakpoints[breakpoint].replace('px', '')) - 1}px)`;
  }, [breakpoint]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const handleChange = () => {
      setMatches(matchMedia.matches);
    };

    // Listen matchMedia
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener('change', handleChange);
    }

    handleChange();

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener('change', handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return matches;
};

export default useMediaQuery;
