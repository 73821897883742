import { Menu, Transition } from '@headlessui/react';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import clsxm from '../../lib/clsxm';
import { ComposantId, COOKIE_CURRENCY_KEY, CURRENCY } from '../../lib/globale/constantes';
import { Side } from '../../types/enums';
import { ArrowDownThinIcon, ArrowUpThinIcon } from '../icons/arrow';
import SettingButton from '../molecules/SettingButton';
import AhotwoProperty from './AhotwoProperty';
import Currency from './Currency';

interface IDropdownProps {
  className?: string;
}

const DropdownLinkCurrency = ({ className }: IDropdownProps) => {
  const router = useRouter();
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    const selectedCurrency = getCookie(COOKIE_CURRENCY_KEY);
    setCurrency(selectedCurrency);
  }, []);

  const onCurrencyClick = c => {
    const url = new URL(router.asPath, document.baseURI);
    url.hash = ComposantId.FOOTER_SETTINGS;

    setCookie(COOKIE_CURRENCY_KEY, c);
    router.replace(url.href, url.href, { scroll: false });
    router.reload();
  };

  return (
    <Menu>
      {({ open }) => (
        <div>
          <Menu.Button className="space-x-2">
            <AhotwoProperty
              iconPosition={Side.right}
              icon={open ? ArrowUpThinIcon : ArrowDownThinIcon}
              className="min-w-[150px] w-fit justify-between ">
              <SettingButton text={currency} symbole="$" />
            </AhotwoProperty>
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Menu.Items
              className={clsxm(
                'shadow-30 absolute bg-white rounded-md space-y-2 py-2 !left-auto !right-0 w-[280px] h-[190px] overflow-y-scroll',
                className
              )}>
              {CURRENCY.map(c => (
                <Menu.Item key={c}>
                  {({ active }) => (
                    <div
                      key={c}
                      className={clsxm(
                        'hover:cursor-pointer  px-4',
                        active && 'bg-green-5',
                        c === currency && 'font-bold'
                      )}
                      onClick={() => onCurrencyClick(c)}>
                      <Currency currency={c} />
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default DropdownLinkCurrency;
