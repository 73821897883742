import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const { NODE_ENV } = publicRuntimeConfig;

//Show the error in development mode
export const handleClientSideError = (context, error) => {
  if (NODE_ENV == 'production') {
    if (window) {
      window?.newrelic?.noticeError(error, context);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
