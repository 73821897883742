import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

interface SnackbarProps {
  isOpened?: boolean;
  msg: ReactNode | string;
  error?: boolean;
}

export const Snackbar: FC<SnackbarProps> = ({ isOpened, msg, error }) => (
  <aside
    className={clsx(
      'fixed z-50 max-w-lg flex items-center rounded-md bg-white h-12 top-2 right-2 shadow-30 transition-all',
      isOpened ? 'mr-0' : '-mr-128' // -mr-128 is our custom css class
    )}>
    <i className={clsx('block w-3 h-full flex-grow-0 rounded-l-md', error ? 'bg-red-100' : 'bg-green-70')} />
    <div className="flex-grow justify-center p-5">{msg}</div>
  </aside>
);
