import clsxm from '../../lib/clsxm';

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  as?: React.ElementType;
  children?: React.ReactNode;
  size?: 'p0' | 'p1' | 'p2' | 'p3';
  truncate?: boolean;
  color?: 'light' | 'lighter';
}

const paragraphSizeMap = {
  p0: 'text-lg',
  p1: 'text-base',
  p2: 'text-sm',
  p3: 'text-xs'
};

export const Paragraph = ({
  as: Component = 'p',
  children,
  truncate,
  size = 'p1',
  className,
  color,
  ...rest
}: ParagraphProps): JSX.Element => {
  return (
    <Component
      className={clsxm(
        paragraphSizeMap[size],
        'antialiased',
        'mt-0 mb-4',
        truncate && 'truncate',
        color === 'light' && 'text-gray-90',
        color === 'lighter' && 'text-gray-70',
        className
      )}
      {...rest}>
      {children}
    </Component>
  );
};
