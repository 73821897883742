import { ButtonHTMLAttributes, ElementType } from 'react';

import clsxm from '../../../lib/clsxm';
import { AhotuLoadingSpinner } from '../AhotuLoadingSpinner';

type ConditionalProps =
  | {
      as?: 'button';
      htmlFor?: never;
    }
  | {
      as: 'label';
      htmlFor: string;
      type?: never;
    };

type Props = {
  as?: ElementType<any>;
  fullWidth?: boolean;
  showSpinner?: boolean;
  small?: boolean;
  variant?: 'default' | 'stroke' | 'white' | 'link' | 'label';
} & ConditionalProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

const AhotuButton = ({
  as: Component = 'button',
  children,
  className,
  fullWidth,
  showSpinner,
  small,
  type = 'button',
  variant = 'default',
  ...rest
}: Props) => {
  const classNames = clsxm(
    'ah-btn',
    small && 'ah-btn-sm',
    variant === 'default' && 'ah-btn-default',
    variant === 'stroke' && 'ah-btn-stroke',
    variant === 'white' && 'ah-btn-white',
    variant === 'link' && 'ah-btn-link',
    variant === 'label' && 'ah-btn-label',
    showSpinner && 'has-spinner',
    fullWidth && 'ah-btn-full',
    className
  );

  return (
    <Component type={type} className={classNames} {...rest}>
      {showSpinner ? <AhotuLoadingSpinner /> : children}
    </Component>
  );
};

export default AhotuButton;
