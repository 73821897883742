import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { PartnerLogo } from '~/src/lib/getPartnerLogos';

import { getLocalizedProperty } from '../../lib/localization';
import AhotuLayout from '../../templates/AhotuLayout';
import { DropdownLink } from '../../types/eventPageTypes';
import { TopNavigationMenu } from '../../types/homePageTypes';
import { routes } from '../../utils/routes';
import AhotuLink from '../atoms/AhotuLink';
import { AhotwoBinocularsIcon } from '../icons/icons';

interface IErrorPageProps {
  title: string;
  otherLanguagesUrls: DropdownLink[];
  topNavigationMenu: TopNavigationMenu;
  partnerLogos: PartnerLogo[];
}

const ErrorPage = ({ title, otherLanguagesUrls, topNavigationMenu, partnerLogos }: IErrorPageProps) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <AhotuLayout
      partnerLogos={partnerLogos}
      animateNavigation={false}
      headProps={{ title: title }}
      topNavigationMenu={topNavigationMenu}
      otherLanguagesUrls={otherLanguagesUrls}>
      <div className="h-150 flex justify-center items-center">
        <div className="flex flex-col items-center space-y-2">
          <div className="h-14 w-14">
            <AhotwoBinocularsIcon className="fill-green-70" />
          </div>
          <p className="text-center leading-6">{title}</p>
          <p className="text-sm text-center">
            {t('common:go-to')}{' '}
            <AhotuLink className="underline" href={getLocalizedProperty(routes.homepage, locale)}>
              Ahotu {t('common:home-page')}
            </AhotuLink>
          </p>
        </div>
      </div>
    </AhotuLayout>
  );
};

export default ErrorPage;
