import { Element } from 'react-scroll';

import clsxm from '../lib/clsxm';

interface IAhotuSectionsProps {
  id: string;
  name?: string;
  layout?: 'full' | 'container' | 'left-container';
  breakFullwidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  bgColor?: 'white' | 'lightgray' | 'lightgreen';
  children: React.ReactNode;
  className?: string;
}

const bgColorMap = {
  white: 'bg-white',
  lightgray: 'bg-gray-5',
  lightgreen: 'bg-green-5'
};

const classesMapper = (layout, breakFullwidth) => {
  if (layout === 'full') {
    return 'w-full';
  } else if (layout === 'container') {
    return ['mx-4 4xl:mx-auto 4xl:max-w-ahotu-section', breakpointMap.container[breakFullwidth]];
  } else {
    return ['ml-4 4xl:mx-auto 4xl:max-w-ahotu-section', breakpointMap.leftContainer[breakFullwidth]];
  }
};
const breakpointMap = {
  container: {
    xs: 'xs:mx-16',
    sm: 'sm:mx-16',
    md: 'md:mx-16',
    lg: 'lg:mx-16',
    xl: 'xl:mx-16'
  },
  leftContainer: {
    xs: 'xs:ml-16',
    sm: 'sm:ml-16',
    md: 'md:ml-16',
    lg: 'lg:ml-16',
    xl: 'xl:ml-16'
  }
};

export const AhotuSection = ({
  id,
  name,
  layout = 'full',
  bgColor = 'white',
  breakFullwidth = 'lg',
  className,
  children
}: IAhotuSectionsProps) => {
  const nameProp = name || id;

  return (
    <section className={clsxm('w-full', bgColorMap[bgColor])}>
      <Element id={id} name={nameProp}>
        <div className={clsxm(classesMapper(layout, breakFullwidth), className)}>{children}</div>
      </Element>
    </section>
  );
};
