import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { DropdownLink } from '../../types/eventPageTypes';
import { PaginationMetaData } from '../../types/homePageTypes';

export interface AhotuHeadProps {
  title: string;
  description?: string;
  schemaData?: object;
  image?: string;
  customProperties?: any;
  otherLanguagesUrls?: DropdownLink[];
  type?: any;
  pagination?: PaginationMetaData;
}
//Review hotffix
const AhotuHead = ({
  title,
  description,
  schemaData,
  image,
  customProperties,
  otherLanguagesUrls = [],
  type,
  pagination
}: AhotuHeadProps) => {
  const { asPath, locale } = useRouter();
  const pageTitle = title ? title : 'Ahotu';
  let canonical = '';

  if (
    (asPath.includes('event') || asPath.includes('evenement')) &&
    !asPath.includes('news') &&
    asPath.split('/').length === 4
  ) {
    //deal with edition date on event page
    canonical = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}${asPath.split('/').splice(0, 3).join('/')}`;
  } else {
    const url = otherLanguagesUrls?.find(option => option.locale === locale)?.href;
    canonical = `${process.env.NEXT_PUBLIC_SITE_DOMAIN}${url}`.split('?')[0];
  }

  if (!image) {
    // Ahotu Logo Url
    image = 'https://images.ahotu.com/vj7yintmt19ac15ksyygpsgn1p8x?w=1200&f=jpg';
  }
  return (
    <NextHead>
      <title>{title}</title>

      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content={type ?? 'website'} />
      <meta property="og:site_name" content="Ahotu" />
      <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_DOMAIN}${asPath}`.split('?')[0]} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="en-US" />
      <meta name="google-site-verification" content="NKM_rqyeTVHfMP0prDeXjDOR8imySuRpNMNPh7ivCxw" />
      <meta name="google-site-verification" content="Ec_TD1SUQAvKL6-VVeaVgwlhQxH5c5uWoTi8jifuPkk" />

      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:card" content="summary" />
      <link rel="shortcut icon" type="image/png" href="/favicon.ico" />
      <link rel="canonical" href={canonical} />

      {pagination?.previousPageURL && (
        <link rel="prev" href={`${process.env.NEXT_PUBLIC_SITE_DOMAIN}${pagination?.previousPageURL}`} />
      )}
      {pagination?.nextPageUrl && (
        <link rel="next" href={`${process.env.NEXT_PUBLIC_SITE_DOMAIN}${pagination?.nextPageUrl}`} />
      )}

      {otherLanguagesUrls.length &&
        otherLanguagesUrls.map(option => (
          <link
            key={option.name}
            rel="alternate"
            href={`${process.env.NEXT_PUBLIC_SITE_DOMAIN}${option.href}`}
            hrefLang={option.locale}
          />
        ))}

      <meta name="description" content={description} />
      {schemaData && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}></script>
      )}
      {customProperties?.length &&
        customProperties.map(({ property, content }) => <meta key={property} property={property} content={content} />)}

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </NextHead>
  );
};

export default memo(AhotuHead);
