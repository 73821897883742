import { Side, Size } from '../../types/enums';
import { Icon, IconProps } from '../../types/Icon';
import clsxm from './../../lib/clsxm';

interface IAhotwoPropertyProps extends IconProps {
  icon?: Icon;
  className?: string;
  iconPosition?: Side.right | Side.left;
  size?: Size.xs | Size.sm | Size.base | Size.xl;
  onOneLine?: boolean;
  children?: React.ReactNode;
}

const TextSizeMapper = {
  [Size.xs]: 'text-xs',
  [Size.sm]: 'text-sm',
  [Size.base]: 'text-base',
  [Size.xl]: 'text-xl'
};

const IconSizeMapper = {
  [Size.xs]: 'h-4 w-4',
  [Size.sm]: 'h-5 w-5',
  [Size.base]: 'h-6 w-6',
  [Size.xl]: 'h-7 w-7'
};

const AhotwoProperty = ({
  icon,
  children,
  className,
  iconPosition = Side.left,
  size = Size.base,
  onOneLine = false,
  ...iconProps
}: IAhotwoPropertyProps) => {
  const IconComp = icon;
  return (
    <div
      className={clsxm(
        'text-softblack flex flex-row items-start justify-center',
        iconPosition === Side.right && 'flex-row-reverse text-right',
        TextSizeMapper[size],
        className
      )}>
      {IconComp && (
        <div
          className={clsxm(
            'flex justify-center items-center mr-2',
            iconPosition === Side.right && 'mr-0 ml-1',
            IconSizeMapper[size]
          )}>
          <IconComp className={IconSizeMapper[size]} {...iconProps} />
        </div>
      )}
      <span className={clsxm('flex-grow', onOneLine && ' line-clamp-1')}>{children}</span>
    </div>
  );
};

export default AhotwoProperty;
