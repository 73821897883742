import kebabCase from 'lodash/kebabCase';

import { EventPageData } from '../../types/eventPageTypes';
import { SearchResultData } from '../../types/searchPageTypes';
import { FeaturedEvent } from '../../types/sportPageTypes';

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, title: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    event: 'virtualPageview',
    pageUrl: url,
    pageTitle: title
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

export const sendEvent = (event: string, payload?: object) => {
  window?.dataLayer.push({
    event: event,
    ...payload
  });
};

type ResultListName =
  | 'Calendar Results'
  | 'Searchbox Results'
  | 'Event Page'
  | 'Featured events'
  | 'Recommended events';

type EcommerceItem = {
  item_id: string; // 'SKU_12345';
  item_name?: string; // 'Stan and Friends Tee';
  affiliation?: string; // 'Google Merchandise Store';
  coupon?: string; // 'SUMMER_FUN';
  discount?: number; // 2.22;
  index: number; // 0;
  item_brand?: string; // 'Google';
  item_category?: string; // 'Apparel';
  item_category2?: string; // 'Adult';
  item_category3?: string; // 'Shirts';
  item_category4?: string; // 'Crew';
  item_category5?: string; // 'Short sleeve';
  item_list_id?: string; // 'related_products';
  item_list_name?: ResultListName; // 'Related Products';
  item_variant?: string; // 'green';
  location_id?: string; // 'ChIJIQBpAG2ahYAR_6128GcTUEo';
  price?: number; // 10.01;
  quantity?: number; // 3;
};

const searchResult2EcommerceItem = (event: SearchResultData, list: ResultListName, index = 0): EcommerceItem => ({
  item_id: event.permalink,
  item_list_id: kebabCase(list),
  item_list_name: list,
  item_name: event.eventName,
  item_category: event.isClient ? 'Client' : 'No Client',
  ...(event.tags &&
    event.tags.length &&
    event.tags.slice(0, 5).reduce((acc, tag, index) => ({ ...acc, [`item_category${index}`]: tag }), {})),
  price: event.minimumPrice,
  location_id: event.location,
  quantity: 1,
  index
});

const eventPage2EcommerceItem = (event: EventPageData, list: ResultListName, index = 0): EcommerceItem => ({
  item_id: event.permalink,
  item_list_id: kebabCase(list),
  item_list_name: list,
  item_name: event.name,
  price: event.ron?.minPrice,
  location_id: event.location,
  quantity: 1,
  index
});

const featuredEvent2EcommerceItem = (event: FeaturedEvent, list: ResultListName, index = 0): EcommerceItem => ({
  item_id: event.permalink,
  item_list_id: kebabCase(list),
  item_list_name: list,
  item_name: event.name,
  price: event.rawPrice?.price,
  location_id: event.location,
  quantity: 1,
  index
});

export const sendEventImpressions = (events: SearchResultData[], list: ResultListName) => {
  const { currency = 'EUR' } = events.find(event => !!event.currency) || {};
  sendEcommerceEvent('view_item_list', {
    currencyCode: currency,
    item_list_id: kebabCase(list),
    item_list_name: list,
    items: events.map<EcommerceItem>((item, index) => searchResult2EcommerceItem(item, list, index))
  });
};

export const sendEventSelection = (event: FeaturedEvent, list: ResultListName) => {
  sendEcommerceEvent('select_item', {
    item_list_id: kebabCase(list),
    item_list_name: list,
    items: [featuredEvent2EcommerceItem(event, list)]
  });
};

export const sendEventViewDetails = (event: EventPageData, list: ResultListName) => {
  sendEcommerceEvent('view_item', {
    currency: event.ron?.priceCurrency || 'EUR',
    value: event.ron?.minPrice,
    items: [eventPage2EcommerceItem(event, list)]
  });
};

export const sendEventAddToWishList = (event: SearchResultData, list: ResultListName) => {
  sendEcommerceEvent('add_to_wishlist', {
    currency: event.currency || 'EUR',
    value: event.minimumPrice,
    items: [searchResult2EcommerceItem(event, list)]
  });
};

export const sendEventBeginCheckout = event_id => {
  sendEcommerceEvent('beging_checkout', {
    items: [
      {
        item_id: event_id
      }
    ]
  });
};

type EcommerceEventName =
  | 'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'remove_from_cart'
  | 'beging_checkout';

const sendEcommerceEvent = (eventName: EcommerceEventName, payload: object) => {
  window?.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

  window?.dataLayer.push({
    event: eventName,
    ecommerce: payload
  });
};
