import get from 'lodash/get';

import {
  AhotwoBlockIcon,
  AhotwoInfoIcon,
  AhotwoWarningIcon,
  CalendarIcon,
  Route,
  ScheduleIcon
} from '../components/icons/icons';
import {
  AdventureRaceIcon,
  AlpineSkiingIcon,
  AquabikeIcon,
  AquathlonIcon,
  BikeNRunIcon,
  CanoeingIcon,
  CyclingIcon,
  CycloCrossIcon,
  DogRunIcon,
  DuathlonIcon,
  GravelIcon,
  InlineSkatingIcon,
  MTBIcon,
  NordicIceSkatingIcon,
  NordicWalkIcon,
  ObstacleRaceIcon,
  OrienteeringIcon,
  RunIcon,
  StandUpPadleIcon,
  SwimmingIcon,
  SwimrunIcon,
  TrailRunIcon,
  TriathlonIcon,
  WalkIcon,
  WheelchairIcon,
  XCskiingIcon
} from '../components/icons/sport';

export const activityIcon = activity => {
  const activityIcons = {
    '0': RunIcon,
    '1': WalkIcon,
    '2': NordicWalkIcon,
    '6': ObstacleRaceIcon,
    '7': BikeNRunIcon,
    '8': DuathlonIcon,
    '9': TriathlonIcon,
    '10': AdventureRaceIcon,
    '11': CyclingIcon,
    '12': SwimrunIcon,
    '13': AquathlonIcon,
    '14': DogRunIcon,
    '15': WheelchairIcon,
    '16': OrienteeringIcon,
    '17': SwimmingIcon,
    '18': InlineSkatingIcon,
    '20': AquabikeIcon,
    '3': MTBIcon,
    '4': CanoeingIcon,
    '5': CanoeingIcon,
    '19': StandUpPadleIcon,
    '22': AlpineSkiingIcon,
    '23': AlpineSkiingIcon,
    '24': XCskiingIcon,
    '25': AlpineSkiingIcon,
    '26': AlpineSkiingIcon,
    '28': CycloCrossIcon,
    '29': NordicIceSkatingIcon,
    '30': TrailRunIcon,
    '31': GravelIcon,
    '27': AlpineSkiingIcon,
    '21': CanoeingIcon
  };
  return get(activityIcons, activity, RunIcon); //RunIcon is the default value
};

export const distanceIcon = unit => {
  return [5, 10, 6].includes(unit) ? ScheduleIcon : Route; // hours, minutes, day
};

export const statusIcon = (error, level) => {
  const statusIcons = {
    'all-hybrid': AhotwoInfoIcon,
    'all-virtual': AhotwoInfoIcon,
    'cancelled-event': AhotwoBlockIcon,
    'cancelled-terminated': AhotwoBlockIcon,
    'new-date-announced': AhotwoInfoIcon,
    'postponed-terminated': AhotwoWarningIcon,
    'rescheduled-terminated': CalendarIcon,
    'turned-virtual': AhotwoInfoIcon,
    'virtual-race': AhotwoInfoIcon,
    'virtual-terminated': AhotwoWarningIcon,
    archived: AhotwoWarningIcon,
    canceled: AhotwoBlockIcon,
    cancelled: AhotwoBlockIcon,
    postponed: AhotwoWarningIcon,
    rescheduled: CalendarIcon,
    terminated: AhotwoWarningIcon
  };
  const defaultIcon = level === 'info' ? AhotwoInfoIcon : AhotwoWarningIcon;

  return get(statusIcons, error, defaultIcon);
};
