export const CURRENCY = [
  'EUR',
  'SEK',
  'GBP',
  'USD',
  'CZK',
  'NOK',
  'DKK',
  'JPY',
  'PLN',
  'AUD',
  'HKD',
  'INR',
  'TRY',
  'CNY',
  'CAD',
  'RSD'
];

export enum MenuLevel {
  DEFAULT = 'default',
  SPORTS = 'sports',
  CURRENCIES = 'currencies',
  LOCALES = 'locales'
}

export enum ComposantId {
  FOOTER_SETTINGS = 'footerSettings'
}

export const COOKIE_CURRENCY_KEY = 'currency';

export enum Direction {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}
