import useOneTapSignin from '../contexts/SignInProvider/useOneTapSignin';

const OneTapComponent = () => {
  const { isLoading: oneTapIsLoading } = useOneTapSignin({
    redirect: false,
    parentContainerId: 'oneTap'
  });

  console.log('oneTapIsLoading: ', oneTapIsLoading);

  return <div id="oneTap" className="fixed top-0 right-0 z-[100]" />;
};
export default OneTapComponent;
