import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { COOKIE_CURRENCY_KEY, CURRENCY, MenuLevel } from '../../../lib/globale/constantes';
import { getLocalizedProperty } from '../../../lib/localization';
import LanguageRef from '../../../references/language';
import { TopNavigationMenu, TopNavigationSubMenu } from '../../../types/homePageTypes';
import { Icon } from '../../../types/Icon';
import { SportType } from '../../../types/referenceTypes';
import { activityIcon } from '../../../utils/icons';
import AhotuLink from '../../atoms/AhotuLink';
import AhotwoProperty from '../../atoms/AhotwoProperty';
import Currency from '../../atoms/Currency';
import Divider from '../../atoms/Divider';
import { Paragraph } from '../../atoms/Paragraph';
import { ArrowLeftThinIcon } from '../../icons/arrow';

interface ISecondLevelMobileNavProps {
  setMobileMenu: (x) => void;
  level: string;
  setLevel: (x: MenuLevel) => void;
  title: string;
  titleIcon?: Icon;
  showReturnButton?: boolean;
  shouldScrollTo?: string;
  menu?: TopNavigationMenu;
  sportType?: SportType | '';
}

const SecondLevelMobileNav = ({
  setMobileMenu,
  level,
  setLevel,
  title,
  titleIcon,
  showReturnButton = true,
  shouldScrollTo = '',
  menu,
  sportType
}: ISecondLevelMobileNavProps) => {
  const IconComp = titleIcon;

  const { t } = useTranslation();

  const [links, setLinks] = useState([]);
  const [activeCurrency, setActiveCurrency] = useState('');

  useEffect(() => {
    if (level === MenuLevel.CURRENCIES) {
      setLinks(CURRENCY.map(currency => ({ name: currency })));
      setActiveCurrency(getCookie(COOKIE_CURRENCY_KEY));
    }
  }, [level]);

  return (
    <>
      {showReturnButton && (
        <div
          className="flex flex-row items-center space-x-4 mb-5"
          onClick={() => {
            setLevel(MenuLevel.DEFAULT);
          }}>
          <ArrowLeftThinIcon className="w-6 h-6" />
          <Paragraph className="mb-0">{t('common:back')}</Paragraph>
        </div>
      )}
      <AhotwoProperty icon={titleIcon && IconComp} className="mb-7">
        {title}
      </AhotwoProperty>
      <Divider />

      <div className="flex flex-col pt-8 space-y-6">
        {level === MenuLevel.SPORTS && (
          <DisplaySports setMobileMenu={setMobileMenu} subMenus={menu[sportType].subMenus} />
        )}
        {level === MenuLevel.CURRENCIES && (
          <DisplayCurrencies links={links} activeCurrency={activeCurrency} shouldScrollTo={shouldScrollTo} />
        )}
        {level === MenuLevel.LOCALES && <DisplayLocales shouldScrollTo={shouldScrollTo} />}
      </div>
    </>
  );
};

export default SecondLevelMobileNav;

const DisplayCurrencies = ({ links, activeCurrency, shouldScrollTo }) => {
  const router = useRouter();
  const url = new URL(router.asPath, document.baseURI);

  if (shouldScrollTo) {
    url.hash = shouldScrollTo;
  } else {
    url.hash = '';
  }

  const onCurrencyClick = currency => {
    setCookie(COOKIE_CURRENCY_KEY, currency.name);
    router.replace(url.href, url.href, { scroll: false });
    router.reload();
  };
  //need to use router.replace + router.reload to update all components (server side + client side components)
  //router.push would only reload server components
  return links.map((currency, i) => (
    <div key={currency + i} onClick={() => onCurrencyClick(currency)}>
      <Currency currency={currency.name} className={activeCurrency === currency.name && 'font-bold'} />
    </div>
  ));
};

const DisplayLocales = ({ shouldScrollTo }) => {
  const router = useRouter();

  const { locale } = router;
  const url = new URL(router.asPath, document.baseURI);

  if (shouldScrollTo) {
    url.hash = shouldScrollTo;
  } else {
    url.hash = '';
  }

  const onLocalClick = (lang: string) => {
    router.push(url.href, url.href, { scroll: false, locale: lang });
  };

  return (
    <>
      {router.locales.map(l => (
        <div key={l} className={locale === l && 'font-bold'} onClick={() => onLocalClick(l)}>
          {getLocalizedProperty(LanguageRef[l].names, l)}
        </div>
      ))}
    </>
  );
};

interface DisplaySports {
  subMenus: TopNavigationSubMenu[];
  setMobileMenu: (x) => void;
}
const DisplaySports = ({ setMobileMenu, subMenus }) => {
  return subMenus.map((link, i) => (
    <AhotuLink key={link.url + i} href={link.url} passHref prefetch={false}>
      <div onClick={() => setMobileMenu(false)}>
        <AhotwoProperty icon={!!link.id && activityIcon(link.id)} className="mb-0">
          {link.name}
        </AhotwoProperty>
      </div>
    </AhotuLink>
  ));
};
