import Script from 'next/script';
import { useSession } from 'next-auth/react';
import { memo } from 'react';

import { CustomDimensionsGA } from '../types/sportPageTypes';
interface GoogleAnalyticsProps {
  trackerId: string;
  customDimensions?: Partial<CustomDimensionsGA>;
}

declare global {
  interface Window {
    gtag: any;
    dataLayer: any;
    _paq: any;
  }
}

const GoogleAnalytics = ({ trackerId, customDimensions }: GoogleAnalyticsProps) => {
  const { data: session } = useSession();
  // const visitorIsLogged = session ? 'true' : 'false';
  const dimensions = [];

  if (customDimensions?.pageCountry) {
    // page: event, search, / code
    dimensions.push(`page_country: "${customDimensions?.pageCountry}"`);
  }

  if (customDimensions?.pageContinent) {
    // page: event, search / english name
    dimensions.push(`page_continent: "${customDimensions?.pageContinent}"`);
  }

  if (customDimensions?.pageSport) {
    // page: event, sport, search / [code].joins(",")
    dimensions.push(`page_activity: "${customDimensions?.pageSport}"`);
  }

  if (customDimensions?.pageDistance) {
    // page: event, search / [code].joins(",")
    dimensions.push(`page_race_type: "${customDimensions?.pageDistance}"`);
  }

  if (session) {
    dimensions.push(`visitorIsOrganizer: ${session.roles.includes('organizer') ? 'true' : 'false'}`);
  }

  if (customDimensions?.eventTimeStamp) {
    //page: event
    dimensions.push(`EventTimeStamp: "${customDimensions?.eventTimeStamp}"`);
  }

  return trackerId ? (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${trackerId}');
      `}
      </Script>
      {/* <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${trackerId}`} />
      <Script strategy="afterInteractive" id="gtag">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments)};
            gtag('js', new Date());
            gtag('config', '${trackerId}', {
              custom_tag: {
                dimension1: 'page_country',
                dimension2: 'page_activity',
                dimension3: 'visitor_is_logged',
                dimension4: 'page_continent',
                dimension5: 'page_race_type',
                dimension6: 'visitorIsOrganizer',
                dimension7: 'eventTimeStamp'
              },
              page_path: window.document.pathname,
              page_title: document.title,
              visitor_is_logged: ${visitorIsLogged},
              ${dimensions.join(', ')}
            });
            `}
      </Script> */}
    </>
  ) : null;
};
export default memo(GoogleAnalytics);
