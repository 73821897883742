import getConfig from 'next/config';
import { Session } from 'next-auth';

import { AhotuEvent, AhotuEventListCriteria, AhotuEventListResponsePayload } from '../types';

const { publicRuntimeConfig } = getConfig();
const { RAILS_API_URL } = publicRuntimeConfig;

const getEvent = async (session: Session, eventId: string): Promise<AhotuEvent | number> => {
  if (session.user) {
    const response = await fetch(`${RAILS_API_URL}/v2/a_events/${eventId}`, {
      method: 'GET',
      headers: {
        'x-user-email': session.user.email,
        'x-user-token': session.authenticationToken,
        'Content-Type': 'application/json'
      }
    });

    if (response?.ok) {
      return response.json();
    }

    return Promise.reject(response.status);
  }
};

const getEvents = async (
  session: Session,
  params: AhotuEventListCriteria
): Promise<AhotuEventListResponsePayload | number> => {
  if (session.user) {
    const url = new URL(`${RAILS_API_URL}/v2/a_events`);

    for (const key in params) {
      if (params[key]) {
        url.searchParams.append(key, params[key]);
      }
    }

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'x-user-email': session.user.email,
        'x-user-token': session.authenticationToken,
        'Content-Type': 'application/json'
      }
    });

    if (response?.ok) {
      return response.json();
    }

    return Promise.reject(response.status);
  }
};

const saveEvent = async (
  session: Session,
  eventPayload
): Promise<AhotuEvent | { status: number; payload: AhotuEvent }> => {
  if (session.user) {
    const { id } = eventPayload.a_event;
    const url = id ? `${RAILS_API_URL}/v2/a_events/${id}` : `${RAILS_API_URL}/v2/a_events`;

    const response = await fetch(url, {
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify(eventPayload),
      headers: {
        'x-user-email': session.user.email,
        'x-user-token': session.authenticationToken,
        'Content-Type': 'application/json'
      }
    });

    if (response?.ok) {
      return await response.json();
    }

    return Promise.reject({ status: response.status, payload: await response.json() });
  }
};

export { getEvent, getEvents, saveEvent };
