import { Menu, Transition } from '@headlessui/react';

import clsxm from '../../lib/clsxm';
import { Side, StatusLevel } from '../../types/enums';
import { DropdownLink as DropdownLinkType } from '../../types/eventPageTypes';
import { Icon } from '../../types/Icon';
import { ArrowDownThinIcon, ArrowUpThinIcon } from '../icons/arrow';
import { GlobeIcon } from '../icons/icons';
import SettingButton from '../molecules/SettingButton';
import Status from './../atoms/Status';
import AhotuLink from './AhotuLink';
import AhotwoProperty from './AhotwoProperty';

interface IDropdownProps {
  active: string;
  links: DropdownLinkType[];
  className?: string;
  icon?: Icon;
}

const DropdownLink = ({ active, links, className, icon }: IDropdownProps) => {
  return (
    <Menu>
      {({ open }) => (
        <div>
          <Menu.Button className="flex justify-start">
            <AhotwoProperty
              iconPosition={Side.right}
              icon={open ? ArrowUpThinIcon : ArrowDownThinIcon}
              className="min-w-[150px] w-fit justify-between ">
              {icon ? (
                <SettingButton iconLeft={GlobeIcon} text={active} />
              ) : (
                <span dangerouslySetInnerHTML={{ __html: active }}></span>
              )}
            </AhotwoProperty>
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0">
            <Menu.Items
              className={clsxm('shadow-30 absolute bg-white rounded-md space-y-2 py-2 !left-auto !right-0', className)}>
              {links.map(link => (
                <div key={link.name}>
                  {link.unclickable ? (
                    <div className="antialiased py-1 px-2 text-gray-50">{link.name}</div>
                  ) : (
                    <Menu.Item>
                      {({ active }) => (
                        <div className={clsxm('py-1 px-4', active ? 'bg-green-5' : 'bg-white')}>
                          <AhotuLink
                            href={link.href}
                            locale={link.locale}
                            className="flex flex-row justify-between space-x-4">
                            <AhotwoProperty className={clsxm('whitespace-nowrap', link?.selected && 'font-bold')}>
                              {link.name}
                            </AhotwoProperty>
                            {link.message && (
                              <Status
                                level={StatusLevel[link.warningLevel]}
                                className="w-fit rounded-full text-xs px-3 py-1 whitespace-nowrap">
                                {link.message}
                              </Status>
                            )}
                          </AhotuLink>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

export default DropdownLink;
