import clsxm from '../../lib/clsxm';

const classesMap = {
  h1: 'text-3xl md:text-5xl mt-0 mb-2 md:mb-6',
  h2: 'text-2xl md:text-4xl mt-0 mb-2 md:mb-5',
  h3: 'text-3xl mt-0 mb-2',
  h4: 'text-lg md:text-2xl mt-0 mb-2 md:mb-3',
  h5: 'text-lg md:text-xl mt-0 mb-2',
  h6: 'text-base'
};
const colorMap = {
  default: 'text-softblack',
  primary: 'text-blue-500',
  green: 'text-green-70',
  darkgreen: 'text-green-80',
  secondary: 'text-indigo-700',
  white: 'text-white'
};

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadElement> {
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
  color?: 'default' | 'primary' | 'secondary' | 'white' | 'green' | 'darkgreen';
  size?: '' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  customColor?: boolean;
}
export const Heading = ({
  headingLevel = 'h1',
  className,
  children,
  color = 'default',
  customColor = false,
  size,
  ...rest
}: HeadingProps): JSX.Element => {
  const Heading = headingLevel;
  return (
    <Heading
      className={clsxm(
        'font-bold antialiased',
        color && !customColor && colorMap[color],
        size ? `text-${size}` : classesMap[headingLevel],
        className
      )}
      {...rest}>
      {children}
    </Heading>
  );
};
