import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import * as Yup from 'yup';

import useMediaQuery from '../../utils/useMediaQuery';
import AhotuInput from '../atoms/AhotuInput';
import AhotuButton from '../atoms/form/AhotuButton';
import { Heading } from '../atoms/Heading';

interface NewsletterFormProps {
  handleSubmit: (x, y) => void;
  isSubmitting: boolean;
}
const NewsletterForm = ({ isSubmitting, handleSubmit }: NewsletterFormProps) => {
  const isMobile = useMediaQuery('sm');
  const { t } = useTranslation();

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('validation:validEmail')).required(t('validation:required'))
  });

  return (
    <div className="ah-newsletter-container lg:flex lg:space-x-6 lg:items-center ">
      <div className="mb-6 lg:flex-auto lg:mb-0 lg:h-full">
        <Heading headingLevel="h4" className="text-xl md:text-xl mb-1 md:mb-1 text-center sm:text-left">
          {t('common:newsletter-heading')}
        </Heading>
        <div className="text-center sm:text-left">{t('common:newsletter-text')}</div>
      </div>
      <div className="lg:flex-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={(values, { setFieldError }) => handleSubmit(values, setFieldError)}>
          {({ errors, handleChange }) => (
            <Form className="mb-4 lg:mb-2 sm:flex sm:space-x-6 w-full">
              <div className="flex-auto">
                <AhotuInput
                  id="email"
                  name="email"
                  fullwidth={true}
                  placeholder={t('common:email')}
                  onChange={handleChange}
                  error={!!errors.email}
                  errorMessage={errors.email}
                />
              </div>
              <div className="sm:flex-none">
                <AhotuButton className="mt-6 sm:mt-0" fullWidth={isMobile} type="submit" showSpinner={isSubmitting}>
                  {t('common:subscribe')}
                </AhotuButton>
              </div>
            </Form>
          )}
        </Formik>
        <div
          className="text-xs ah-newsletter-conditions "
          dangerouslySetInnerHTML={{ __html: t('common:newsletter-conditions') }}
        />
      </div>
    </div>
  );
};

export default NewsletterForm;
