import { Session } from 'next-auth';

import { User } from '../../contexts/UserProvider/UserContext';
import { transformProfileData } from '../../dataTransform/profile';
import { getMember } from '../../services';

const getMemberData = async (session: Session, locale: string): Promise<User | number> => {
  if (session && session.permalink) {
    try {
      const { payload } = await getMember(session.permalink);

      return transformProfileData(payload, locale);
    } catch (err) {
      return err;
    }
  }

  return null;
};

export { getMemberData };
