import { PartnerLogo } from '~/src/lib/getPartnerLogos';

import GoogleAnalytics from '../components/GoogleAnalytics';
import AhotuFooter from '../components/organisms/AhotuFooter';
import AhotuHead, { AhotuHeadProps } from '../components/organisms/AhotuHead';
import TopNavigation from '../components/organisms/TopNavigation';
import clsxm from '../lib/clsxm';
import { DropdownLink as DropdownLinkType } from '../types/eventPageTypes';
import { TopNavigationMenu } from '../types/homePageTypes';
import { CustomDimensionsGA } from '../types/sportPageTypes';

interface AhotuLayout {
  animateNavigation?: boolean;
  bgColor?: 'white' | 'lightgray';
  children;
  className?: string;
  hasFooter?: boolean;
  hasNavigation?: boolean;
  headProps: AhotuHeadProps;
  layout?: 'full' | 'container';
  otherLanguagesUrls?: DropdownLinkType[];
  customDimensions?: Partial<CustomDimensionsGA>;
  topNavigationMenu?: TopNavigationMenu;
  partnerLogos: PartnerLogo[];
}

const classes = {
  container: 'mx-auto mx-0 lg:mx-16',
  full: 'w-full'
};

const bgColorMap = {
  white: 'bg-white',
  lightgray: 'bg-gray-5'
};

const AhotuLayout = ({
  children,
  otherLanguagesUrls,
  hasNavigation = true,
  animateNavigation = false,
  hasFooter = true,
  bgColor = 'white',
  className,
  headProps,
  customDimensions,
  layout = 'full',
  topNavigationMenu,
  partnerLogos
}: AhotuLayout) => (
  <div className={clsxm('w-full', bgColorMap[bgColor], className && className)} id="main-frame">
    <GoogleAnalytics customDimensions={customDimensions} trackerId={process.env.NEXT_PUBLIC_GOOGLE_GTM} />
    <AhotuHead
      title={headProps.title}
      description={headProps.description}
      schemaData={headProps.schemaData}
      otherLanguagesUrls={otherLanguagesUrls}
      image={headProps?.image}
      pagination={headProps.pagination}
    />
    {hasNavigation && <TopNavigation isAnimated={animateNavigation} menu={topNavigationMenu} />}

    <noscript
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_GTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
      }}
    />

    <div className={classes[layout]}>{children}</div>
    {hasFooter && (
      <AhotuFooter otherLanguagesUrls={otherLanguagesUrls} menu={topNavigationMenu} partnerLogos={partnerLogos} />
    )}
  </div>
);

export default AhotuLayout;
