import clsx from 'clsx';
import { Field } from 'formik';
import { useState } from 'react';

import { Paragraph } from '../../components/atoms/Paragraph';
import { handleClientSideError } from '../../utils/handleClientSideError';
import { CheckIcon, EyeIcon } from '../icons/icons';

interface IAhotuInputProps {
  id: string;
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  helpText?: string;
  error?: boolean | string;
  errorMessage?: string;
  disabled?: boolean;
  password?: boolean;
  icon?: 'none' | 'checkIcon' | 'eyeIcon';
  unit?: 'none' | 'km' | 'mi';
  onChange?: (e: Event) => void;
  fullwidth?: boolean;
}

const icons = {
  checkIcon: <CheckIcon />,
  eyeIcon: <EyeIcon />,
  none: false
};

const AhotuInput = ({
  id,
  name,
  label,
  required = false,
  placeholder,
  helpText,
  error,
  errorMessage,
  disabled = false,
  password = false,
  icon = 'none',
  unit = 'none',
  fullwidth = false,
  onChange,
  ...rest
}: IAhotuInputProps): JSX.Element => {
  const [togglePassword, setTogglePassword] = useState(password);

  return (
    <div>
      <div className={clsx('flex flex-col focus-within:text-black', fullwidth ? 'w-full' : '')}>
        {label && (
          <label htmlFor={name} className={clsx('mb-1 text-sm', error && 'text-red-100', disabled && 'text-gray-50')}>
            {label}
            {required && '*'}
          </label>
        )}
        <div className="flex items-center">
          <Field
            id={id}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            onChange={e => onChange(e)}
            className={clsx(
              'h-14 border border-gray-50 px-4 rounded focus:border-fullblack focus:outline-none ',
              fullwidth ? 'w-full' : 'w-auto',
              error && 'text-red-100 border-red-100 focus:border-red-100',
              disabled && 'bg-white border-gray-50 text-red-100'
            )}
            type={togglePassword ? 'password' : 'text'}
            {...rest}
          />
          <div className="flex -ml-10 flex-row-reverse place-items-start justify-end space-x-2 items-center">
            {unit !== 'none' && <Paragraph className="mb-0">{unit}</Paragraph>}
            {icon !== 'none' && (
              <div
                className="h-8 w-8 flex justify-center items-center cursor-pointer"
                onClick={() => setTogglePassword(!togglePassword)}>
                {icons[icon]}
              </div>
            )}
            {password && (
              <div
                className="h-8 w-8  flex justify-center items-center cursor-pointer"
                onClick={() => setTogglePassword(!togglePassword)}>
                <EyeIcon className="" />
              </div>
            )}
          </div>
        </div>
      </div>

      {helpText && (
        <Paragraph size="p3" className="mb-0 mt-1 focus:text-blue-70">
          {helpText}
        </Paragraph>
      )}
      {error && (
        <Paragraph size="p3" className="mb-0 mt-1 text-red-100">
          {errorMessage}
        </Paragraph>
      )}
    </div>
  );
};

export const AhotuPasswordInput = ({ id, name, label }) => {
  const handlePasswordChange = e => {
    handleClientSideError({ message: 'Handling password change', id, name, label }, e);
  };
  return <AhotuInput id={id} name={name} label={label} onChange={handlePasswordChange} />;
};

export default AhotuInput;
