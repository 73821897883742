import getConfig from 'next/config';

import { User } from '../contexts/UserProvider/UserContext';
import { AhotuResponse, Member, MemberAvatar, MemberRequestPayload } from '../types';
import { handleClientSideError } from '../utils/handleClientSideError';
const { publicRuntimeConfig } = getConfig();
const { RAILS_API_URL, RAILS_API_TOKEN } = publicRuntimeConfig;

const getMember = async (username: string): Promise<AhotuResponse<User>> => {
  const response = await fetch(`${RAILS_API_URL}/v2/profile/${username}`, {
    method: 'GET',
    headers: {
      'x-api-key': RAILS_API_TOKEN,
      'Content-Type': 'application/json'
    }
  });

  const resObj: AhotuResponse<User> = { status: response.status, payload: await response.json() };

  if (response?.ok) {
    return resObj;
  } else {
    handleClientSideError('Error in GetMember', resObj);
  }

  return Promise.reject(resObj);
};

const saveMember = async (
  memberEmail: string,
  memberToken: string,
  memberId: number,
  payload: MemberRequestPayload | FormData
): Promise<AhotuResponse<Member>> => {
  const url = memberId ? `${RAILS_API_URL}/v2/members/${memberId}` : `${RAILS_API_URL}/v2/members`;

  const response = await fetch(url, {
    method: memberId ? 'PUT' : 'POST',
    body: memberId ? JSON.stringify(payload as MemberRequestPayload) : (payload as FormData),
    headers: getHeaders(memberEmail, memberToken, !memberId)
  });

  const resObj: AhotuResponse<Member> = { status: response.status, payload: await response.json() };

  if (response?.ok) {
    return resObj;
  } else {
    handleClientSideError('Error in SaveMember', resObj);
  }

  return Promise.reject(resObj);
};

const saveAvatar = async (
  memberEmail: string,
  memberToken: string,
  memberId: number,
  payload: FormData
): Promise<AhotuResponse<MemberAvatar>> => {
  const response = await fetch(`${RAILS_API_URL}/v2/members/${memberId}/add_avatar`, {
    method: 'PUT',
    body: payload,
    headers: getHeaders(memberEmail, memberToken)
  });

  const resObj: AhotuResponse<MemberAvatar> = { status: response.status, payload: await response.json() };

  if (response?.ok) {
    return resObj;
  } else {
    handleClientSideError('Error in SaveAvatar', resObj);
  }

  return Promise.reject(resObj);
};

const removeAvatar = async (
  memberEmail: string,
  memberToken: string,
  memberId: number
): Promise<AhotuResponse<MemberAvatar>> => {
  const response = await fetch(`${RAILS_API_URL}/v2/members/${memberId}/remove_avatar`, {
    method: 'PUT',
    headers: getHeaders(memberEmail, memberToken)
  });

  const resObj: AhotuResponse<MemberAvatar> = { status: response.status, payload: await response.json() };

  if (response?.ok) {
    return resObj;
  } else {
    handleClientSideError('Error in RemoveAvatar', resObj);
  }

  return Promise.reject(resObj);
};

const getHeaders = (memberEmail: string, memberToken: string, isFormData = true) => {
  const headers = {
    'x-user-email': memberEmail,
    'x-user-token': memberToken
  };

  if (isFormData) {
    return headers;
  }

  return {
    ...headers,
    'Content-Type': 'application/json'
  };
};

export { getMember, removeAvatar, saveAvatar, saveMember };
