'use strict';
import { useMemo } from 'react';

import { RichTextRenderer } from '../../AhotuUI';
import { landingMaxWidths, landingMaxWidthsExtraWide } from '../../organisms/LandingPageContent';

export type ContentText = {
  alignment: string;
  richText: string;
  extraWide: boolean;
};

export const Text = ({ alignment, richText, extraWide }: ContentText) => {
  const alteredText = useMemo(() => {
    return richText?.replace(/<H([2-6])/gi, '<p data-style-as="h$1"');
  }, [richText]);

  return (
    <div
      className={`flex flex-col ${extraWide ? landingMaxWidthsExtraWide : landingMaxWidths}
      ${alignment === 'left' ? 'items-start' : ''}
      ${alignment === 'center' ? 'items-center' : ''}
      ${alignment === 'right' ? 'items-end' : ''}
    `}>
      <div dangerouslySetInnerHTML={{ __html: alteredText }} className={RichTextRenderer} />
    </div>
  );
};
