import { CountryReference } from '../types/referenceTypes';
const dict: CountryReference = {
  ABW: {
    iso2: 'aw',
    old_id: 160,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Aruba',
      fr: 'Aruba',
      de: 'Aruba',
      es: 'Aruba',
      pt: 'Aruba',
      pl: 'Aruba',
      'pt-BR': 'Aruba',
      sv: 'Aruba',
      zh: '阿鲁巴',
      ko: 'Aruba',
      ja: 'Aruba'
    },
    permalinks: {
      en: 'aruba',
      fr: 'aruba',
      de: 'aruba',
      es: 'aruba',
      pt: 'aruba',
      pl: 'aruba',
      'pt-BR': 'aruba',
      sv: 'aruba'
    },
    hasRegion: false,
    ins: {}
  },
  AGO: {
    iso2: 'ao',
    old_id: 141,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Angola',
      fr: 'Angola',
      de: 'Angola',
      es: 'Angola',
      pt: 'Angola',
      pl: 'Angola',
      'pt-BR': 'Angola',
      sv: 'Angola',
      zh: '安哥拉',
      ko: 'Angola',
      ja: 'Angola'
    },
    permalinks: {
      en: 'angola',
      fr: 'angola',
      de: 'angola',
      es: 'angola',
      pt: 'angola',
      pl: 'angola',
      'pt-BR': 'angola',
      sv: 'angola'
    },
    hasRegion: false,
    ins: {}
  },
  AIA: {
    iso2: 'ai',
    old_id: 142,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Anguilla',
      fr: 'Anguilla',
      de: 'Anguilla',
      es: 'Anguila',
      pt: 'Anguilla',
      pl: 'Anguilla',
      'pt-BR': 'Anguilla',
      sv: 'Anguilla',
      zh: '安圭拉',
      ko: 'Anguilla',
      ja: 'Anguilla'
    },
    permalinks: {
      en: 'anguilla',
      fr: 'anguilla',
      de: 'anguilla',
      es: 'anguila',
      pt: 'anguilla',
      pl: 'anguilla',
      'pt-BR': 'anguilla',
      sv: 'anguilla'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  ALA: {
    iso2: 'ax',
    old_id: 448,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Åland Islands',
      fr: 'Åland, Îles',
      de: 'Åland-Inseln',
      es: 'Islas Äland',
      pt: 'Ilhas Åland',
      pl: 'Åland Islands',
      'pt-BR': 'Åland Islands',
      sv: 'Åland',
      zh: '奥兰群岛',
      ko: 'Åland Islands',
      ja: 'Åland Islands'
    },
    permalinks: {
      en: 'aland-islands',
      fr: 'aland-iles',
      de: 'aland-inseln',
      es: 'islas-aland',
      pt: 'ilhas-aland',
      pl: 'aland-islands',
      'pt-BR': 'aland-islands',
      sv: 'aland'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'dans les îles Åland' }
  },
  ALB: {
    iso2: 'al',
    old_id: 140,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Albania',
      fr: 'Albanie',
      de: 'Albanien',
      es: 'Albania',
      pt: 'Albânia',
      pl: 'Albania',
      'pt-BR': 'Albania',
      sv: 'Albanien',
      zh: '阿尔巴尼亚',
      ko: 'Albania',
      ja: 'Albania'
    },
    permalinks: {
      en: 'albania',
      fr: 'albanie',
      de: 'albanien',
      es: 'albania',
      pt: 'albania',
      pl: 'albania',
      'pt-BR': 'albania',
      sv: 'albanien'
    },
    hasRegion: false,
    ins: {}
  },
  ALG: {
    iso2: 'dz',
    old_id: 1,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Algeria',
      fr: 'Algérie',
      de: 'Algerien',
      es: 'Algeria',
      pt: 'Argélia',
      pl: 'Algeria',
      'pt-BR': 'Algeria',
      sv: 'Algeriet',
      zh: '阿尔及利亚',
      ko: 'Algeria',
      ja: 'Algeria'
    },
    permalinks: {
      en: 'algeria',
      fr: 'algerie',
      de: 'algerien',
      es: 'algeria',
      pt: 'argelia',
      pl: 'algeria',
      'pt-BR': 'algeria',
      sv: 'algeriet'
    },
    hasRegion: false,
    ins: {}
  },
  AND: {
    iso2: 'ad',
    old_id: 131,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Andorra',
      fr: 'Andorre',
      de: 'Andorra',
      es: 'Andorra',
      pt: 'Andorra',
      pl: 'Andorra',
      'pt-BR': 'Andorra',
      sv: 'Andorra',
      zh: '安道尔',
      ko: 'Andorra',
      ja: 'Andorra'
    },
    permalinks: {
      en: 'andorra',
      fr: 'andorre',
      de: 'andorra',
      es: 'andorra',
      pt: 'andorra',
      pl: 'andorra',
      'pt-BR': 'andorra',
      sv: 'andorra'
    },
    hasRegion: false,
    ins: {}
  },
  ANT: {
    iso2: 'an',
    old_id: 118,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Netherlands Antilles',
      fr: 'Antilles néerlandaises',
      de: 'Netherlands Antilles',
      es: 'Antillas Neerlandesas',
      pt: 'Netherlands Antilles',
      pl: 'Netherlands Antilles',
      'pt-BR': 'Netherlands Antilles',
      sv: 'Netherlands Antilles',
      zh: 'Netherlands Antilles',
      ko: 'Netherlands Antilles',
      ja: 'Netherlands Antilles'
    },
    permalinks: {
      en: 'netherlands-antilles',
      fr: 'antilles-neerlandaises',
      de: 'netherlands-antilles',
      es: 'antillas-neerlandesas',
      pt: 'netherlands-antilles',
      pl: 'netherlands-antilles',
      'pt-BR': 'netherlands-antilles',
      sv: 'netherlands-antilles'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  ARE: {
    iso2: 'ae',
    old_id: 93,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'United Arab Emirates',
      fr: 'Emirats Arabes Unis',
      de: 'Vereinigte Arabische Emirate',
      es: 'Emiratos Árabes Unidos',
      pt: 'Emirados Árabes Unidos',
      pl: 'United Arab Emirates',
      'pt-BR': 'United Arab Emirates',
      sv: 'Förenade Arabemiraten',
      zh: '阿联酋',
      ko: 'United Arab Emirates',
      ja: 'United Arab Emirates'
    },
    permalinks: {
      en: 'united-arab-emirates',
      fr: 'emirats-arabes-unis',
      de: 'vereinigte-arabische-emirate',
      es: 'emiratos-arabes-unidos',
      pt: 'emirados-arabes-unidos',
      pl: 'united-arab-emirates',
      'pt-BR': 'united-arab-emirates',
      sv: 'forenade-arabemiraten'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  ARG: {
    iso2: 'ar',
    old_id: 3,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Argentina',
      fr: 'Argentine',
      de: 'Argentinien',
      es: 'Argentina',
      pt: 'Argentina',
      pl: 'Argentina',
      'pt-BR': 'Argentina',
      sv: 'Argentina',
      zh: '阿根廷',
      ko: 'Argentina',
      ja: 'Argentina'
    },
    permalinks: {
      en: 'argentina',
      fr: 'argentine',
      de: 'argentinien',
      es: 'argentina',
      pt: 'argentina',
      pl: 'argentina',
      'pt-BR': 'argentina',
      sv: 'argentina'
    },
    hasRegion: false,
    ins: {}
  },
  ARM: {
    iso2: 'am',
    old_id: 145,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Armenia',
      fr: 'Arménie',
      de: 'Armenien',
      es: 'Armenia',
      pt: 'Arménia',
      pl: 'Armenia',
      'pt-BR': 'Armenia',
      sv: 'Armenien',
      zh: '亚美尼亚',
      ko: 'Armenia',
      ja: 'Armenia'
    },
    permalinks: {
      en: 'armenia',
      fr: 'armenie',
      de: 'armenien',
      es: 'armenia',
      pt: 'armenia',
      pl: 'armenia',
      'pt-BR': 'armenia',
      sv: 'armenien'
    },
    hasRegion: false,
    ins: {}
  },
  ASM: {
    iso2: 'as',
    old_id: 447,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'American Samoa',
      fr: 'Samoa américaines',
      de: 'Amerikanisch-Samoa',
      es: 'Samoa Americana',
      pt: 'Samoa Americana',
      pl: 'American Samoa',
      'pt-BR': 'American Samoa',
      sv: 'Amerikanska Samoa',
      zh: '美属萨摩亚',
      ko: 'American Samoa',
      ja: 'American Samoa'
    },
    permalinks: {
      en: 'american-samoa',
      fr: 'samoa-americaines',
      de: 'amerikanisch-samoa',
      es: 'samoa-americana',
      pt: 'samoa-americana',
      pl: 'american-samoa',
      'pt-BR': 'american-samoa',
      sv: 'amerikanska-samoa'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  ATA: {
    iso2: 'aq',
    old_id: 2,
    subContinent: null,
    continent: 5,
    names: {
      en: 'Antartica',
      fr: 'Antartique',
      de: 'Antarktis',
      es: 'Antártida',
      pt: 'Antárctida',
      pl: 'Antartica',
      'pt-BR': 'Antartica',
      sv: 'Antarktis',
      zh: '南极洲',
      ko: 'Antartica',
      ja: 'Antartica'
    },
    permalinks: {
      en: 'antartica',
      fr: 'antartique',
      de: 'antarktis',
      es: 'antartida',
      pt: 'antarctida',
      pl: 'antartica',
      'pt-BR': 'antartica',
      sv: 'antarktis'
    },
    hasRegion: false,
    ins: {}
  },
  ATF: {
    iso2: 'tf',
    old_id: 460,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'French Southern Territories',
      fr: 'Terres australes françaises',
      de: 'Französische Süd- und Antarktisgebiete',
      es: 'Territorios Franceses del Sur',
      pt: 'Territórios Franceses do Sul',
      pl: 'French Southern Territories',
      'pt-BR': 'French Southern Territories',
      sv: 'Franska sydterritorierna',
      zh: '法属南半球领地',
      ko: 'French Southern Territories',
      ja: 'French Southern Territories'
    },
    permalinks: {
      en: 'french-southern-territories',
      fr: 'terres-australes-francaises',
      de: 'franzosische-sud-und-antarktisgebiete',
      es: 'territorios-franceses-del-sur',
      pt: 'territorios-franceses-do-sul',
      pl: 'french-southern-territories',
      'pt-BR': 'french-southern-territories',
      sv: 'franska-sydterritorierna'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  ATG: {
    iso2: 'ag',
    old_id: 143,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Antigua and Barbuda',
      fr: 'Antigua et Barbuda',
      de: 'Antigua und Barbuda',
      es: 'Antigua y Barbuda',
      pt: 'Antígua e Barbuda',
      pl: 'Antigua and Barbuda',
      'pt-BR': 'Antigua and Barbuda',
      sv: 'Antigua och Barbuda',
      zh: '安提瓜和巴布达',
      ko: 'Antigua and Barbuda',
      ja: 'Antigua and Barbuda'
    },
    permalinks: {
      en: 'antigua-and-barbuda',
      fr: 'antigua-et-barbuda',
      de: 'antigua-und-barbuda',
      es: 'antigua-y-barbuda',
      pt: 'antigua-e-barbuda',
      pl: 'antigua-and-barbuda',
      'pt-BR': 'antigua-and-barbuda',
      sv: 'antigua-och-barbuda'
    },
    hasRegion: false,
    ins: { fr: 'à ' }
  },
  AUS: {
    iso2: 'au',
    old_id: 4,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Australia',
      fr: 'Australie',
      de: 'Australien',
      es: 'Australia',
      pt: 'Austrália',
      pl: 'Australia',
      'pt-BR': 'Australia',
      sv: 'Australien',
      zh: '澳大利亚',
      ko: 'Australia',
      ja: 'Australia'
    },
    permalinks: {
      en: 'australia',
      fr: 'australie',
      de: 'australien',
      es: 'australia',
      pt: 'australia',
      pl: 'australia',
      'pt-BR': 'australia',
      sv: 'australien'
    },
    hasRegion: true,
    ins: {}
  },
  AUT: {
    iso2: 'at',
    old_id: 5,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Austria',
      fr: 'Autriche',
      de: 'Österreich',
      es: 'Austria',
      pt: 'Áustria',
      pl: 'Austria',
      'pt-BR': 'Austria',
      sv: 'Österrike',
      zh: '奥地利',
      ko: 'Austria',
      ja: 'Austria'
    },
    permalinks: {
      en: 'austria',
      fr: 'autriche',
      de: 'osterreich',
      es: 'austria',
      pt: 'austria',
      pl: 'austria',
      'pt-BR': 'austria',
      sv: 'osterrike'
    },
    hasRegion: false,
    ins: {}
  },
  AZE: {
    iso2: 'az',
    old_id: 161,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Azerbaijan',
      fr: 'Azerbaïdjan',
      de: 'Aserbaidschan',
      es: 'Azerbayán',
      pt: 'Azerbaijão',
      pl: 'Azerbaijan',
      'pt-BR': 'Azerbaijan',
      sv: 'Azerbajdzjan',
      zh: '阿塞拜疆',
      ko: 'Azerbaijan',
      ja: 'Azerbaijan'
    },
    permalinks: {
      en: 'azerbaijan',
      fr: 'azerbaidjan',
      de: 'aserbaidschan',
      es: 'azerbayan',
      pt: 'azerbaijao',
      pl: 'azerbaijan',
      'pt-BR': 'azerbaijan',
      sv: 'azerbajdzjan'
    },
    hasRegion: false,
    ins: {}
  },
  BDI: {
    iso2: 'bi',
    old_id: 182,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Burundi',
      fr: 'Burundi',
      de: 'Burundi',
      es: 'Burundi',
      pt: 'Burundi',
      pl: 'Burundi',
      'pt-BR': 'Burundi',
      sv: 'Burundi',
      zh: '布隆迪',
      ko: 'Burundi',
      ja: 'Burundi'
    },
    permalinks: {
      en: 'burundi',
      fr: 'burundi',
      de: 'burundi',
      es: 'burundi',
      pt: 'burundi',
      pl: 'burundi',
      'pt-BR': 'burundi',
      sv: 'burundi'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BEL: {
    iso2: 'be',
    old_id: 7,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Belgium',
      fr: 'Belgique',
      de: 'Belgien',
      es: 'Bélgica',
      pt: 'Bélgica',
      pl: 'Belgium',
      'pt-BR': 'Belgium',
      sv: 'Belgien',
      zh: '比利时',
      ko: 'Belgium',
      ja: 'Belgium'
    },
    permalinks: {
      en: 'belgium',
      fr: 'belgique',
      de: 'belgien',
      es: 'belgica',
      pt: 'belgica',
      pl: 'belgium',
      'pt-BR': 'belgium',
      sv: 'belgien'
    },
    hasRegion: true,
    ins: {}
  },
  BEN: {
    iso2: 'bj',
    old_id: 164,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Benin',
      fr: 'Bénin',
      de: 'Benin',
      es: 'Benín',
      pt: 'Benim',
      pl: 'Benin',
      'pt-BR': 'Benin',
      sv: 'Benin',
      zh: '贝宁',
      ko: 'Benin',
      ja: 'Benin'
    },
    permalinks: {
      en: 'benin',
      fr: 'benin',
      de: 'benin',
      es: 'benin',
      pt: 'benim',
      pl: 'benin',
      'pt-BR': 'benin',
      sv: 'benin'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BES: {
    iso2: 'bq',
    old_id: 449,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Bonaire, Sint Eustatius and Saba',
      fr: 'Bonaire, Saint-Eustache et Saba',
      de: 'Bonaire, Sint Eustatius und Saba',
      es: 'Islas BES (Caribe Neerlandés)',
      pt: 'Bonaire, Santo Eustáquio e Saba',
      pl: 'Bonaire, Sint Eustatius and Saba',
      'pt-BR': 'Bonaire, Sint Eustatius and Saba',
      sv: 'Bonaire, Sint Eustatius och Saba',
      zh: '博奈尔、圣尤斯特歇斯岛和萨巴',
      ko: 'Bonaire, Sint Eustatius and Saba',
      ja: 'Bonaire, Sint Eustatius and Saba'
    },
    permalinks: {
      en: 'bonaire-sint-eustatius-and-saba',
      fr: 'bonaire-saint-eustache-et-saba',
      de: 'bonaire-sint-eustatius-und-saba',
      es: 'islas-bes-caribe-neerlandes',
      pt: 'bonaire-santo-eustaquio-e-saba',
      pl: 'bonaire-sint-eustatius-and-saba',
      'pt-BR': 'bonaire-sint-eustatius-and-saba',
      sv: 'bonaire-sint-eustatius-och-saba'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BFA: {
    iso2: 'bf',
    old_id: 183,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Burkina Faso',
      fr: 'Burkina Faso',
      de: 'Burkina Faso',
      es: 'Burquina Faso',
      pt: 'Burkina Faso',
      pl: 'Burkina Faso',
      'pt-BR': 'Burkina Faso',
      sv: 'Burkina Faso',
      zh: '布基纳法索',
      ko: 'Burkina Faso',
      ja: 'Burkina Faso'
    },
    permalinks: {
      en: 'burkina-faso',
      fr: 'burkina-faso',
      de: 'burkina-faso',
      es: 'burquina-faso',
      pt: 'burkina-faso',
      pl: 'burkina-faso',
      'pt-BR': 'burkina-faso',
      sv: 'burkina-faso'
    },
    hasRegion: false,
    ins: {}
  },
  BGD: {
    iso2: 'bd',
    old_id: 108,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Bangladesh',
      fr: 'Bangladesh',
      de: 'Bangladesch',
      es: 'Bangladesh',
      pt: 'Bangladesh',
      pl: 'Bangladesh',
      'pt-BR': 'Bangladesh',
      sv: 'Bangladesh',
      zh: '孟加拉',
      ko: 'Bangladesh',
      ja: 'Bangladesh'
    },
    permalinks: {
      en: 'bangladesh',
      fr: 'bangladesh',
      de: 'bangladesch',
      es: 'bangladesh',
      pt: 'bangladesh',
      pl: 'bangladesh',
      'pt-BR': 'bangladesh',
      sv: 'bangladesh'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BGR: {
    iso2: 'bg',
    old_id: 181,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Bulgaria',
      fr: 'Bulgarie',
      de: 'Bulgarien',
      es: 'Bulgaria',
      pt: 'Bulgária',
      pl: 'Bulgaria',
      'pt-BR': 'Bulgaria',
      sv: 'Bulgarien',
      zh: '保加利亚',
      ko: 'Bulgaria',
      ja: 'Bulgaria'
    },
    permalinks: {
      en: 'bulgaria',
      fr: 'bulgarie',
      de: 'bulgarien',
      es: 'bulgaria',
      pt: 'bulgaria',
      pl: 'bulgaria',
      'pt-BR': 'bulgaria',
      sv: 'bulgarien'
    },
    hasRegion: false,
    ins: {}
  },
  BHR: {
    iso2: 'bh',
    old_id: 162,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Bahrain',
      fr: 'Bahreïn',
      de: 'Bahrain',
      es: 'Bahrein',
      pt: 'Barein',
      pl: 'Bahrain',
      'pt-BR': 'Bahrain',
      sv: 'Bahrain',
      zh: '巴林',
      ko: 'Bahrain',
      ja: 'Bahrain'
    },
    permalinks: {
      en: 'bahrain',
      fr: 'bahrein',
      de: 'bahrain',
      es: 'bahrein',
      pt: 'barein',
      pl: 'bahrain',
      'pt-BR': 'bahrain',
      sv: 'bahrain'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BHS: {
    iso2: 'bs',
    old_id: 123,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Bahamas',
      fr: 'Bahamas',
      de: 'Bahamas',
      es: 'Bahamas',
      pt: 'Bahamas',
      pl: 'Bahamas',
      'pt-BR': 'Bahamas',
      sv: 'Bahamas',
      zh: '巴哈马',
      ko: 'Bahamas',
      ja: 'Bahamas'
    },
    permalinks: {
      en: 'bahamas',
      fr: 'bahamas',
      de: 'bahamas',
      es: 'bahamas',
      pt: 'bahamas',
      pl: 'bahamas',
      'pt-BR': 'bahamas',
      sv: 'bahamas'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  BIH: {
    iso2: 'ba',
    old_id: 119,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Bosnia and Herzegovina',
      fr: 'Bosnie-Herzégovine',
      de: 'Bosnien und Herzegowina',
      es: 'Bosnia y Herzegovina',
      pt: 'Bósnia-Herzegóvina',
      pl: 'Bosnia and Herzegovina',
      'pt-BR': 'Bosnia and Herzegovina',
      sv: 'Bosnien-Hercegovina',
      zh: '波斯尼亚和黑塞哥维那',
      ko: 'Bosnia and Herzegovina',
      ja: 'Bosnia and Herzegovina'
    },
    permalinks: {
      en: 'bosnia-and-herzegovina',
      fr: 'bosnie-herzegovine',
      de: 'bosnien-und-herzegowina',
      es: 'bosnia-y-herzegovina',
      pt: 'bosnia-herzegovina',
      pl: 'bosnia-and-herzegovina',
      'pt-BR': 'bosnia-and-herzegovina',
      sv: 'bosnien-hercegovina'
    },
    hasRegion: false,
    ins: {}
  },
  BLM: {
    iso2: 'bl',
    old_id: 257,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Barthélemy',
      fr: 'Saint Barthélemy',
      de: 'Saint-Barthélemy',
      es: 'San Bartolomé',
      pt: 'Saint Barthélemy',
      pl: 'Saint Barthélemy',
      'pt-BR': 'Saint Barthélemy',
      sv: 'Saint-Barthélemy',
      zh: '圣巴泰勒米岛',
      ko: 'Saint Barthélemy',
      ja: 'Saint Barthélemy'
    },
    permalinks: {
      en: 'saint-barthelemy',
      fr: 'saint-barthelemy',
      de: 'saint-barthelemy',
      es: 'san-bartolome',
      pt: 'saint-barthelemy',
      pl: 'saint-barthelemy',
      'pt-BR': 'saint-barthelemy',
      sv: 'saint-barthelemy'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BLR: {
    iso2: 'by',
    old_id: 136,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Belarus',
      fr: 'Biélorussie',
      de: 'Weißrussland',
      es: 'Bielorrusia',
      pt: 'Bielorússia',
      pl: 'Belarus',
      'pt-BR': 'Belarus',
      sv: 'Vitryssland',
      zh: '白俄罗斯',
      ko: 'Belarus',
      ja: 'Belarus'
    },
    permalinks: {
      en: 'belarus',
      fr: 'bielorussie',
      de: 'weissrussland',
      es: 'bielorrusia',
      pt: 'bielorussia',
      pl: 'belarus',
      'pt-BR': 'belarus',
      sv: 'vitryssland'
    },
    hasRegion: false,
    ins: {}
  },
  BLZ: {
    iso2: 'bz',
    old_id: 134,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Belize',
      fr: 'Belize',
      de: 'Belize',
      es: 'Belice',
      pt: 'Belize',
      pl: 'Belize',
      'pt-BR': 'Belize',
      sv: 'Belize',
      zh: '伯利兹',
      ko: 'Belize',
      ja: 'Belize'
    },
    permalinks: {
      en: 'belize',
      fr: 'belize',
      de: 'belize',
      es: 'belice',
      pt: 'belize',
      pl: 'belize',
      'pt-BR': 'belize',
      sv: 'belize'
    },
    hasRegion: false,
    ins: { fr: 'à ' }
  },
  BMU: {
    iso2: 'bm',
    old_id: 8,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Bermuda',
      fr: 'Bermudes',
      de: 'Bermuda',
      es: 'Islas Bermudas',
      pt: 'Bermuda',
      pl: 'Bermuda',
      'pt-BR': 'Bermuda',
      sv: 'Bermuda',
      zh: '百慕大',
      ko: 'Bermuda',
      ja: 'Bermuda'
    },
    permalinks: {
      en: 'bermuda',
      fr: 'bermudes',
      de: 'bermuda',
      es: 'islas-bermudas',
      pt: 'bermuda',
      pl: 'bermuda',
      'pt-BR': 'bermuda',
      sv: 'bermuda'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  BOL: {
    iso2: 'bo',
    old_id: 179,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Bolivia',
      fr: 'Bolivie',
      de: 'Bolivien, Plurinationaler Staat',
      es: 'Bolivia, Estado plurinacional de',
      pt: 'Bolívia, Estado Plurinacional da',
      pl: 'Bolivia',
      'pt-BR': 'Bolivia',
      sv: 'Bolivia, Mångnationella staten',
      zh: '玻利维亚共和国',
      ko: 'Bolivia',
      ja: 'Bolivia'
    },
    permalinks: {
      en: 'bolivia',
      fr: 'bolivie',
      de: 'bolivien-plurinationaler-staat',
      es: 'bolivia-estado-plurinacional-de',
      pt: 'bolivia-estado-plurinacional-da',
      pl: 'bolivia',
      'pt-BR': 'bolivia',
      sv: 'bolivia-mangnationella-staten'
    },
    hasRegion: false,
    ins: {}
  },
  BRB: {
    iso2: 'bb',
    old_id: 6,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Barbados',
      fr: 'Barbade',
      de: 'Barbados',
      es: 'Barbados',
      pt: 'Barbados',
      pl: 'Barbados',
      'pt-BR': 'Barbados',
      sv: 'Barbados',
      zh: '巴巴多斯',
      ko: 'Barbados',
      ja: 'Barbados'
    },
    permalinks: {
      en: 'barbados',
      fr: 'barbade',
      de: 'barbados',
      es: 'barbados',
      pt: 'barbados',
      pl: 'barbados',
      'pt-BR': 'barbados',
      sv: 'barbados'
    },
    hasRegion: false,
    ins: { fr: 'à la ' }
  },
  BRE: {
    iso2: 'br',
    old_id: 10,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Brazil',
      fr: 'Brésil',
      de: 'Brasilien',
      es: 'Brasil',
      pt: 'Brasil',
      pl: 'Brazil',
      'pt-BR': 'Brazil',
      sv: 'Brasilien',
      zh: '巴西',
      ko: 'Brazil',
      ja: 'Brazil'
    },
    permalinks: {
      en: 'brazil',
      fr: 'bresil',
      de: 'brasilien',
      es: 'brasil',
      pt: 'brasil',
      pl: 'brazil',
      'pt-BR': 'brazil',
      sv: 'brasilien'
    },
    hasRegion: false,
    ins: {}
  },
  BRN: {
    iso2: 'bn',
    old_id: 180,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Brunei',
      fr: 'Brunei',
      de: 'Brunei Darussalam',
      es: 'Brunei Darussalam',
      pt: 'Brunei',
      pl: 'Brunei',
      'pt-BR': 'Brunei',
      sv: 'Brunei',
      zh: '文莱',
      ko: 'Brunei',
      ja: 'Brunei'
    },
    permalinks: {
      en: 'brunei',
      fr: 'brunei',
      de: 'brunei-darussalam',
      es: 'brunei-darussalam',
      pt: 'brunei',
      pl: 'brunei',
      'pt-BR': 'brunei',
      sv: 'brunei'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  BTN: {
    iso2: 'bt',
    old_id: 9,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Bhutan',
      fr: 'Bhoutan',
      de: 'Bhutan',
      es: 'Bhután',
      pt: 'Butão',
      pl: 'Bhutan',
      'pt-BR': 'Bhutan',
      sv: 'Bhutan',
      zh: '不丹',
      ko: 'Bhutan',
      ja: 'Bhutan'
    },
    permalinks: {
      en: 'bhutan',
      fr: 'bhoutan',
      de: 'bhutan',
      es: 'bhutan',
      pt: 'butao',
      pl: 'bhutan',
      'pt-BR': 'bhutan',
      sv: 'bhutan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  BVT: {
    iso2: 'bv',
    old_id: 450,
    subContinent: null,
    continent: 5,
    names: {
      en: 'Bouvet Island',
      fr: 'Bouvet, Île',
      de: 'Bouvet-Insel',
      es: 'Isla Bouvet',
      pt: 'Ilha Bouvet',
      pl: 'Bouvet Island',
      'pt-BR': 'Bouvet Island',
      sv: 'Bouvetön',
      zh: '布维群岛',
      ko: 'Bouvet Island',
      ja: 'Bouvet Island'
    },
    permalinks: {
      en: 'bouvet-island',
      fr: 'bouvet-ile',
      de: 'bouvet-insel',
      es: 'isla-bouvet',
      pt: 'ilha-bouvet',
      pl: 'bouvet-island',
      'pt-BR': 'bouvet-island',
      sv: 'bouveton'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'île Bouvet" }
  },
  BWA: {
    iso2: 'bw',
    old_id: 171,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Botswana',
      fr: 'Botswana',
      de: 'Botsuana',
      es: 'Botsuana',
      pt: 'Botsuana',
      pl: 'Botswana',
      'pt-BR': 'Botswana',
      sv: 'Botswana',
      zh: '博兹瓦那',
      ko: 'Botswana',
      ja: 'Botswana'
    },
    permalinks: {
      en: 'botswana',
      fr: 'botswana',
      de: 'botsuana',
      es: 'botsuana',
      pt: 'botsuana',
      pl: 'botswana',
      'pt-BR': 'botswana',
      sv: 'botswana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CAF: {
    iso2: 'cf',
    old_id: 184,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Central African Republic',
      fr: 'République centrafricaine',
      de: 'Zentralafrikanische Republik',
      es: 'República Centro-africana',
      pt: 'República Centro-Africana',
      pl: 'Central African Republic',
      'pt-BR': 'Central African Republic',
      sv: 'Centralafrikanska republiken',
      zh: '中非',
      ko: 'Central African Republic',
      ja: 'Central African Republic'
    },
    permalinks: {
      en: 'central-african-republic',
      fr: 'republique-centrafricaine',
      de: 'zentralafrikanische-republik',
      es: 'republica-centro-africana',
      pt: 'republica-centro-africana',
      pl: 'central-african-republic',
      'pt-BR': 'central-african-republic',
      sv: 'centralafrikanska-republiken'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: '' }
  },
  CAN: {
    iso2: 'ca',
    old_id: 13,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Canada',
      fr: 'Canada',
      de: 'Kanada',
      es: 'Canadá',
      pt: 'Canadá',
      pl: 'Canada',
      'pt-BR': 'Canada',
      sv: 'Kanada',
      zh: '加拿大',
      ko: 'Canada',
      ja: 'Canada'
    },
    permalinks: {
      en: 'canada',
      fr: 'canada',
      de: 'kanada',
      es: 'canada',
      pt: 'canada',
      pl: 'canada',
      'pt-BR': 'canada',
      sv: 'kanada'
    },
    hasRegion: true,
    ins: { fr: 'au' }
  },
  CCK: {
    iso2: 'cc',
    old_id: 451,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Cocos (Keeling) Islands',
      fr: 'Cocos (Keeling), Îles',
      de: 'Kokos-(Keeling-)Inseln',
      es: 'Islas Cocos (Keeling)',
      pt: 'Ilhas Cocos',
      pl: 'Cocos (Keeling) Islands',
      'pt-BR': 'Cocos (Keeling) Islands',
      sv: 'Kokosöarna',
      zh: '科科斯群岛',
      ko: 'Cocos (Keeling) Islands',
      ja: 'Cocos (Keeling) Islands'
    },
    permalinks: {
      en: 'cocos-keeling-islands',
      fr: 'cocos-keeling-iles',
      de: 'kokos-keeling-inseln',
      es: 'islas-cocos-keeling',
      pt: 'ilhas-cocos',
      pl: 'cocos-keeling-islands',
      'pt-BR': 'cocos-keeling-islands',
      sv: 'kokosoarna'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'dans les ïles Cocos' }
  },
  CHE: {
    iso2: 'ch',
    old_id: 85,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Switzerland',
      fr: 'Suisse',
      de: 'Schweiz',
      es: 'Suiza',
      pt: 'Suíça',
      pl: 'Switzerland',
      'pt-BR': 'Switzerland',
      sv: 'Schweiz',
      zh: '瑞士',
      ko: 'Switzerland',
      ja: 'Switzerland'
    },
    permalinks: {
      en: 'switzerland',
      fr: 'suisse',
      de: 'schweiz',
      es: 'suiza',
      pt: 'suica',
      pl: 'switzerland',
      'pt-BR': 'switzerland',
      sv: 'schweiz'
    },
    hasRegion: true,
    ins: {}
  },
  CHL: {
    iso2: 'cl',
    old_id: 15,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Chile',
      fr: 'Chili',
      de: 'Chile',
      es: 'Chile',
      pt: 'Chile',
      pl: 'Chile',
      'pt-BR': 'Chile',
      sv: 'Chile',
      zh: '智利',
      ko: 'Chile',
      ja: 'Chile'
    },
    permalinks: {
      en: 'chile',
      fr: 'chili',
      de: 'chile',
      es: 'chile',
      pt: 'chile',
      pl: 'chile',
      'pt-BR': 'chile',
      sv: 'chile'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CHN: {
    iso2: 'cn',
    old_id: 16,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'China',
      fr: 'Chine',
      de: 'China',
      es: 'China',
      pt: 'China',
      pl: 'China',
      'pt-BR': 'China',
      sv: 'Kina',
      zh: '中国',
      ko: 'China',
      ja: 'China'
    },
    permalinks: {
      en: 'china',
      fr: 'chine',
      de: 'china',
      es: 'china',
      pt: 'china',
      pl: 'china',
      'pt-BR': 'china',
      sv: 'kina'
    },
    hasRegion: false,
    ins: {}
  },
  CIV: {
    iso2: 'ci',
    old_id: 452,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Ivory Coast',
      fr: "Côte d'Ivoire",
      de: "Côte d'Ivoire",
      es: 'Costa de Marfíl',
      pt: 'Costa do Marfim',
      pl: 'Ivory Coast',
      'pt-BR': 'Ivory Coast',
      sv: 'Elfenbenskusten',
      zh: '科特迪瓦',
      ko: 'Ivory Coast',
      ja: 'Ivory Coast'
    },
    permalinks: {
      en: 'ivory-coast',
      fr: 'cote-d-ivoire',
      de: 'cote-d-ivoire',
      es: 'costa-de-marfil',
      pt: 'costa-do-marfim',
      pl: 'ivory-coast',
      'pt-BR': 'ivory-coast',
      sv: 'elfenbenskusten'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  CMR: {
    iso2: 'cm',
    old_id: 174,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Cameroon',
      fr: 'Cameroun',
      de: 'Kamerun',
      es: 'Camerún',
      pt: 'Camarões',
      pl: 'Cameroon',
      'pt-BR': 'Cameroon',
      sv: 'Kamerun',
      zh: '喀麦隆',
      ko: 'Cameroon',
      ja: 'Cameroon'
    },
    permalinks: {
      en: 'cameroon',
      fr: 'cameroun',
      de: 'kamerun',
      es: 'camerun',
      pt: 'camaroes',
      pl: 'cameroon',
      'pt-BR': 'cameroon',
      sv: 'kamerun'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  COD: {
    iso2: 'cd',
    old_id: 188,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Democratic Republic of the Congo',
      fr: 'République démocratique du Congo',
      de: 'Demokratische Republik Kongo',
      es: 'Congo, República Democrática del',
      pt: 'Congo, República Democrática do',
      pl: 'Democratic Republic of the Congo',
      'pt-BR': 'Democratic Republic of the Congo',
      sv: 'Kongo, demokratiska republiken',
      zh: '刚果民主共和国',
      ko: 'Democratic Republic of the Congo',
      ja: 'Democratic Republic of the Congo'
    },
    permalinks: {
      en: 'democratic-republic-of-the-congo',
      fr: 'republique-democratique-du-congo',
      de: 'demokratische-republik-kongo',
      es: 'congo-republica-democratica-del',
      pt: 'congo-republica-democratica-do',
      pl: 'democratic-republic-of-the-congo',
      'pt-BR': 'democratic-republic-of-the-congo',
      sv: 'kongo-demokratiska-republiken'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  COG: {
    iso2: 'cg',
    old_id: 187,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Congo',
      fr: 'Congo',
      de: 'Kongo',
      es: 'Congo',
      pt: 'Congo',
      pl: 'Congo',
      'pt-BR': 'Congo',
      sv: 'Kongo',
      zh: '刚果',
      ko: 'Congo',
      ja: 'Congo'
    },
    permalinks: {
      en: 'congo',
      fr: 'congo',
      de: 'kongo',
      es: 'congo',
      pt: 'congo',
      pl: 'congo',
      'pt-BR': 'congo',
      sv: 'kongo'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  COK: {
    iso2: 'ck',
    old_id: 189,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Cook Islands',
      fr: 'Îles Cook',
      de: 'Cookinseln',
      es: 'Islas Cook',
      pt: 'Ilhas Cook',
      pl: 'Cook Islands',
      'pt-BR': 'Cook Islands',
      sv: 'Cooköarna',
      zh: '库克群岛',
      ko: 'Cook Islands',
      ja: 'Cook Islands'
    },
    permalinks: {
      en: 'cook-islands',
      fr: 'iles-cook',
      de: 'cookinseln',
      es: 'islas-cook',
      pt: 'ilhas-cook',
      pl: 'cook-islands',
      'pt-BR': 'cook-islands',
      sv: 'cookoarna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux ' }
  },
  COL: {
    iso2: 'co',
    old_id: 111,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Colombia',
      fr: 'Colombie',
      de: 'Kolumbien',
      es: 'Colombia',
      pt: 'Colômbia',
      pl: 'Colombia',
      'pt-BR': 'Colombia',
      sv: 'Colombia',
      zh: '哥伦比亚',
      ko: 'Colombia',
      ja: 'Colombia'
    },
    permalinks: {
      en: 'colombia',
      fr: 'colombie',
      de: 'kolumbien',
      es: 'colombia',
      pt: 'colombia',
      pl: 'colombia',
      'pt-BR': 'colombia',
      sv: 'colombia'
    },
    hasRegion: false,
    ins: {}
  },
  COM: {
    iso2: 'km',
    old_id: 186,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Comoros',
      fr: 'Comores',
      de: 'Komoren',
      es: 'Comores, Islas',
      pt: 'Comoros',
      pl: 'Comoros',
      'pt-BR': 'Comoros',
      sv: 'Comorerna',
      zh: '科摩罗',
      ko: 'Comoros',
      ja: 'Comoros'
    },
    permalinks: {
      en: 'comoros',
      fr: 'comores',
      de: 'komoren',
      es: 'comores-islas',
      pt: 'comoros',
      pl: 'comoros',
      'pt-BR': 'comoros',
      sv: 'comorerna'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  CPV: {
    iso2: 'cv',
    old_id: 109,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Cape Verde',
      fr: 'Cap Vert',
      de: 'Cabo Verde',
      es: 'Cabo Verde',
      pt: 'Cabo Verde',
      pl: 'Cape Verde',
      'pt-BR': 'Cape Verde',
      sv: 'Kap Verde',
      zh: '佛得角',
      ko: 'Cape Verde',
      ja: 'Cape Verde'
    },
    permalinks: {
      en: 'cape-verde',
      fr: 'cap-vert',
      de: 'cabo-verde',
      es: 'cabo-verde',
      pt: 'cabo-verde',
      pl: 'cape-verde',
      'pt-BR': 'cape-verde',
      sv: 'kap-verde'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CRI: {
    iso2: 'cr',
    old_id: 17,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Costa Rica',
      fr: 'Costa Rica',
      de: 'Costa Rica',
      es: 'Costa Rica',
      pt: 'Costa Rica',
      pl: 'Costa Rica',
      'pt-BR': 'Costa Rica',
      sv: 'Costa Rica',
      zh: '哥斯达黎加',
      ko: 'Costa Rica',
      ja: 'Costa Rica'
    },
    permalinks: {
      en: 'costa-rica',
      fr: 'costa-rica',
      de: 'costa-rica',
      es: 'costa-rica',
      pt: 'costa-rica',
      pl: 'costa-rica',
      'pt-BR': 'costa-rica',
      sv: 'costa-rica'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  CUB: {
    iso2: 'cu',
    old_id: 19,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Cuba',
      fr: 'Cuba',
      de: 'Kuba',
      es: 'Cuba',
      pt: 'Cuba',
      pl: 'Cuba',
      'pt-BR': 'Cuba',
      sv: 'Kuba',
      zh: '古巴',
      ko: 'Cuba',
      ja: 'Cuba'
    },
    permalinks: { en: 'cuba', fr: 'cuba', de: 'kuba', es: 'cuba', pt: 'cuba', pl: 'cuba', 'pt-BR': 'cuba', sv: 'kuba' },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  CUW: {
    iso2: 'cw',
    old_id: 190,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Curaçao',
      fr: 'Curaçao',
      de: 'Curaçao',
      es: 'Curasao',
      pt: 'Curação',
      pl: 'Curaçao',
      'pt-BR': 'Curaçao',
      sv: 'Curaçao',
      zh: '库拉索',
      ko: 'Curaçao',
      ja: 'Curaçao'
    },
    permalinks: {
      en: 'curacao',
      fr: 'curacao',
      de: 'curacao',
      es: 'curasao',
      pt: 'curacao',
      pl: 'curacao',
      'pt-BR': 'curacao',
      sv: 'curacao'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  CXR: {
    iso2: 'cx',
    old_id: 453,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Christmas Island',
      fr: 'Christmas, Île',
      de: 'Weihnachtsinseln',
      es: 'Isla de Navidad',
      pt: 'Ilha Natal',
      pl: 'Christmas Island',
      'pt-BR': 'Christmas Island',
      sv: 'Julön',
      zh: '圣诞岛',
      ko: 'Christmas Island',
      ja: 'Christmas Island'
    },
    permalinks: {
      en: 'christmas-island',
      fr: 'christmas-ile',
      de: 'weihnachtsinseln',
      es: 'isla-de-navidad',
      pt: 'ilha-natal',
      pl: 'christmas-island',
      'pt-BR': 'christmas-island',
      sv: 'julon'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'Île Christmas" }
  },
  CYM: {
    iso2: 'ky',
    old_id: 14,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Cayman Islands',
      fr: 'Iles Cayman',
      de: 'Cayman-Inseln',
      es: 'Islas Caimán',
      pt: 'Ilhas Caimão',
      pl: 'Cayman Islands',
      'pt-BR': 'Cayman Islands',
      sv: 'Caymanöarna',
      zh: '开曼群岛',
      ko: 'Cayman Islands',
      ja: 'Cayman Islands'
    },
    permalinks: {
      en: 'cayman-islands',
      fr: 'iles-cayman',
      de: 'cayman-inseln',
      es: 'islas-caiman',
      pt: 'ilhas-caimao',
      pl: 'cayman-islands',
      'pt-BR': 'cayman-islands',
      sv: 'caymanoarna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  CYP: {
    iso2: 'cy',
    old_id: 20,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Cyprus',
      fr: 'Chypre',
      de: 'Zypern',
      es: 'Chipre',
      pt: 'Chipre',
      pl: 'Cyprus',
      'pt-BR': 'Cyprus',
      sv: 'Cypern',
      zh: '塞浦路斯',
      ko: 'Cyprus',
      ja: 'Cyprus'
    },
    permalinks: {
      en: 'cyprus',
      fr: 'chypre',
      de: 'zypern',
      es: 'chipre',
      pt: 'chipre',
      pl: 'cyprus',
      'pt-BR': 'cyprus',
      sv: 'cypern'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  CZE: {
    iso2: 'cz',
    old_id: 21,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Czech Republic',
      fr: 'République Tchèque',
      de: 'Tschechische Republik',
      es: 'República Checa',
      pt: 'República Checa',
      pl: 'Czech Republic',
      'pt-BR': 'Czech Republic',
      sv: 'Tjeckien',
      zh: '捷克',
      ko: 'Czech Republic',
      ja: 'Czech Republic'
    },
    permalinks: {
      en: 'czech-republic',
      fr: 'republique-tcheque',
      de: 'tschechische-republik',
      es: 'republica-checa',
      pt: 'republica-checa',
      pl: 'czech-republic',
      'pt-BR': 'czech-republic',
      sv: 'tjeckien'
    },
    hasRegion: false,
    ins: {}
  },
  DEU: {
    iso2: 'de',
    old_id: 31,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Germany',
      fr: 'Allemagne',
      de: 'Deutschland',
      es: 'Alemania',
      pt: 'Alemanha',
      pl: 'Germany',
      'pt-BR': 'Germany',
      sv: 'Tyskland',
      zh: '德国',
      ko: 'Germany',
      ja: 'Germany'
    },
    permalinks: {
      en: 'germany',
      fr: 'allemagne',
      de: 'deutschland',
      es: 'alemania',
      pt: 'alemanha',
      pl: 'germany',
      'pt-BR': 'germany',
      sv: 'tyskland'
    },
    hasRegion: true,
    ins: {}
  },
  DJI: {
    iso2: 'dj',
    old_id: 191,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Djibouti',
      fr: 'Djibouti',
      de: 'Dschibuti',
      es: 'Yibuti',
      pt: 'Djibouti',
      pl: 'Djibouti',
      'pt-BR': 'Djibouti',
      sv: 'Djibouti',
      zh: '吉布提',
      ko: 'Djibouti',
      ja: 'Djibouti'
    },
    permalinks: {
      en: 'djibouti',
      fr: 'djibouti',
      de: 'dschibuti',
      es: 'yibuti',
      pt: 'djibouti',
      pl: 'djibouti',
      'pt-BR': 'djibouti',
      sv: 'djibouti'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  DMA: {
    iso2: 'dm',
    old_id: 192,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Dominica',
      fr: 'Dominique',
      de: 'Dominica',
      es: 'Dominica',
      pt: 'Dominica',
      pl: 'Dominica',
      'pt-BR': 'Dominica',
      sv: 'Dominica',
      zh: '多米尼克',
      ko: 'Dominica',
      ja: 'Dominica'
    },
    permalinks: {
      en: 'dominica',
      fr: 'dominique',
      de: 'dominica',
      es: 'dominica',
      pt: 'dominica',
      pl: 'dominica',
      'pt-BR': 'dominica',
      sv: 'dominica'
    },
    hasRegion: false,
    ins: {}
  },
  DNK: {
    iso2: 'dk',
    old_id: 22,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Denmark',
      fr: 'Danemark',
      de: 'Dänemark',
      es: 'Dinamarca',
      pt: 'Dinamarca',
      pl: 'Denmark',
      'pt-BR': 'Denmark',
      sv: 'Danmark',
      zh: '丹麦',
      ko: 'Denmark',
      ja: 'Denmark'
    },
    permalinks: {
      en: 'denmark',
      fr: 'danemark',
      de: 'danemark',
      es: 'dinamarca',
      pt: 'dinamarca',
      pl: 'denmark',
      'pt-BR': 'denmark',
      sv: 'danmark'
    },
    hasRegion: true,
    ins: { fr: 'au' }
  },
  DOM: {
    iso2: 'do',
    old_id: 193,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Dominican Republic',
      fr: 'République Dominicaine',
      de: 'Dominikanische Republik',
      es: 'República Dominicana',
      pt: 'República Dominicana',
      pl: 'Dominican Republic',
      'pt-BR': 'Dominican Republic',
      sv: 'Dominikanska republiken',
      zh: '多米尼加共和国',
      ko: 'Dominican Republic',
      ja: 'Dominican Republic'
    },
    permalinks: {
      en: 'dominican-republic',
      fr: 'republique-dominicaine',
      de: 'dominikanische-republik',
      es: 'republica-dominicana',
      pt: 'republica-dominicana',
      pl: 'dominican-republic',
      'pt-BR': 'dominican-republic',
      sv: 'dominikanska-republiken'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  ECU: {
    iso2: 'ec',
    old_id: 23,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Ecuador',
      fr: 'Equateur',
      de: 'Ecuador',
      es: 'Ecuador',
      pt: 'Equador',
      pl: 'Ecuador',
      'pt-BR': 'Ecuador',
      sv: 'Ecuador',
      zh: '厄瓜多尔',
      ko: 'Ecuador',
      ja: 'Ecuador'
    },
    permalinks: {
      en: 'ecuador',
      fr: 'equateur',
      de: 'ecuador',
      es: 'ecuador',
      pt: 'equador',
      pl: 'ecuador',
      'pt-BR': 'ecuador',
      sv: 'ecuador'
    },
    hasRegion: false,
    ins: {}
  },
  EGY: {
    iso2: 'eg',
    old_id: 24,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Egypt',
      fr: 'Egypte',
      de: 'Ägypten',
      es: 'Egipto',
      pt: 'Egipto',
      pl: 'Egypt',
      'pt-BR': 'Egypt',
      sv: 'Egypten',
      zh: '埃及',
      ko: 'Egypt',
      ja: 'Egypt'
    },
    permalinks: {
      en: 'egypt',
      fr: 'egypte',
      de: 'agypten',
      es: 'egipto',
      pt: 'egipto',
      pl: 'egypt',
      'pt-BR': 'egypt',
      sv: 'egypten'
    },
    hasRegion: false,
    ins: {}
  },
  ERI: {
    iso2: 'er',
    old_id: 194,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Eritrea',
      fr: 'Érythrée',
      de: 'Eritrea',
      es: 'Eritrea',
      pt: 'Eritreia',
      pl: 'Eritrea',
      'pt-BR': 'Eritrea',
      sv: 'Eritrea',
      zh: '厄立特里亚',
      ko: 'Eritrea',
      ja: 'Eritrea'
    },
    permalinks: {
      en: 'eritrea',
      fr: 'erythree',
      de: 'eritrea',
      es: 'eritrea',
      pt: 'eritreia',
      pl: 'eritrea',
      'pt-BR': 'eritrea',
      sv: 'eritrea'
    },
    hasRegion: false,
    ins: {}
  },
  ESH: {
    iso2: 'eh',
    old_id: 454,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Western Sahara',
      fr: 'Sahara Occidental',
      de: 'Westsahara',
      es: 'Sahara Occidental',
      pt: 'Sahara Ocidental',
      pl: 'Western Sahara',
      'pt-BR': 'Western Sahara',
      sv: 'Västsahara',
      zh: '西撒哈拉',
      ko: 'Western Sahara',
      ja: 'Western Sahara'
    },
    permalinks: {
      en: 'western-sahara',
      fr: 'sahara-occidental',
      de: 'westsahara',
      es: 'sahara-occidental',
      pt: 'sahara-ocidental',
      pl: 'western-sahara',
      'pt-BR': 'western-sahara',
      sv: 'vastsahara'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'au' }
  },
  ESP: {
    iso2: 'es',
    old_id: 83,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Spain',
      fr: 'Espagne',
      de: 'Spanien',
      es: 'España',
      pt: 'Espanha',
      pl: 'Spain',
      'pt-BR': 'Spain',
      sv: 'Spanien',
      zh: '西班牙',
      ko: 'Spain',
      ja: 'Spain'
    },
    permalinks: {
      en: 'spain',
      fr: 'espagne',
      de: 'spanien',
      es: 'espana',
      pt: 'espanha',
      pl: 'spain',
      'pt-BR': 'spain',
      sv: 'spanien'
    },
    hasRegion: true,
    ins: {}
  },
  EST: {
    iso2: 'ee',
    old_id: 25,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Estonia',
      fr: 'Estonie',
      de: 'Estland',
      es: 'Estonia',
      pt: 'Estónia',
      pl: 'Estonia',
      'pt-BR': 'Estonia',
      sv: 'Estland',
      zh: '爱沙尼亚',
      ko: 'Estonia',
      ja: 'Estonia'
    },
    permalinks: {
      en: 'estonia',
      fr: 'estonie',
      de: 'estland',
      es: 'estonia',
      pt: 'estonia',
      pl: 'estonia',
      'pt-BR': 'estonia',
      sv: 'estland'
    },
    hasRegion: false,
    ins: {}
  },
  ETH: {
    iso2: 'et',
    old_id: 195,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Ethiopia',
      fr: 'Ethiopie',
      de: 'Äthiopien',
      es: 'Etiopía',
      pt: 'Etiópia',
      pl: 'Ethiopia',
      'pt-BR': 'Ethiopia',
      sv: 'Etiopien',
      zh: '埃塞俄比亚',
      ko: 'Ethiopia',
      ja: 'Ethiopia'
    },
    permalinks: {
      en: 'ethiopia',
      fr: 'ethiopie',
      de: 'athiopien',
      es: 'etiopia',
      pt: 'etiopia',
      pl: 'ethiopia',
      'pt-BR': 'ethiopia',
      sv: 'etiopien'
    },
    hasRegion: false,
    ins: {}
  },
  FIN: {
    iso2: 'fi',
    old_id: 28,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Finland',
      fr: 'Finlande',
      de: 'Finnland',
      es: 'Finlandia',
      pt: 'Finlândia',
      pl: 'Finland',
      'pt-BR': 'Finland',
      sv: 'Finland',
      zh: '芬兰',
      ko: 'Finland',
      ja: 'Finland'
    },
    permalinks: {
      en: 'finland',
      fr: 'finlande',
      de: 'finnland',
      es: 'finlandia',
      pt: 'finlandia',
      pl: 'finland',
      'pt-BR': 'finland',
      sv: 'finland'
    },
    hasRegion: true,
    ins: {}
  },
  FJI: {
    iso2: 'fj',
    old_id: 27,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Fiji',
      fr: 'Fidji',
      de: 'Fidschi',
      es: 'Fiyi',
      pt: 'Fiji',
      pl: 'Fiji',
      'pt-BR': 'Fiji',
      sv: 'Fiji',
      zh: '斐济',
      ko: 'Fiji',
      ja: 'Fiji'
    },
    permalinks: {
      en: 'fiji',
      fr: 'fidji',
      de: 'fidschi',
      es: 'fiyi',
      pt: 'fiji',
      pl: 'fiji',
      'pt-BR': 'fiji',
      sv: 'fiji'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  FLK: {
    iso2: 'fk',
    old_id: 26,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Falklands Islands',
      fr: 'Malouines',
      de: 'Falklandinseln (Malwinen)',
      es: 'Islas Falkland (Malvinas)',
      pt: 'Ilhas Falkland (Malvinas)',
      pl: 'Falklands Islands',
      'pt-BR': 'Falklands Islands',
      sv: 'Falklandsöarna (Malvinas)',
      zh: '福克兰群岛(马尔维纳斯)',
      ko: 'Falklands Islands',
      ja: 'Falklands Islands'
    },
    permalinks: {
      en: 'falklands-islands',
      fr: 'malouines',
      de: 'falklandinseln-malwinen',
      es: 'islas-falkland-malvinas',
      pt: 'ilhas-falkland-malvinas',
      pl: 'falklands-islands',
      'pt-BR': 'falklands-islands',
      sv: 'falklandsoarna-malvinas'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  FRA: {
    iso2: 'fr',
    old_id: 29,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'France',
      fr: 'France',
      de: 'Frankreich',
      es: 'Francia',
      pt: 'França',
      pl: 'France',
      'pt-BR': 'France',
      sv: 'Frankrike',
      zh: '法国',
      ko: 'France',
      ja: 'France'
    },
    permalinks: {
      en: 'france',
      fr: 'france',
      de: 'frankreich',
      es: 'francia',
      pt: 'franca',
      pl: 'france',
      'pt-BR': 'france',
      sv: 'frankrike'
    },
    hasRegion: true,
    ins: {}
  },
  FRO: {
    iso2: 'fo',
    old_id: 455,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Faroe Islands',
      fr: 'Féroé, Îles',
      de: 'Färöer-Inseln',
      es: 'Islas Feroe',
      pt: 'Ilhas Feroé',
      pl: 'Faroe Islands',
      'pt-BR': 'Faroe Islands',
      sv: 'Färöarna',
      zh: '法罗群岛',
      ko: 'Faroe Islands',
      ja: 'Faroe Islands'
    },
    permalinks: {
      en: 'faroe-islands',
      fr: 'feroe-iles',
      de: 'faroer-inseln',
      es: 'islas-feroe',
      pt: 'ilhas-feroe',
      pl: 'faroe-islands',
      'pt-BR': 'faroe-islands',
      sv: 'faroarna'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'dans les Îles Féroé' }
  },
  FSM: {
    iso2: 'fm',
    old_id: 217,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Micronesia (FSM)',
      fr: 'Micronesie (FSM)',
      de: 'Mikronesien, Föderierte Staaten von',
      es: 'Micronesia, Estados Federados de',
      pt: 'Micronesia, Estados Federados da',
      pl: 'Micronesia (FSM)',
      'pt-BR': 'Micronesia (FSM)',
      sv: 'Mikronesien, federala staterna',
      zh: '密克罗尼西亚',
      ko: 'Micronesia (FSM)',
      ja: 'Micronesia (FSM)'
    },
    permalinks: {
      en: 'micronesia-fsm',
      fr: 'micronesie-fsm',
      de: 'mikronesien-foderierte-staaten-von',
      es: 'micronesia-estados-federados-de',
      pt: 'micronesia-estados-federados-da',
      pl: 'micronesia-fsm',
      'pt-BR': 'micronesia-fsm',
      sv: 'mikronesien-federala-staterna'
    },
    hasRegion: false,
    ins: {}
  },
  GAB: {
    iso2: 'ga',
    old_id: 196,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Gabon',
      fr: 'Gabon',
      de: 'Gabun',
      es: 'Gabón',
      pt: 'Gabão',
      pl: 'Gabon',
      'pt-BR': 'Gabon',
      sv: 'Gabon',
      zh: '加蓬',
      ko: 'Gabon',
      ja: 'Gabon'
    },
    permalinks: {
      en: 'gabon',
      fr: 'gabon',
      de: 'gabun',
      es: 'gabon',
      pt: 'gabao',
      pl: 'gabon',
      'pt-BR': 'gabon',
      sv: 'gabon'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GBR: {
    iso2: 'gb',
    old_id: 94,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'United Kingdom',
      fr: 'Royaume-Uni',
      de: 'Vereinigtes Königreich',
      es: 'Reino Unido',
      pt: 'Reino Unido',
      pl: 'United Kingdom',
      'pt-BR': 'United Kingdom',
      sv: 'Storbritannien',
      zh: '英国',
      ko: 'United Kingdom',
      ja: 'United Kingdom'
    },
    permalinks: {
      en: 'united-kingdom',
      fr: 'royaume-uni',
      de: 'vereinigtes-konigreich',
      es: 'reino-unido',
      pt: 'reino-unido',
      pl: 'united-kingdom',
      'pt-BR': 'united-kingdom',
      sv: 'storbritannien'
    },
    hasRegion: true,
    ins: { en: 'in the', fr: 'au' }
  },
  GEO: {
    iso2: 'ge',
    old_id: 137,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Georgia (country)',
      fr: 'Géorgie (pays)',
      de: 'Georgien (land)',
      es: 'Georgia (país)',
      pt: 'Geórgia (país)',
      pl: 'Georgia (country)',
      'pt-BR': 'Georgia (country)',
      sv: 'Georgien (land)',
      zh: '格鲁吉亚',
      ko: 'Georgia (country)',
      ja: 'Georgia (country)'
    },
    permalinks: {
      en: 'georgia-country',
      fr: 'georgie-pays',
      de: 'georgien-land',
      es: 'georgia-pais',
      pt: 'georgia-pais',
      pl: 'georgia-country',
      'pt-BR': 'georgia-country',
      sv: 'georgien-land'
    },
    hasRegion: false,
    ins: { en: 'in Georgia', fr: 'en' }
  },
  GGY: {
    iso2: 'gg',
    old_id: 173,
    subContinent: 830,
    continent: 1,
    names: {
      en: 'Guernsey',
      fr: 'Guernesey',
      de: 'Guernsey',
      es: 'Guernsey',
      pt: 'Guernsey',
      pl: 'Guernsey',
      'pt-BR': 'Guernsey',
      sv: 'Guernsey',
      zh: '根西岛',
      ko: 'Guernsey',
      ja: 'Guernsey'
    },
    permalinks: {
      en: 'guernsey',
      fr: 'guernesey',
      de: 'guernsey',
      es: 'guernsey',
      pt: 'guernsey',
      pl: 'guernsey',
      'pt-BR': 'guernsey',
      sv: 'guernsey'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  GHA: {
    iso2: 'gh',
    old_id: 100,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Ghana',
      fr: 'Ghana',
      de: 'Ghana',
      es: 'Ghana',
      pt: 'Gana',
      pl: 'Ghana',
      'pt-BR': 'Ghana',
      sv: 'Ghana',
      zh: '加纳',
      ko: 'Ghana',
      ja: 'Ghana'
    },
    permalinks: {
      en: 'ghana',
      fr: 'ghana',
      de: 'ghana',
      es: 'ghana',
      pt: 'gana',
      pl: 'ghana',
      'pt-BR': 'ghana',
      sv: 'ghana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GIB: {
    iso2: 'gi',
    old_id: 198,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Gibraltar',
      fr: 'Gibraltar',
      de: 'Gibraltar',
      es: 'Gibraltar',
      pt: 'Gibraltar',
      pl: 'Gibraltar',
      'pt-BR': 'Gibraltar',
      sv: 'Gibraltar',
      zh: '直布罗陀',
      ko: 'Gibraltar',
      ja: 'Gibraltar'
    },
    permalinks: {
      en: 'gibraltar',
      fr: 'gibraltar',
      de: 'gibraltar',
      es: 'gibraltar',
      pt: 'gibraltar',
      pl: 'gibraltar',
      'pt-BR': 'gibraltar',
      sv: 'gibraltar'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  GIN: {
    iso2: 'gn',
    old_id: 157,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Guinea',
      fr: 'Guinée',
      de: 'Guinea',
      es: 'Guinea',
      pt: 'Guiné',
      pl: 'Guinea',
      'pt-BR': 'Guinea',
      sv: 'Guinea',
      zh: '几内亚',
      ko: 'Guinea',
      ja: 'Guinea'
    },
    permalinks: {
      en: 'guinea',
      fr: 'guinee',
      de: 'guinea',
      es: 'guinea',
      pt: 'guine',
      pl: 'guinea',
      'pt-BR': 'guinea',
      sv: 'guinea'
    },
    hasRegion: false,
    ins: {}
  },
  GLP: {
    iso2: 'gp',
    old_id: 122,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Guadeloupe',
      fr: 'Guadeloupe',
      de: 'Guadeloupe',
      es: 'Guadalupe',
      pt: 'Guadalupe',
      pl: 'Guadeloupe',
      'pt-BR': 'Guadeloupe',
      sv: 'Guadeloupe',
      zh: '瓜德罗普',
      ko: 'Guadeloupe',
      ja: 'Guadeloupe'
    },
    permalinks: {
      en: 'guadeloupe',
      fr: 'guadeloupe',
      de: 'guadeloupe',
      es: 'guadalupe',
      pt: 'guadalupe',
      pl: 'guadeloupe',
      'pt-BR': 'guadeloupe',
      sv: 'guadeloupe'
    },
    hasRegion: false,
    ins: {}
  },
  GMB: {
    iso2: 'gm',
    old_id: 197,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Gambia',
      fr: 'Gambie',
      de: 'Gambia',
      es: 'Gambia',
      pt: 'Gâmbia',
      pl: 'Gambia',
      'pt-BR': 'Gambia',
      sv: 'Gambia',
      zh: '冈比亚',
      ko: 'Gambia',
      ja: 'Gambia'
    },
    permalinks: {
      en: 'gambia',
      fr: 'gambie',
      de: 'gambia',
      es: 'gambia',
      pt: 'gambia',
      pl: 'gambia',
      'pt-BR': 'gambia',
      sv: 'gambia'
    },
    hasRegion: false,
    ins: {}
  },
  GNB: {
    iso2: 'gw',
    old_id: 158,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      de: 'Guinea-Bissau',
      es: 'Guinea-Bissau',
      pt: 'Guiné-Bissáu',
      pl: 'Guinea-Bissau',
      'pt-BR': 'Guinea-Bissau',
      sv: 'Guinea-Bissau',
      zh: '几内亚比绍',
      ko: 'Guinea-Bissau',
      ja: 'Guinea-Bissau'
    },
    permalinks: {
      en: 'guinea-bissau',
      fr: 'guinee-bissau',
      de: 'guinea-bissau',
      es: 'guinea-bissau',
      pt: 'guine-bissau',
      pl: 'guinea-bissau',
      'pt-BR': 'guinea-bissau',
      sv: 'guinea-bissau'
    },
    hasRegion: false,
    ins: {}
  },
  GNQ: {
    iso2: 'gq',
    old_id: 159,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Equatorial Guinea',
      fr: 'Guinée équatoriale',
      de: 'Äquatorialguinea',
      es: 'Guinea Ecuatorial',
      pt: 'Guiné Equatorial',
      pl: 'Equatorial Guinea',
      'pt-BR': 'Equatorial Guinea',
      sv: 'Ekvatorialguinea',
      zh: '赤道几内亚',
      ko: 'Equatorial Guinea',
      ja: 'Equatorial Guinea'
    },
    permalinks: {
      en: 'equatorial-guinea',
      fr: 'guinee-equatoriale',
      de: 'aquatorialguinea',
      es: 'guinea-ecuatorial',
      pt: 'guine-equatorial',
      pl: 'equatorial-guinea',
      'pt-BR': 'equatorial-guinea',
      sv: 'ekvatorialguinea'
    },
    hasRegion: false,
    ins: {}
  },
  GRC: {
    iso2: 'gr',
    old_id: 32,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Greece',
      fr: 'Grèce',
      de: 'Griechenland',
      es: 'Grecia',
      pt: 'Grécia',
      pl: 'Greece',
      'pt-BR': 'Greece',
      sv: 'Grekland',
      zh: '希腊',
      ko: 'Greece',
      ja: 'Greece'
    },
    permalinks: {
      en: 'greece',
      fr: 'grece',
      de: 'griechenland',
      es: 'grecia',
      pt: 'grecia',
      pl: 'greece',
      'pt-BR': 'greece',
      sv: 'grekland'
    },
    hasRegion: false,
    ins: {}
  },
  GRD: {
    iso2: 'gd',
    old_id: 199,
    subContinent: 29,
    continent: 2,
    names: {
      en: 'Grenada',
      fr: 'Grenade',
      de: 'Grenada',
      es: 'Granada',
      pt: 'Granada',
      pl: 'Grenada',
      'pt-BR': 'Grenada',
      sv: 'Grenada',
      zh: '格林纳达',
      ko: 'Grenada',
      ja: 'Grenada'
    },
    permalinks: {
      en: 'grenada',
      fr: 'grenade',
      de: 'grenada',
      es: 'granada',
      pt: 'granada',
      pl: 'grenada',
      'pt-BR': 'grenada',
      sv: 'grenada'
    },
    hasRegion: false,
    ins: { fr: 'à la' }
  },
  GRL: {
    iso2: 'gl',
    old_id: 33,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Greenland',
      fr: 'Groenland',
      de: 'Grönland',
      es: 'Groenlandia',
      pt: 'Gronelândia',
      pl: 'Greenland',
      'pt-BR': 'Greenland',
      sv: 'Grönland',
      zh: '格陵兰',
      ko: 'Greenland',
      ja: 'Greenland'
    },
    permalinks: {
      en: 'greenland',
      fr: 'groenland',
      de: 'gronland',
      es: 'groenlandia',
      pt: 'gronelandia',
      pl: 'greenland',
      'pt-BR': 'greenland',
      sv: 'gronland'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GTM: {
    iso2: 'gt',
    old_id: 99,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Guatemala',
      fr: 'Guatemala',
      de: 'Guatemala',
      es: 'Guatemala',
      pt: 'Guatemala',
      pl: 'Guatemala',
      'pt-BR': 'Guatemala',
      sv: 'Guatemala',
      zh: '瓜地马拉',
      ko: 'Guatemala',
      ja: 'Guatemala'
    },
    permalinks: {
      en: 'guatemala',
      fr: 'guatemala',
      de: 'guatemala',
      es: 'guatemala',
      pt: 'guatemala',
      pl: 'guatemala',
      'pt-BR': 'guatemala',
      sv: 'guatemala'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  GUF: {
    iso2: 'gf',
    old_id: 107,
    subContinent: null,
    continent: 2,
    names: {
      en: 'French Guiana',
      fr: 'Guyane',
      de: 'Französisch-Guyana',
      es: 'Guayana Francesa',
      pt: 'Guiana Francesa',
      pl: 'French Guiana',
      'pt-BR': 'French Guiana',
      sv: 'Franska Guiana',
      zh: '法属圭亚那',
      ko: 'French Guiana',
      ja: 'French Guiana'
    },
    permalinks: {
      en: 'french-guiana',
      fr: 'guyane',
      de: 'franzosisch-guyana',
      es: 'guayana-francesa',
      pt: 'guiana-francesa',
      pl: 'french-guiana',
      'pt-BR': 'french-guiana',
      sv: 'franska-guiana'
    },
    hasRegion: false,
    ins: {}
  },
  GUM: {
    iso2: 'gu',
    old_id: 34,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Guam',
      fr: 'Guam',
      de: 'Guam',
      es: 'Guam',
      pt: 'Guam',
      pl: 'Guam',
      'pt-BR': 'Guam',
      sv: 'Guam',
      zh: '关岛',
      ko: 'Guam',
      ja: 'Guam'
    },
    permalinks: { en: 'guam', fr: 'guam', de: 'guam', es: 'guam', pt: 'guam', pl: 'guam', 'pt-BR': 'guam', sv: 'guam' },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  GUY: {
    iso2: 'gy',
    old_id: 200,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Guyana',
      fr: 'Guyana',
      de: 'Guyana',
      es: 'Guyana',
      pt: 'Guiana',
      pl: 'Guyana',
      'pt-BR': 'Guyana',
      sv: 'Guyana',
      zh: '圭亚那',
      ko: 'Guyana',
      ja: 'Guyana'
    },
    permalinks: {
      en: 'guyana',
      fr: 'guyana',
      de: 'guyana',
      es: 'guyana',
      pt: 'guiana',
      pl: 'guyana',
      'pt-BR': 'guyana',
      sv: 'guyana'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  HKG: {
    iso2: 'hk',
    old_id: 35,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Hong-Kong',
      fr: 'Honk-Kong',
      de: 'Hongkong',
      es: 'Hong Kong',
      pt: 'Hong Kong',
      pl: 'Hong-Kong',
      'pt-BR': 'Hong-Kong',
      sv: 'Hong Kong',
      zh: '香港',
      ko: 'Hong-Kong',
      ja: 'Hong-Kong'
    },
    permalinks: {
      en: 'hong-kong',
      fr: 'honk-kong',
      de: 'hongkong',
      es: 'hong-kong',
      pt: 'hong-kong',
      pl: 'hong-kong',
      'pt-BR': 'hong-kong',
      sv: 'hong-kong'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  HMD: {
    iso2: 'hm',
    old_id: 457,
    subContinent: 53,
    continent: 5,
    names: {
      en: 'Heard Island and McDonald Islands',
      fr: 'Heard, Île et McDonald, Îles',
      de: 'Heard und McDonaldinseln',
      es: 'Islas Heard e Islas McDonald',
      pt: 'Ilha Heard e Ilhas McDonald',
      pl: 'Heard Island and McDonald Islands',
      'pt-BR': 'Heard Island and McDonald Islands',
      sv: 'Heardön och McDonaldöarna',
      zh: '赫德岛与麦克唐纳群岛',
      ko: 'Heard Island and McDonald Islands',
      ja: 'Heard Island and McDonald Islands'
    },
    permalinks: {
      en: 'heard-island-and-mcdonald-islands',
      fr: 'heard-ile-et-mcdonald-iles',
      de: 'heard-und-mcdonaldinseln',
      es: 'islas-heard-e-islas-mcdonald',
      pt: 'ilha-heard-e-ilhas-mcdonald',
      pl: 'heard-island-and-mcdonald-islands',
      'pt-BR': 'heard-island-and-mcdonald-islands',
      sv: 'heardon-och-mcdonaldoarna'
    },
    hasRegion: false,
    ins: { fr: 'dans les Îles Heard et McDonald' }
  },
  HND: {
    iso2: 'hn',
    old_id: 132,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Honduras',
      fr: 'Honduras',
      de: 'Honduras',
      es: 'Honduras',
      pt: 'Honduras',
      pl: 'Honduras',
      'pt-BR': 'Honduras',
      sv: 'Honduras',
      zh: '洪都拉斯',
      ko: 'Honduras',
      ja: 'Honduras'
    },
    permalinks: {
      en: 'honduras',
      fr: 'honduras',
      de: 'honduras',
      es: 'honduras',
      pt: 'honduras',
      pl: 'honduras',
      'pt-BR': 'honduras',
      sv: 'honduras'
    },
    hasRegion: false,
    ins: { fr: 'au ' }
  },
  HRV: {
    iso2: 'hr',
    old_id: 18,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Croatia',
      fr: 'Croatie',
      de: 'Kroatien',
      es: 'Croacia',
      pt: 'Croácia',
      pl: 'Croatia',
      'pt-BR': 'Croatia',
      sv: 'Kroatien',
      zh: '克罗地亚',
      ko: 'Croatia',
      ja: 'Croatia'
    },
    permalinks: {
      en: 'croatia',
      fr: 'croatie',
      de: 'kroatien',
      es: 'croacia',
      pt: 'croacia',
      pl: 'croatia',
      'pt-BR': 'croatia',
      sv: 'kroatien'
    },
    hasRegion: false,
    ins: {}
  },
  HTI: {
    iso2: 'ht',
    old_id: 201,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Haiti',
      fr: 'Haïti',
      de: 'Haiti',
      es: 'Haití',
      pt: 'Haiti',
      pl: 'Haiti',
      'pt-BR': 'Haiti',
      sv: 'Haiti',
      zh: '海地',
      ko: 'Haiti',
      ja: 'Haiti'
    },
    permalinks: {
      en: 'haiti',
      fr: 'haiti',
      de: 'haiti',
      es: 'haiti',
      pt: 'haiti',
      pl: 'haiti',
      'pt-BR': 'haiti',
      sv: 'haiti'
    },
    hasRegion: false,
    ins: {}
  },
  HUN: {
    iso2: 'hu',
    old_id: 36,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Hungary',
      fr: 'Hongrie',
      de: 'Ungarn',
      es: 'Hungría',
      pt: 'Hungria',
      pl: 'Hungary',
      'pt-BR': 'Hungary',
      sv: 'Ungern',
      zh: '匈牙利',
      ko: 'Hungary',
      ja: 'Hungary'
    },
    permalinks: {
      en: 'hungary',
      fr: 'hongrie',
      de: 'ungarn',
      es: 'hungria',
      pt: 'hungria',
      pl: 'hungary',
      'pt-BR': 'hungary',
      sv: 'ungern'
    },
    hasRegion: false,
    ins: {}
  },
  IDN: {
    iso2: 'id',
    old_id: 39,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Indonesia',
      fr: 'Indonésie',
      de: 'Indonesien',
      es: 'Indonesia',
      pt: 'Indonésia',
      pl: 'Indonesia',
      'pt-BR': 'Indonesia',
      sv: 'Indonesien',
      zh: '印度尼西亚',
      ko: 'Indonesia',
      ja: 'Indonesia'
    },
    permalinks: {
      en: 'indonesia',
      fr: 'indonesie',
      de: 'indonesien',
      es: 'indonesia',
      pt: 'indonesia',
      pl: 'indonesia',
      'pt-BR': 'indonesia',
      sv: 'indonesien'
    },
    hasRegion: false,
    ins: {}
  },
  IMN: {
    iso2: 'im',
    old_id: 116,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Isle of Man',
      fr: 'Île de Man',
      de: 'Insel Man',
      es: 'Isla de Man',
      pt: 'Ilha de Man',
      pl: 'Isle of Man',
      'pt-BR': 'Isle of Man',
      sv: 'Isle of Man',
      zh: '曼岛',
      ko: 'Isle of Man',
      ja: 'Isle of Man'
    },
    permalinks: {
      en: 'isle-of-man',
      fr: 'ile-de-man',
      de: 'insel-man',
      es: 'isla-de-man',
      pt: 'ilha-de-man',
      pl: 'isle-of-man',
      'pt-BR': 'isle-of-man',
      sv: 'isle-of-man'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'île de Man" }
  },
  IND: {
    iso2: 'in',
    old_id: 38,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'India',
      fr: 'Inde',
      de: 'Indien',
      es: 'India',
      pt: 'Índia',
      pl: 'India',
      'pt-BR': 'India',
      sv: 'Indien',
      zh: '印度',
      ko: 'India',
      ja: 'India'
    },
    permalinks: {
      en: 'india',
      fr: 'inde',
      de: 'indien',
      es: 'india',
      pt: 'india',
      pl: 'india',
      'pt-BR': 'india',
      sv: 'indien'
    },
    hasRegion: true,
    ins: {}
  },
  IOT: {
    iso2: 'io',
    old_id: 11,
    subContinent: 14,
    continent: 6,
    names: {
      en: 'British Indian Ocean Territory',
      fr: "Territoire britanique de l'océan indien",
      de: 'Britisches Territorium im Indischen Ozean',
      es: 'Británico del Océano Índico, Territorio',
      pt: 'Território Britânico do Oceano Índico',
      pl: 'British Indian Ocean Territory',
      'pt-BR': 'British Indian Ocean Territory',
      sv: 'Brittiskt territorium i Indiska Oceanen',
      zh: '英属印度洋领地',
      ko: 'British Indian Ocean Territory',
      ja: 'British Indian Ocean Territory'
    },
    permalinks: {
      en: 'british-indian-ocean-territory',
      fr: 'territoire-britanique-de-l-ocean-indien',
      de: 'britisches-territorium-im-indischen-ozean',
      es: 'britanico-del-oceano-indico-territorio',
      pt: 'territorio-britanico-do-oceano-indico',
      pl: 'british-indian-ocean-territory',
      'pt-BR': 'british-indian-ocean-territory',
      sv: 'brittiskt-territorium-i-indiska-oceanen'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'dans le' }
  },
  IRL: {
    iso2: 'ie',
    old_id: 40,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Ireland',
      fr: 'Irlande',
      de: 'Irland',
      es: 'Irlanda',
      pt: 'Irlanda',
      pl: 'Ireland',
      'pt-BR': 'Ireland',
      sv: 'Irland',
      zh: '爱尔兰',
      ko: 'Ireland',
      ja: 'Ireland'
    },
    permalinks: {
      en: 'ireland',
      fr: 'irlande',
      de: 'irland',
      es: 'irlanda',
      pt: 'irlanda',
      pl: 'ireland',
      'pt-BR': 'ireland',
      sv: 'irland'
    },
    hasRegion: true,
    ins: {}
  },
  IRN: {
    iso2: 'ir',
    old_id: 203,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Iran',
      fr: 'Iran',
      de: 'Iran, Islamische Republik',
      es: 'Irán, República islámica de',
      pt: 'Irão, República Islâmica do',
      pl: 'Iran',
      'pt-BR': 'Iran',
      sv: 'Iran, islamiska republiken',
      zh: '伊朗',
      ko: 'Iran',
      ja: 'Iran'
    },
    permalinks: {
      en: 'iran',
      fr: 'iran',
      de: 'iran-islamische-republik',
      es: 'iran-republica-islamica-de',
      pt: 'irao-republica-islamica-do',
      pl: 'iran',
      'pt-BR': 'iran',
      sv: 'iran-islamiska-republiken'
    },
    hasRegion: false,
    ins: {}
  },
  IRQ: {
    iso2: 'iq',
    old_id: 165,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Iraq',
      fr: 'Irak',
      de: 'Irak',
      es: 'Irak',
      pt: 'Iraque',
      pl: 'Iraq',
      'pt-BR': 'Iraq',
      sv: 'Irak',
      zh: '伊拉克',
      ko: 'Iraq',
      ja: 'Iraq'
    },
    permalinks: {
      en: 'iraq',
      fr: 'irak',
      de: 'irak',
      es: 'irak',
      pt: 'iraque',
      pl: 'iraq',
      'pt-BR': 'iraq',
      sv: 'irak'
    },
    hasRegion: false,
    ins: {}
  },
  ISL: {
    iso2: 'is',
    old_id: 37,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Iceland',
      fr: 'Islande',
      de: 'Island',
      es: 'Islandia',
      pt: 'Islândia',
      pl: 'Iceland',
      'pt-BR': 'Iceland',
      sv: 'Island',
      zh: '冰岛',
      ko: 'Iceland',
      ja: 'Iceland'
    },
    permalinks: {
      en: 'iceland',
      fr: 'islande',
      de: 'island',
      es: 'islandia',
      pt: 'islandia',
      pl: 'iceland',
      'pt-BR': 'iceland',
      sv: 'island'
    },
    hasRegion: false,
    ins: {}
  },
  ISR: {
    iso2: 'il',
    old_id: 41,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Israel',
      fr: 'Israël',
      de: 'Israel',
      es: 'Israel',
      pt: 'Israel',
      pl: 'Israel',
      'pt-BR': 'Israel',
      sv: 'Israel',
      zh: '以色列',
      ko: 'Israel',
      ja: 'Israel'
    },
    permalinks: {
      en: 'israel',
      fr: 'israel',
      de: 'israel',
      es: 'israel',
      pt: 'israel',
      pl: 'israel',
      'pt-BR': 'israel',
      sv: 'israel'
    },
    hasRegion: false,
    ins: {}
  },
  ITA: {
    iso2: 'it',
    old_id: 42,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Italy',
      fr: 'Italie',
      de: 'Italien',
      es: 'Italia',
      pt: 'Itália',
      pl: 'Italy',
      'pt-BR': 'Italy',
      sv: 'Italien',
      zh: '意大利',
      ko: 'Italy',
      ja: 'Italy'
    },
    permalinks: {
      en: 'italy',
      fr: 'italie',
      de: 'italien',
      es: 'italia',
      pt: 'italia',
      pl: 'italy',
      'pt-BR': 'italy',
      sv: 'italien'
    },
    hasRegion: true,
    ins: {}
  },
  JAM: {
    iso2: 'jm',
    old_id: 43,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Jamaica',
      fr: 'Jamaique',
      de: 'Jamaika',
      es: 'Jamaica',
      pt: 'Jamaica',
      pl: 'Jamaica',
      'pt-BR': 'Jamaica',
      sv: 'Jamaica',
      zh: '牙买加',
      ko: 'Jamaica',
      ja: 'Jamaica'
    },
    permalinks: {
      en: 'jamaica',
      fr: 'jamaique',
      de: 'jamaika',
      es: 'jamaica',
      pt: 'jamaica',
      pl: 'jamaica',
      'pt-BR': 'jamaica',
      sv: 'jamaica'
    },
    hasRegion: false,
    ins: {}
  },
  JEY: {
    iso2: 'je',
    old_id: 258,
    subContinent: 830,
    continent: 1,
    names: {
      en: 'Jersey',
      fr: 'Jersey',
      de: 'Jersey',
      es: 'Jersey',
      pt: 'Jersey',
      pl: 'Jersey',
      'pt-BR': 'Jersey',
      sv: 'Jersey',
      zh: '泽西岛',
      ko: 'Jersey',
      ja: 'Jersey'
    },
    permalinks: {
      en: 'jersey',
      fr: 'jersey',
      de: 'jersey',
      es: 'jersey',
      pt: 'jersey',
      pl: 'jersey',
      'pt-BR': 'jersey',
      sv: 'jersey'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  JOR: {
    iso2: 'jo',
    old_id: 45,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Jordan',
      fr: 'Jordanie',
      de: 'Jordanien',
      es: 'Jordania',
      pt: 'Jordânia',
      pl: 'Jordan',
      'pt-BR': 'Jordan',
      sv: 'Jordanien',
      zh: '约旦',
      ko: 'Jordan',
      ja: 'Jordan'
    },
    permalinks: {
      en: 'jordan',
      fr: 'jordanie',
      de: 'jordanien',
      es: 'jordania',
      pt: 'jordania',
      pl: 'jordan',
      'pt-BR': 'jordan',
      sv: 'jordanien'
    },
    hasRegion: false,
    ins: {}
  },
  JPN: {
    iso2: 'jp',
    old_id: 44,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Japan',
      fr: 'Japon',
      de: 'Japan',
      es: 'Japón',
      pt: 'Japão',
      pl: 'Japan',
      'pt-BR': 'Japan',
      sv: 'Japan',
      zh: '日本',
      ko: 'Japan',
      ja: 'Japan'
    },
    permalinks: {
      en: 'japan',
      fr: 'japon',
      de: 'japan',
      es: 'japon',
      pt: 'japao',
      pl: 'japan',
      'pt-BR': 'japan',
      sv: 'japan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KAZ: {
    iso2: 'kz',
    old_id: 172,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Kazakhstan',
      fr: 'Kazakhstan',
      de: 'Kasachstan',
      es: 'Kazajistán',
      pt: 'Cazaquistão',
      pl: 'Kazakhstan',
      'pt-BR': 'Kazakhstan',
      sv: 'Kazakstan',
      zh: '哈萨克斯坦',
      ko: 'Kazakhstan',
      ja: 'Kazakhstan'
    },
    permalinks: {
      en: 'kazakhstan',
      fr: 'kazakhstan',
      de: 'kasachstan',
      es: 'kazajistan',
      pt: 'cazaquistao',
      pl: 'kazakhstan',
      'pt-BR': 'kazakhstan',
      sv: 'kazakstan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KEN: {
    iso2: 'ke',
    old_id: 46,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Kenya',
      fr: 'Kenya',
      de: 'Kenia',
      es: 'Kenia',
      pt: 'Quénia',
      pl: 'Kenya',
      'pt-BR': 'Kenya',
      sv: 'Kenya',
      zh: '肯尼亚',
      ko: 'Kenya',
      ja: 'Kenya'
    },
    permalinks: {
      en: 'kenya',
      fr: 'kenya',
      de: 'kenia',
      es: 'kenia',
      pt: 'quenia',
      pl: 'kenya',
      'pt-BR': 'kenya',
      sv: 'kenya'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KGZ: {
    iso2: 'kg',
    old_id: 207,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Kyrgyzstan',
      fr: 'Kyrgyzstan',
      de: 'Kirgisistan',
      es: 'Kirgizstán',
      pt: 'Quirgistão',
      pl: 'Kyrgyzstan',
      'pt-BR': 'Kyrgyzstan',
      sv: 'Kirgisistan',
      zh: '吉尔吉斯坦',
      ko: 'Kyrgyzstan',
      ja: 'Kyrgyzstan'
    },
    permalinks: {
      en: 'kyrgyzstan',
      fr: 'kyrgyzstan',
      de: 'kirgisistan',
      es: 'kirgizstan',
      pt: 'quirgistao',
      pl: 'kyrgyzstan',
      'pt-BR': 'kyrgyzstan',
      sv: 'kirgisistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  KHM: {
    iso2: 'kh',
    old_id: 12,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Cambodia',
      fr: 'Cambodge',
      de: 'Kambodscha',
      es: 'Camboya',
      pt: 'Cambodja',
      pl: 'Cambodia',
      'pt-BR': 'Cambodia',
      sv: 'Kambodja',
      zh: '柬埔塞',
      ko: 'Cambodia',
      ja: 'Cambodia'
    },
    permalinks: {
      en: 'cambodia',
      fr: 'cambodge',
      de: 'kambodscha',
      es: 'camboya',
      pt: 'cambodja',
      pl: 'cambodia',
      'pt-BR': 'cambodia',
      sv: 'kambodja'
    },
    hasRegion: false,
    ins: { fr: 'au ' }
  },
  KIR: {
    iso2: 'ki',
    old_id: 204,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Kiribati',
      fr: 'Kiribati',
      de: 'Kiribati',
      es: 'Kiribati',
      pt: 'Kiribati',
      pl: 'Kiribati',
      'pt-BR': 'Kiribati',
      sv: 'Kiribati',
      zh: '基里巴斯',
      ko: 'Kiribati',
      ja: 'Kiribati'
    },
    permalinks: {
      en: 'kiribati',
      fr: 'kiribati',
      de: 'kiribati',
      es: 'kiribati',
      pt: 'kiribati',
      pl: 'kiribati',
      'pt-BR': 'kiribati',
      sv: 'kiribati'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  KNA: {
    iso2: 'kn',
    old_id: 256,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Kitts and Nevis',
      fr: 'Saint Kitts et Nevis',
      de: 'St. Kitts und Nevis',
      es: 'San Cristobo y Nevis',
      pt: 'Saint Kitts e Nevis',
      pl: 'Saint Kitts and Nevis',
      'pt-BR': 'Saint Kitts and Nevis',
      sv: 'Sankt Kitts och Nevis',
      zh: '圣基茨和尼维斯',
      ko: 'Saint Kitts and Nevis',
      ja: 'Saint Kitts and Nevis'
    },
    permalinks: {
      en: 'saint-kitts-and-nevis',
      fr: 'saint-kitts-et-nevis',
      de: 'st-kitts-und-nevis',
      es: 'san-cristobo-y-nevis',
      pt: 'saint-kitts-e-nevis',
      pl: 'saint-kitts-and-nevis',
      'pt-BR': 'saint-kitts-and-nevis',
      sv: 'sankt-kitts-och-nevis'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  KOR: {
    iso2: 'kr',
    old_id: 47,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'South Korea',
      fr: 'Corée du Sud',
      de: 'Korea, Republik',
      es: 'Corea, República de',
      pt: 'Coreia, República da',
      pl: 'South Korea',
      'pt-BR': 'South Korea',
      sv: 'Sydkorea',
      zh: '韩国',
      ko: 'South Korea',
      ja: 'South Korea'
    },
    permalinks: {
      en: 'south-korea',
      fr: 'coree-du-sud',
      de: 'korea-republik',
      es: 'corea-republica-de',
      pt: 'coreia-republica-da',
      pl: 'south-korea',
      'pt-BR': 'south-korea',
      sv: 'sydkorea'
    },
    hasRegion: false,
    ins: {}
  },
  KWT: {
    iso2: 'kw',
    old_id: 206,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Kuwait',
      fr: 'Koweit',
      de: 'Kuwait',
      es: 'Kuwait',
      pt: 'Kuwait',
      pl: 'Kuwait',
      'pt-BR': 'Kuwait',
      sv: 'Kuwait',
      zh: '科威特',
      ko: 'Kuwait',
      ja: 'Kuwait'
    },
    permalinks: {
      en: 'kuwait',
      fr: 'koweit',
      de: 'kuwait',
      es: 'kuwait',
      pt: 'kuwait',
      pl: 'kuwait',
      'pt-BR': 'kuwait',
      sv: 'kuwait'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LAO: {
    iso2: 'la',
    old_id: 208,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Laos',
      fr: 'Laos',
      de: 'Laos, Demokratische Volksrepublik',
      es: 'República Democrática Popular de Lao',
      pt: 'República Democrática Popular do Laos',
      pl: 'Laos',
      'pt-BR': 'Laos',
      sv: 'Demokratiska folkrepubliken Lao',
      zh: '老挝人民民主共和国',
      ko: 'Laos',
      ja: 'Laos'
    },
    permalinks: {
      en: 'laos',
      fr: 'laos',
      de: 'laos-demokratische-volksrepublik',
      es: 'republica-democratica-popular-de-lao',
      pt: 'republica-democratica-popular-do-laos',
      pl: 'laos',
      'pt-BR': 'laos',
      sv: 'demokratiska-folkrepubliken-lao'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LBN: {
    iso2: 'lb',
    old_id: 49,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Lebanon',
      fr: 'Liban',
      de: 'Libanon',
      es: 'Líbano',
      pt: 'Líbano',
      pl: 'Lebanon',
      'pt-BR': 'Lebanon',
      sv: 'Libanon',
      zh: '黎巴嫩',
      ko: 'Lebanon',
      ja: 'Lebanon'
    },
    permalinks: {
      en: 'lebanon',
      fr: 'liban',
      de: 'libanon',
      es: 'libano',
      pt: 'libano',
      pl: 'lebanon',
      'pt-BR': 'lebanon',
      sv: 'libanon'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LBR: {
    iso2: 'lr',
    old_id: 210,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Liberia',
      fr: 'Liberia',
      de: 'Liberia',
      es: 'Liberia',
      pt: 'Libéria',
      pl: 'Liberia',
      'pt-BR': 'Liberia',
      sv: 'Liberia',
      zh: '利比里亚',
      ko: 'Liberia',
      ja: 'Liberia'
    },
    permalinks: {
      en: 'liberia',
      fr: 'liberia',
      de: 'liberia',
      es: 'liberia',
      pt: 'liberia',
      pl: 'liberia',
      'pt-BR': 'liberia',
      sv: 'liberia'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LBY: {
    iso2: 'ly',
    old_id: 211,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Lybia',
      fr: 'Lybie',
      de: 'Lybia',
      es: 'Lybia',
      pt: 'Lybia',
      pl: 'Lybia',
      'pt-BR': 'Lybia',
      sv: 'Lybia',
      zh: 'Lybia',
      ko: 'Lybia',
      ja: 'Lybia'
    },
    permalinks: {
      en: 'lybia',
      fr: 'lybie',
      de: 'lybia',
      es: 'lybia',
      pt: 'lybia',
      pl: 'lybia',
      'pt-BR': 'lybia',
      sv: 'lybia'
    },
    hasRegion: false,
    ins: {}
  },
  LCA: {
    iso2: 'lc',
    old_id: 255,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Lucia',
      fr: 'Saint Lucie',
      de: 'St. Lucia',
      es: 'Santa Lucía',
      pt: 'Santa Lúcia',
      pl: 'Saint Lucia',
      'pt-BR': 'Saint Lucia',
      sv: 'Sankt Lucia',
      zh: '圣路西亚',
      ko: 'Saint Lucia',
      ja: 'Saint Lucia'
    },
    permalinks: {
      en: 'saint-lucia',
      fr: 'saint-lucie',
      de: 'st-lucia',
      es: 'santa-lucia',
      pt: 'santa-lucia',
      pl: 'saint-lucia',
      'pt-BR': 'saint-lucia',
      sv: 'sankt-lucia'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  LIE: {
    iso2: 'li',
    old_id: 50,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Liechtenstein',
      fr: 'Liechtenstein',
      de: 'Liechtenstein',
      es: 'Liechtenstein',
      pt: 'Liechtenstein',
      pl: 'Liechtenstein',
      'pt-BR': 'Liechtenstein',
      sv: 'Liechtenstein',
      zh: '列支敦士登',
      ko: 'Liechtenstein',
      ja: 'Liechtenstein'
    },
    permalinks: {
      en: 'liechtenstein',
      fr: 'liechtenstein',
      de: 'liechtenstein',
      es: 'liechtenstein',
      pt: 'liechtenstein',
      pl: 'liechtenstein',
      'pt-BR': 'liechtenstein',
      sv: 'liechtenstein'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LKA: {
    iso2: 'lk',
    old_id: 124,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Sri Lanka',
      fr: 'Sri Lanka',
      de: 'Sri Lanka',
      es: 'Sri Lanka',
      pt: 'Sri Lanka',
      pl: 'Sri Lanka',
      'pt-BR': 'Sri Lanka',
      sv: 'Sri Lanka',
      zh: '斯里兰卡',
      ko: 'Sri Lanka',
      ja: 'Sri Lanka'
    },
    permalinks: {
      en: 'sri-lanka',
      fr: 'sri-lanka',
      de: 'sri-lanka',
      es: 'sri-lanka',
      pt: 'sri-lanka',
      pl: 'sri-lanka',
      'pt-BR': 'sri-lanka',
      sv: 'sri-lanka'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LSO: {
    iso2: 'ls',
    old_id: 209,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Lesotho',
      fr: 'Lesotho',
      de: 'Lesotho',
      es: 'Lesoto',
      pt: 'Lesoto',
      pl: 'Lesotho',
      'pt-BR': 'Lesotho',
      sv: 'Lesotho',
      zh: '莱索托',
      ko: 'Lesotho',
      ja: 'Lesotho'
    },
    permalinks: {
      en: 'lesotho',
      fr: 'lesotho',
      de: 'lesotho',
      es: 'lesoto',
      pt: 'lesoto',
      pl: 'lesotho',
      'pt-BR': 'lesotho',
      sv: 'lesotho'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LTU: {
    iso2: 'lt',
    old_id: 51,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Lithuania',
      fr: 'Lituanie',
      de: 'Litauen',
      es: 'Lituania',
      pt: 'Lituânia',
      pl: 'Lithuania',
      'pt-BR': 'Lithuania',
      sv: 'Litauen',
      zh: '立陶宛',
      ko: 'Lithuania',
      ja: 'Lithuania'
    },
    permalinks: {
      en: 'lithuania',
      fr: 'lituanie',
      de: 'litauen',
      es: 'lituania',
      pt: 'lituania',
      pl: 'lithuania',
      'pt-BR': 'lithuania',
      sv: 'litauen'
    },
    hasRegion: false,
    ins: {}
  },
  LUX: {
    iso2: 'lu',
    old_id: 52,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Luxembourg',
      fr: 'Luxembourg',
      de: 'Luxemburg',
      es: 'Luxemburgo',
      pt: 'Luxemburgo',
      pl: 'Luxembourg',
      'pt-BR': 'Luxembourg',
      sv: 'Luxemburg',
      zh: '卢森堡',
      ko: 'Luxembourg',
      ja: 'Luxembourg'
    },
    permalinks: {
      en: 'luxembourg',
      fr: 'luxembourg',
      de: 'luxemburg',
      es: 'luxemburgo',
      pt: 'luxemburgo',
      pl: 'luxembourg',
      'pt-BR': 'luxembourg',
      sv: 'luxemburg'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  LVA: {
    iso2: 'lv',
    old_id: 48,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Latvia',
      fr: 'Lettonie',
      de: 'Lettland',
      es: 'Letonia',
      pt: 'Letónia',
      pl: 'Latvia',
      'pt-BR': 'Latvia',
      sv: 'Lettland',
      zh: '拉脱维亚',
      ko: 'Latvia',
      ja: 'Latvia'
    },
    permalinks: {
      en: 'latvia',
      fr: 'lettonie',
      de: 'lettland',
      es: 'letonia',
      pt: 'letonia',
      pl: 'latvia',
      'pt-BR': 'latvia',
      sv: 'lettland'
    },
    hasRegion: false,
    ins: {}
  },
  LYB: {
    iso2: 'ly',
    old_id: 113,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Libya',
      fr: 'Libye',
      de: 'Libyen',
      es: 'Libia',
      pt: 'Líbia',
      pl: 'Libya',
      'pt-BR': 'Libya',
      sv: 'Libyen',
      zh: '利比亚',
      ko: 'Libya',
      ja: 'Libya'
    },
    permalinks: {
      en: 'libya',
      fr: 'libye',
      de: 'libyen',
      es: 'libia',
      pt: 'libia',
      pl: 'libya',
      'pt-BR': 'libya',
      sv: 'libyen'
    },
    hasRegion: false,
    ins: {}
  },
  MAC: {
    iso2: 'mo',
    old_id: 53,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Macau',
      fr: 'Macao',
      de: 'Macao',
      es: 'Macao',
      pt: 'Macau',
      pl: 'Macau',
      'pt-BR': 'Macau',
      sv: 'Macao',
      zh: '澳门',
      ko: 'Macau',
      ja: 'Macau'
    },
    permalinks: {
      en: 'macau',
      fr: 'macao',
      de: 'macao',
      es: 'macao',
      pt: 'macau',
      pl: 'macau',
      'pt-BR': 'macau',
      sv: 'macao'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MAF: {
    iso2: 'mf',
    old_id: 254,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Martin',
      fr: 'Saint Martin',
      de: 'Saint Martin (Französischer Teil)',
      es: 'San Martín (zona francesa)',
      pt: 'São Martin (Território Francês)',
      pl: 'Saint Martin',
      'pt-BR': 'Saint Martin',
      sv: 'Saint Martin (franska delen)',
      zh: '法属圣马丁',
      ko: 'Saint Martin',
      ja: 'Saint Martin'
    },
    permalinks: {
      en: 'saint-martin',
      fr: 'saint-martin',
      de: 'saint-martin-franzosischer-teil',
      es: 'san-martin-zona-francesa',
      pt: 'sao-martin-territorio-frances',
      pl: 'saint-martin',
      'pt-BR': 'saint-martin',
      sv: 'saint-martin-franska-delen'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MAR: {
    iso2: 'ma',
    old_id: 60,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Morocco',
      fr: 'Maroc',
      de: 'Marokko',
      es: 'Marruecos',
      pt: 'Marrocos',
      pl: 'Morocco',
      'pt-BR': 'Morocco',
      sv: 'Marocko',
      zh: '摩洛哥',
      ko: 'Morocco',
      ja: 'Morocco'
    },
    permalinks: {
      en: 'morocco',
      fr: 'maroc',
      de: 'marokko',
      es: 'marruecos',
      pt: 'marrocos',
      pl: 'morocco',
      'pt-BR': 'morocco',
      sv: 'marocko'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MCO: {
    iso2: 'mc',
    old_id: 58,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Monaco',
      fr: 'Monaco',
      de: 'Monaco',
      es: 'Mónaco',
      pt: 'Mónaco',
      pl: 'Monaco',
      'pt-BR': 'Monaco',
      sv: 'Monaco',
      zh: '摩纳哥',
      ko: 'Monaco',
      ja: 'Monaco'
    },
    permalinks: {
      en: 'monaco',
      fr: 'monaco',
      de: 'monaco',
      es: 'monaco',
      pt: 'monaco',
      pl: 'monaco',
      'pt-BR': 'monaco',
      sv: 'monaco'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MDA: {
    iso2: 'md',
    old_id: 148,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Moldova',
      fr: 'Moldavie',
      de: 'Moldau, Republik',
      es: 'Moldavia, República de',
      pt: 'Moldávia, República da',
      pl: 'Moldova',
      'pt-BR': 'Moldova',
      sv: 'Moldavien, republiken',
      zh: '摩尔多瓦共和国',
      ko: 'Moldova',
      ja: 'Moldova'
    },
    permalinks: {
      en: 'moldova',
      fr: 'moldavie',
      de: 'moldau-republik',
      es: 'moldavia-republica-de',
      pt: 'moldavia-republica-da',
      pl: 'moldova',
      'pt-BR': 'moldova',
      sv: 'moldavien-republiken'
    },
    hasRegion: false,
    ins: {}
  },
  MDG: {
    iso2: 'mg',
    old_id: 125,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Madagascar',
      fr: 'Madagascar',
      de: 'Madagaskar',
      es: 'Madagascar',
      pt: 'Madagáscar',
      pl: 'Madagascar',
      'pt-BR': 'Madagascar',
      sv: 'Madagaskar',
      zh: '马达加斯加',
      ko: 'Madagascar',
      ja: 'Madagascar'
    },
    permalinks: {
      en: 'madagascar',
      fr: 'madagascar',
      de: 'madagaskar',
      es: 'madagascar',
      pt: 'madagascar',
      pl: 'madagascar',
      'pt-BR': 'madagascar',
      sv: 'madagaskar'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MDV: {
    iso2: 'mv',
    old_id: 213,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Maldives',
      fr: 'Maldives',
      de: 'Malediven',
      es: 'Islas Maldivas',
      pt: 'Maldivas',
      pl: 'Maldives',
      'pt-BR': 'Maldives',
      sv: 'Maldiverna',
      zh: '马尔代夫',
      ko: 'Maldives',
      ja: 'Maldives'
    },
    permalinks: {
      en: 'maldives',
      fr: 'maldives',
      de: 'malediven',
      es: 'islas-maldivas',
      pt: 'maldivas',
      pl: 'maldives',
      'pt-BR': 'maldives',
      sv: 'maldiverna'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  MEX: {
    iso2: 'mx',
    old_id: 57,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Mexico',
      fr: 'Méxique',
      de: 'Mexiko',
      es: 'México',
      pt: 'México',
      pl: 'Mexico',
      'pt-BR': 'Mexico',
      sv: 'Mexico',
      zh: '墨西哥',
      ko: 'Mexico',
      ja: 'Mexico'
    },
    permalinks: {
      en: 'mexico',
      fr: 'mexique',
      de: 'mexiko',
      es: 'mexico',
      pt: 'mexico',
      pl: 'mexico',
      'pt-BR': 'mexico',
      sv: 'mexico'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MHL: {
    iso2: 'mh',
    old_id: 214,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Marshall Islands',
      fr: 'Îles Marshall',
      de: 'Marshallinseln',
      es: 'Islas Marshall',
      pt: 'Ilhas Marshall',
      pl: 'Marshall Islands',
      'pt-BR': 'Marshall Islands',
      sv: 'Marshallöarna',
      zh: '马绍尔群岛',
      ko: 'Marshall Islands',
      ja: 'Marshall Islands'
    },
    permalinks: {
      en: 'marshall-islands',
      fr: 'iles-marshall',
      de: 'marshallinseln',
      es: 'islas-marshall',
      pt: 'ilhas-marshall',
      pl: 'marshall-islands',
      'pt-BR': 'marshall-islands',
      sv: 'marshalloarna'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  MKD: {
    iso2: 'mk',
    old_id: 54,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Macedonia',
      fr: 'Macédoine',
      de: 'Mazedonien, ehemalige jugoslawische Republik',
      es: 'Macedonia, República de',
      pt: 'Macedónia, República',
      pl: 'Macedonia',
      'pt-BR': 'Macedonia',
      sv: 'Makedonien, republiken',
      zh: '马其顿',
      ko: 'Macedonia',
      ja: 'Macedonia'
    },
    permalinks: {
      en: 'macedonia',
      fr: 'macedoine',
      de: 'mazedonien-ehemalige-jugoslawische-republik',
      es: 'macedonia-republica-de',
      pt: 'macedonia-republica',
      pl: 'macedonia',
      'pt-BR': 'macedonia',
      sv: 'makedonien-republiken'
    },
    hasRegion: false,
    ins: { fr: 'en' }
  },
  MLI: {
    iso2: 'ml',
    old_id: 110,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Mali',
      fr: 'Mali',
      de: 'Mali',
      es: 'Malí',
      pt: 'Mali',
      pl: 'Mali',
      'pt-BR': 'Mali',
      sv: 'Mali',
      zh: '马里',
      ko: 'Mali',
      ja: 'Mali'
    },
    permalinks: { en: 'mali', fr: 'mali', de: 'mali', es: 'mali', pt: 'mali', pl: 'mali', 'pt-BR': 'mali', sv: 'mali' },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MLT: {
    iso2: 'mt',
    old_id: 56,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Malta',
      fr: 'Malte',
      de: 'Malta',
      es: 'Malta',
      pt: 'Malta',
      pl: 'Malta',
      'pt-BR': 'Malta',
      sv: 'Malta',
      zh: '马尔他',
      ko: 'Malta',
      ja: 'Malta'
    },
    permalinks: {
      en: 'malta',
      fr: 'malte',
      de: 'malta',
      es: 'malta',
      pt: 'malta',
      pl: 'malta',
      'pt-BR': 'malta',
      sv: 'malta'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MMR: {
    iso2: 'mm',
    old_id: 175,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Burma',
      fr: 'Birmanie',
      de: 'Myanmar',
      es: 'Birmania',
      pt: 'Myanmar',
      pl: 'Burma',
      'pt-BR': 'Burma',
      sv: 'Myanmar',
      zh: '缅甸',
      ko: 'Burma',
      ja: 'Burma'
    },
    permalinks: {
      en: 'burma',
      fr: 'birmanie',
      de: 'myanmar',
      es: 'birmania',
      pt: 'myanmar',
      pl: 'burma',
      'pt-BR': 'burma',
      sv: 'myanmar'
    },
    hasRegion: false,
    ins: {}
  },
  MNE: {
    iso2: 'me',
    old_id: 154,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Montenegro',
      fr: 'Monténégro',
      de: 'Montenegro',
      es: 'Montenegro',
      pt: 'Montenegro',
      pl: 'Montenegro',
      'pt-BR': 'Montenegro',
      sv: 'Montenegro',
      zh: '黑山',
      ko: 'Montenegro',
      ja: 'Montenegro'
    },
    permalinks: {
      en: 'montenegro',
      fr: 'montenegro',
      de: 'montenegro',
      es: 'montenegro',
      pt: 'montenegro',
      pl: 'montenegro',
      'pt-BR': 'montenegro',
      sv: 'montenegro'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MNG: {
    iso2: 'mn',
    old_id: 59,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Mongolia',
      fr: 'Mongolie',
      de: 'Mongolei',
      es: 'Mongolia',
      pt: 'Mongólia',
      pl: 'Mongolia',
      'pt-BR': 'Mongolia',
      sv: 'Mongoliet',
      zh: '蒙古',
      ko: 'Mongolia',
      ja: 'Mongolia'
    },
    permalinks: {
      en: 'mongolia',
      fr: 'mongolie',
      de: 'mongolei',
      es: 'mongolia',
      pt: 'mongolia',
      pl: 'mongolia',
      'pt-BR': 'mongolia',
      sv: 'mongoliet'
    },
    hasRegion: false,
    ins: {}
  },
  MNP: {
    iso2: 'mp',
    old_id: 223,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Northern Mariana Islands',
      fr: 'Îles Mariannes du Nord',
      de: 'Nördliche Mariana-Inseln',
      es: 'Islas Marianas del Norte',
      pt: 'Ilhas Marianas do Norte',
      pl: 'Northern Mariana Islands',
      'pt-BR': 'Northern Mariana Islands',
      sv: 'Nordmarianerna',
      zh: '北马里亚纳群岛',
      ko: 'Northern Mariana Islands',
      ja: 'Northern Mariana Islands'
    },
    permalinks: {
      en: 'northern-mariana-islands',
      fr: 'iles-mariannes-du-nord',
      de: 'nordliche-mariana-inseln',
      es: 'islas-marianas-del-norte',
      pt: 'ilhas-marianas-do-norte',
      pl: 'northern-mariana-islands',
      'pt-BR': 'northern-mariana-islands',
      sv: 'nordmarianerna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  MOZ: {
    iso2: 'mz',
    old_id: 218,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Mozambique',
      fr: 'Mozambique',
      de: 'Mosambik',
      es: 'Mozambique',
      pt: 'Moçambique',
      pl: 'Mozambique',
      'pt-BR': 'Mozambique',
      sv: 'Moçambique',
      zh: '莫桑比克',
      ko: 'Mozambique',
      ja: 'Mozambique'
    },
    permalinks: {
      en: 'mozambique',
      fr: 'mozambique',
      de: 'mosambik',
      es: 'mozambique',
      pt: 'mocambique',
      pl: 'mozambique',
      'pt-BR': 'mozambique',
      sv: 'mocambique'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MRT: {
    iso2: 'mr',
    old_id: 215,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Mauritania',
      fr: 'Mauritanie',
      de: 'Mauretanien',
      es: 'Mauritania',
      pt: 'Mauritânia',
      pl: 'Mauritania',
      'pt-BR': 'Mauritania',
      sv: 'Mauretanien',
      zh: '毛里塔尼亚',
      ko: 'Mauritania',
      ja: 'Mauritania'
    },
    permalinks: {
      en: 'mauritania',
      fr: 'mauritanie',
      de: 'mauretanien',
      es: 'mauritania',
      pt: 'mauritania',
      pl: 'mauritania',
      'pt-BR': 'mauritania',
      sv: 'mauretanien'
    },
    hasRegion: false,
    ins: {}
  },
  MSR: {
    iso2: 'ms',
    old_id: 146,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Montserrat',
      fr: 'Montserrat',
      de: 'Montserrat',
      es: 'Montserrat',
      pt: 'Montserrat',
      pl: 'Montserrat',
      'pt-BR': 'Montserrat',
      sv: 'Montserrat',
      zh: '蒙塞拉特岛',
      ko: 'Montserrat',
      ja: 'Montserrat'
    },
    permalinks: {
      en: 'montserrat',
      fr: 'montserrat',
      de: 'montserrat',
      es: 'montserrat',
      pt: 'montserrat',
      pl: 'montserrat',
      'pt-BR': 'montserrat',
      sv: 'montserrat'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MTQ: {
    iso2: 'mq',
    old_id: 120,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Martinique',
      fr: 'Martinique',
      de: 'Martinique',
      es: 'Martinica',
      pt: 'Martinica',
      pl: 'Martinique',
      'pt-BR': 'Martinique',
      sv: 'Martinique',
      zh: '马提尼克',
      ko: 'Martinique',
      ja: 'Martinique'
    },
    permalinks: {
      en: 'martinique',
      fr: 'martinique',
      de: 'martinique',
      es: 'martinica',
      pt: 'martinica',
      pl: 'martinique',
      'pt-BR': 'martinique',
      sv: 'martinique'
    },
    hasRegion: false,
    ins: {}
  },
  MUS: {
    iso2: 'mu',
    old_id: 153,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Mauritius',
      fr: 'Maurice',
      de: 'Mauritius',
      es: 'Mauricio',
      pt: 'Maurícias',
      pl: 'Mauritius',
      'pt-BR': 'Mauritius',
      sv: 'Mauritius',
      zh: '毛里求斯',
      ko: 'Mauritius',
      ja: 'Mauritius'
    },
    permalinks: {
      en: 'mauritius',
      fr: 'maurice',
      de: 'mauritius',
      es: 'mauricio',
      pt: 'mauricias',
      pl: 'mauritius',
      'pt-BR': 'mauritius',
      sv: 'mauritius'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  MWI: {
    iso2: 'mw',
    old_id: 212,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Malawi',
      fr: 'Malawi',
      de: 'Malawi',
      es: 'Malawi',
      pt: 'Malawi',
      pl: 'Malawi',
      'pt-BR': 'Malawi',
      sv: 'Malawi',
      zh: '马拉维',
      ko: 'Malawi',
      ja: 'Malawi'
    },
    permalinks: {
      en: 'malawi',
      fr: 'malawi',
      de: 'malawi',
      es: 'malawi',
      pt: 'malawi',
      pl: 'malawi',
      'pt-BR': 'malawi',
      sv: 'malawi'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  MYS: {
    iso2: 'my',
    old_id: 55,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Malaysia',
      fr: 'Malaisie',
      de: 'Malaysia',
      es: 'Malasia',
      pt: 'Malásia',
      pl: 'Malaysia',
      'pt-BR': 'Malaysia',
      sv: 'Malaysia',
      zh: '马来西亚',
      ko: 'Malaysia',
      ja: 'Malaysia'
    },
    permalinks: {
      en: 'malaysia',
      fr: 'malaisie',
      de: 'malaysia',
      es: 'malasia',
      pt: 'malasia',
      pl: 'malaysia',
      'pt-BR': 'malaysia',
      sv: 'malaysia'
    },
    hasRegion: false,
    ins: {}
  },
  MYT: {
    iso2: 'yt',
    old_id: 216,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Mayotte',
      fr: 'Mayotte',
      de: 'Mayotte',
      es: 'Mayotte',
      pt: 'Mayotte',
      pl: 'Mayotte',
      'pt-BR': 'Mayotte',
      sv: 'Mayotte',
      zh: '马约特',
      ko: 'Mayotte',
      ja: 'Mayotte'
    },
    permalinks: {
      en: 'mayotte',
      fr: 'mayotte',
      de: 'mayotte',
      es: 'mayotte',
      pt: 'mayotte',
      pl: 'mayotte',
      'pt-BR': 'mayotte',
      sv: 'mayotte'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  NAM: {
    iso2: 'na',
    old_id: 114,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Namibia',
      fr: 'Namibie',
      de: 'Namibia',
      es: 'Namibia',
      pt: 'Namíbia',
      pl: 'Namibia',
      'pt-BR': 'Namibia',
      sv: 'Namibia',
      zh: '纳米比亚',
      ko: 'Namibia',
      ja: 'Namibia'
    },
    permalinks: {
      en: 'namibia',
      fr: 'namibie',
      de: 'namibia',
      es: 'namibia',
      pt: 'namibia',
      pl: 'namibia',
      'pt-BR': 'namibia',
      sv: 'namibia'
    },
    hasRegion: false,
    ins: {}
  },
  NCL: {
    iso2: 'nc',
    old_id: 63,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'New Caledonia',
      fr: 'Nouvelle Calédonie',
      de: 'Neukaledonien',
      es: 'Nueva Caledonia',
      pt: 'Nova Caledónia',
      pl: 'New Caledonia',
      'pt-BR': 'New Caledonia',
      sv: 'Nya Kaledonien',
      zh: '新喀里多尼亚',
      ko: 'New Caledonia',
      ja: 'New Caledonia'
    },
    permalinks: {
      en: 'new-caledonia',
      fr: 'nouvelle-caledonie',
      de: 'neukaledonien',
      es: 'nueva-caledonia',
      pt: 'nova-caledonia',
      pl: 'new-caledonia',
      'pt-BR': 'new-caledonia',
      sv: 'nya-kaledonien'
    },
    hasRegion: false,
    ins: {}
  },
  NEP: {
    iso2: 'np',
    old_id: 61,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Nepal',
      fr: 'Népal',
      de: 'Nepal',
      es: 'Nepal',
      pt: 'Nepal',
      pl: 'Nepal',
      'pt-BR': 'Nepal',
      sv: 'Nepal',
      zh: '尼泊尔',
      ko: 'Nepal',
      ja: 'Nepal'
    },
    permalinks: {
      en: 'nepal',
      fr: 'nepal',
      de: 'nepal',
      es: 'nepal',
      pt: 'nepal',
      pl: 'nepal',
      'pt-BR': 'nepal',
      sv: 'nepal'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NER: {
    iso2: 'ne',
    old_id: 178,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Niger',
      fr: 'Niger',
      de: 'Niger',
      es: 'Niger',
      pt: 'Níger',
      pl: 'Niger',
      'pt-BR': 'Niger',
      sv: 'Niger',
      zh: '尼日尔',
      ko: 'Niger',
      ja: 'Niger'
    },
    permalinks: {
      en: 'niger',
      fr: 'niger',
      de: 'niger',
      es: 'niger',
      pt: 'niger',
      pl: 'niger',
      'pt-BR': 'niger',
      sv: 'niger'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NFK: {
    iso2: 'nf',
    old_id: 222,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'Norfolk Island',
      fr: 'Île Norfolk',
      de: 'Norfolkinsel',
      es: 'Isla Norfolk',
      pt: 'Ilha Norfolk',
      pl: 'Norfolk Island',
      'pt-BR': 'Norfolk Island',
      sv: 'Norfolköarna',
      zh: '诺福克岛',
      ko: 'Norfolk Island',
      ja: 'Norfolk Island'
    },
    permalinks: {
      en: 'norfolk-island',
      fr: 'ile-norfolk',
      de: 'norfolkinsel',
      es: 'isla-norfolk',
      pt: 'ilha-norfolk',
      pl: 'norfolk-island',
      'pt-BR': 'norfolk-island',
      sv: 'norfolkoarna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: "dans l'Île Norfolk" }
  },
  NGA: {
    iso2: 'ng',
    old_id: 220,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Nigeria',
      fr: 'Nigéria',
      de: 'Nigeria',
      es: 'Nigeria',
      pt: 'Nigéria',
      pl: 'Nigeria',
      'pt-BR': 'Nigeria',
      sv: 'Nigeria',
      zh: '尼日利亚',
      ko: 'Nigeria',
      ja: 'Nigeria'
    },
    permalinks: {
      en: 'nigeria',
      fr: 'nigeria',
      de: 'nigeria',
      es: 'nigeria',
      pt: 'nigeria',
      pl: 'nigeria',
      'pt-BR': 'nigeria',
      sv: 'nigeria'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NIC: {
    iso2: 'ni',
    old_id: 133,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Nicaragua',
      fr: 'Nicaragua',
      de: 'Nicaragua',
      es: 'Nicaragua',
      pt: 'Nicarágua',
      pl: 'Nicaragua',
      'pt-BR': 'Nicaragua',
      sv: 'Nicaragua',
      zh: '尼加拉瓜',
      ko: 'Nicaragua',
      ja: 'Nicaragua'
    },
    permalinks: {
      en: 'nicaragua',
      fr: 'nicaragua',
      de: 'nicaragua',
      es: 'nicaragua',
      pt: 'nicaragua',
      pl: 'nicaragua',
      'pt-BR': 'nicaragua',
      sv: 'nicaragua'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  NIU: {
    iso2: 'nu',
    old_id: 221,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Niue',
      fr: 'Niue',
      de: 'Niue',
      es: 'Niue',
      pt: 'Niue',
      pl: 'Niue',
      'pt-BR': 'Niue',
      sv: 'Niue',
      zh: '纽埃',
      ko: 'Niue',
      ja: 'Niue'
    },
    permalinks: { en: 'niue', fr: 'niue', de: 'niue', es: 'niue', pt: 'niue', pl: 'niue', 'pt-BR': 'niue', sv: 'niue' },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  NLD: {
    iso2: 'nl',
    old_id: 62,
    subContinent: 155,
    continent: 1,
    names: {
      en: 'Netherlands',
      fr: 'Pays-Bas',
      de: 'Niederlande',
      es: 'Países Bajos',
      pt: 'Países Baixos',
      pl: 'Netherlands',
      'pt-BR': 'Netherlands',
      sv: 'Nederländerna',
      zh: '荷兰',
      ko: 'Netherlands',
      ja: 'Netherlands'
    },
    permalinks: {
      en: 'netherlands',
      fr: 'pays-bas',
      de: 'niederlande',
      es: 'paises-bajos',
      pt: 'paises-baixos',
      pl: 'netherlands',
      'pt-BR': 'netherlands',
      sv: 'nederlanderna'
    },
    hasRegion: true,
    ins: { en: 'in the', fr: 'aux' }
  },
  NOR: {
    iso2: 'no',
    old_id: 66,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Norway',
      fr: 'Norvège',
      de: 'Norwegen',
      es: 'Noruega',
      pt: 'Noruega',
      pl: 'Norway',
      'pt-BR': 'Norway',
      sv: 'Norge',
      zh: '挪威',
      ko: 'Norway',
      ja: 'Norway'
    },
    permalinks: {
      en: 'norway',
      fr: 'norvege',
      de: 'norwegen',
      es: 'noruega',
      pt: 'noruega',
      pl: 'norway',
      'pt-BR': 'norway',
      sv: 'norge'
    },
    hasRegion: false,
    ins: {}
  },
  NPO: {
    iso2: 'xx',
    old_id: 65,
    subContinent: null,
    continent: 1,
    names: {
      en: 'North Pole',
      fr: 'Pôle Nord',
      de: 'North Pole',
      es: 'North Pole',
      pt: 'North Pole',
      pl: 'North Pole',
      'pt-BR': 'North Pole',
      sv: 'North Pole',
      zh: 'North Pole',
      ko: 'North Pole',
      ja: 'North Pole'
    },
    permalinks: {
      en: 'north-pole',
      fr: 'pole-nord',
      de: 'north-pole',
      es: 'north-pole',
      pt: 'north-pole',
      pl: 'north-pole',
      'pt-BR': 'north-pole',
      sv: 'north-pole'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'au' }
  },
  NRU: {
    iso2: 'nr',
    old_id: 219,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Nauru',
      fr: 'Nauru',
      de: 'Nauru',
      es: 'Nauru',
      pt: 'Nauru',
      pl: 'Nauru',
      'pt-BR': 'Nauru',
      sv: 'Nauru',
      zh: '瑙鲁',
      ko: 'Nauru',
      ja: 'Nauru'
    },
    permalinks: {
      en: 'nauru',
      fr: 'nauru',
      de: 'nauru',
      es: 'nauru',
      pt: 'nauru',
      pl: 'nauru',
      'pt-BR': 'nauru',
      sv: 'nauru'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  NZL: {
    iso2: 'nz',
    old_id: 64,
    subContinent: 53,
    continent: 6,
    names: {
      en: 'New Zealand',
      fr: 'Nouvelle Zélande',
      de: 'Neuseeland',
      es: 'Nueva Zelanda',
      pt: 'Nova Zelândia',
      pl: 'New Zealand',
      'pt-BR': 'New Zealand',
      sv: 'Nya Zeeland',
      zh: '新西兰',
      ko: 'New Zealand',
      ja: 'New Zealand'
    },
    permalinks: {
      en: 'new-zealand',
      fr: 'nouvelle-zelande',
      de: 'neuseeland',
      es: 'nueva-zelanda',
      pt: 'nova-zelandia',
      pl: 'new-zealand',
      'pt-BR': 'new-zealand',
      sv: 'nya-zeeland'
    },
    hasRegion: false,
    ins: {}
  },
  OMN: {
    iso2: 'om',
    old_id: 115,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Oman',
      fr: 'Oman',
      de: 'Oman',
      es: 'Omán',
      pt: 'Oman',
      pl: 'Oman',
      'pt-BR': 'Oman',
      sv: 'Oman',
      zh: '阿曼',
      ko: 'Oman',
      ja: 'Oman'
    },
    permalinks: { en: 'oman', fr: 'oman', de: 'oman', es: 'oman', pt: 'oman', pl: 'oman', 'pt-BR': 'oman', sv: 'oman' },
    hasRegion: false,
    ins: {}
  },
  PAK: {
    iso2: 'pk',
    old_id: 67,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Pakistan',
      fr: 'Pakistan',
      de: 'Pakistan',
      es: 'Pakistán',
      pt: 'Paquistão',
      pl: 'Pakistan',
      'pt-BR': 'Pakistan',
      sv: 'Pakistan',
      zh: '巴基斯坦',
      ko: 'Pakistan',
      ja: 'Pakistan'
    },
    permalinks: {
      en: 'pakistan',
      fr: 'pakistan',
      de: 'pakistan',
      es: 'pakistan',
      pt: 'paquistao',
      pl: 'pakistan',
      'pt-BR': 'pakistan',
      sv: 'pakistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PAN: {
    iso2: 'pa',
    old_id: 68,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'Panama',
      fr: 'Panama',
      de: 'Panama',
      es: 'Panamá',
      pt: 'Panamá',
      pl: 'Panama',
      'pt-BR': 'Panama',
      sv: 'Panama',
      zh: '巴拿马',
      ko: 'Panama',
      ja: 'Panama'
    },
    permalinks: {
      en: 'panama',
      fr: 'panama',
      de: 'panama',
      es: 'panama',
      pt: 'panama',
      pl: 'panama',
      'pt-BR': 'panama',
      sv: 'panama'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  PCN: {
    iso2: 'pn',
    old_id: 227,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Pitcairn Islands',
      fr: 'Îles Pitcairn',
      de: 'Pitcairn',
      es: 'Pitcairn',
      pt: 'Pitcairn',
      pl: 'Pitcairn Islands',
      'pt-BR': 'Pitcairn Islands',
      sv: 'Pitcairn',
      zh: '皮特克恩',
      ko: 'Pitcairn Islands',
      ja: 'Pitcairn Islands'
    },
    permalinks: {
      en: 'pitcairn-islands',
      fr: 'iles-pitcairn',
      de: 'pitcairn',
      es: 'pitcairn',
      pt: 'pitcairn',
      pl: 'pitcairn-islands',
      'pt-BR': 'pitcairn-islands',
      sv: 'pitcairn'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  PER: {
    iso2: 'pe',
    old_id: 69,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Peru',
      fr: 'Pérou',
      de: 'Peru',
      es: 'Perú',
      pt: 'Peru',
      pl: 'Peru',
      'pt-BR': 'Peru',
      sv: 'Peru',
      zh: '秘鲁',
      ko: 'Peru',
      ja: 'Peru'
    },
    permalinks: {
      en: 'peru',
      fr: 'perou',
      de: 'peru',
      es: 'peru',
      pt: 'peru',
      pl: 'peru',
      'pt-BR': 'peru',
      sv: 'peru'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PHL: {
    iso2: 'ph',
    old_id: 70,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Philippines',
      fr: 'Philippines',
      de: 'Philippinen',
      es: 'Filipinas',
      pt: 'Filipinas',
      pl: 'Philippines',
      'pt-BR': 'Philippines',
      sv: 'Filippinerna',
      zh: '菲律宾',
      ko: 'Philippines',
      ja: 'Philippines'
    },
    permalinks: {
      en: 'philippines',
      fr: 'philippines',
      de: 'philippinen',
      es: 'filipinas',
      pt: 'filipinas',
      pl: 'philippines',
      'pt-BR': 'philippines',
      sv: 'filippinerna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  PLW: {
    iso2: 'pw',
    old_id: 224,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'Palau',
      fr: 'Palaos',
      de: 'Palau',
      es: 'Palau',
      pt: 'Palau',
      pl: 'Palau',
      'pt-BR': 'Palau',
      sv: 'Palau',
      zh: '帕劳',
      ko: 'Palau',
      ja: 'Palau'
    },
    permalinks: {
      en: 'palau',
      fr: 'palaos',
      de: 'palau',
      es: 'palau',
      pt: 'palau',
      pl: 'palau',
      'pt-BR': 'palau',
      sv: 'palau'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  PNG: {
    iso2: 'pg',
    old_id: 225,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Papua New Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      de: 'Papua-Neuguinea',
      es: 'Papúa Nueva Guinea',
      pt: 'Papua Nova Guiné',
      pl: 'Papua New Guinea',
      'pt-BR': 'Papua New Guinea',
      sv: 'Papua Nya Guinea',
      zh: '巴布亚新几内亚',
      ko: 'Papua New Guinea',
      ja: 'Papua New Guinea'
    },
    permalinks: {
      en: 'papua-new-guinea',
      fr: 'papouasie-nouvelle-guinee',
      de: 'papua-neuguinea',
      es: 'papua-nueva-guinea',
      pt: 'papua-nova-guine',
      pl: 'papua-new-guinea',
      'pt-BR': 'papua-new-guinea',
      sv: 'papua-nya-guinea'
    },
    hasRegion: false,
    ins: {}
  },
  POL: {
    iso2: 'pl',
    old_id: 71,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Poland',
      fr: 'Pologne',
      de: 'Polen',
      es: 'Polonia',
      pt: 'Polónia',
      pl: 'Poland',
      'pt-BR': 'Poland',
      sv: 'Polen',
      zh: '波兰',
      ko: 'Poland',
      ja: 'Poland'
    },
    permalinks: {
      en: 'poland',
      fr: 'pologne',
      de: 'polen',
      es: 'polonia',
      pt: 'polonia',
      pl: 'poland',
      'pt-BR': 'poland',
      sv: 'polen'
    },
    hasRegion: false,
    ins: {}
  },
  PRI: {
    iso2: 'pr',
    old_id: 73,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Puerto Rico',
      fr: 'Porto rico',
      de: 'Puerto Rico',
      es: 'Puerto Rico',
      pt: 'Porto Rico',
      pl: 'Puerto Rico',
      'pt-BR': 'Puerto Rico',
      sv: 'Puerto Rico',
      zh: '波多黎各',
      ko: 'Puerto Rico',
      ja: 'Puerto Rico'
    },
    permalinks: {
      en: 'puerto-rico',
      fr: 'porto-rico',
      de: 'puerto-rico',
      es: 'puerto-rico',
      pt: 'porto-rico',
      pl: 'puerto-rico',
      'pt-BR': 'puerto-rico',
      sv: 'puerto-rico'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  PRK: {
    iso2: 'kp',
    old_id: 205,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'North Korea',
      fr: 'Corée du Nord',
      de: 'Korea, Demokratische Volksrepublik',
      es: 'Corea, República Democrática Popular de',
      pt: 'Coreia, República Popular Democrática de',
      pl: 'North Korea',
      'pt-BR': 'North Korea',
      sv: 'Korea, demokratiska folkrepubliken',
      zh: '朝鲜',
      ko: 'North Korea',
      ja: 'North Korea'
    },
    permalinks: {
      en: 'north-korea',
      fr: 'coree-du-nord',
      de: 'korea-demokratische-volksrepublik',
      es: 'corea-republica-democratica-popular-de',
      pt: 'coreia-republica-popular-democratica-de',
      pl: 'north-korea',
      'pt-BR': 'north-korea',
      sv: 'korea-demokratiska-folkrepubliken'
    },
    hasRegion: false,
    ins: {}
  },
  PRT: {
    iso2: 'pt',
    old_id: 72,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Portugal',
      fr: 'Portugal',
      de: 'Portugal',
      es: 'Portugal',
      pt: 'Portugal',
      pl: 'Portugal',
      'pt-BR': 'Portugal',
      sv: 'Portugal',
      zh: '葡萄牙',
      ko: 'Portugal',
      ja: 'Portugal'
    },
    permalinks: {
      en: 'portugal',
      fr: 'portugal',
      de: 'portugal',
      es: 'portugal',
      pt: 'portugal',
      pl: 'portugal',
      'pt-BR': 'portugal',
      sv: 'portugal'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PRY: {
    iso2: 'py',
    old_id: 226,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Paraguay',
      fr: 'Paraguay',
      de: 'Paraguay',
      es: 'Paraguay',
      pt: 'Paraguai',
      pl: 'Paraguay',
      'pt-BR': 'Paraguay',
      sv: 'Paraguay',
      zh: '巴拉圭',
      ko: 'Paraguay',
      ja: 'Paraguay'
    },
    permalinks: {
      en: 'paraguay',
      fr: 'paraguay',
      de: 'paraguay',
      es: 'paraguay',
      pt: 'paraguai',
      pl: 'paraguay',
      'pt-BR': 'paraguay',
      sv: 'paraguay'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  PSE: {
    iso2: 'ps',
    old_id: 170,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Palestine (State of)',
      fr: 'Palestine',
      de: 'Palästina, Staat',
      es: 'Palestina, Estado de',
      pt: 'Palestina, Autoridade da',
      pl: 'Palestine (State of)',
      'pt-BR': 'Palestine (State of)',
      sv: 'Staten Palestina',
      zh: '巴勒斯坦',
      ko: 'Palestine (State of)',
      ja: 'Palestine (State of)'
    },
    permalinks: {
      en: 'palestine-state-of',
      fr: 'palestine',
      de: 'palastina-staat',
      es: 'palestina-estado-de',
      pt: 'palestina-autoridade-da',
      pl: 'palestine-state-of',
      'pt-BR': 'palestine-state-of',
      sv: 'staten-palestina'
    },
    hasRegion: false,
    ins: { en: 'in Palestine' }
  },
  PYF: {
    iso2: 'pf',
    old_id: 30,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'French Polynesia',
      fr: 'Polynésie Française',
      de: 'Französisch-Polynesien',
      es: 'Polinesia Francesa',
      pt: 'Polinésia Francesa',
      pl: 'French Polynesia',
      'pt-BR': 'French Polynesia',
      sv: 'Franska Polynesien',
      zh: '法属玻利尼西亚',
      ko: 'French Polynesia',
      ja: 'French Polynesia'
    },
    permalinks: {
      en: 'french-polynesia',
      fr: 'polynesie-francaise',
      de: 'franzosisch-polynesien',
      es: 'polinesia-francesa',
      pt: 'polinesia-francesa',
      pl: 'french-polynesia',
      'pt-BR': 'french-polynesia',
      sv: 'franska-polynesien'
    },
    hasRegion: false,
    ins: {}
  },
  QAT: {
    iso2: 'qa',
    old_id: 228,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Qatar',
      fr: 'Qatar',
      de: 'Katar',
      es: 'Qatar',
      pt: 'Quatar',
      pl: 'Qatar',
      'pt-BR': 'Qatar',
      sv: 'Qatar',
      zh: '卡塔尔',
      ko: 'Qatar',
      ja: 'Qatar'
    },
    permalinks: {
      en: 'qatar',
      fr: 'qatar',
      de: 'katar',
      es: 'qatar',
      pt: 'quatar',
      pl: 'qatar',
      'pt-BR': 'qatar',
      sv: 'qatar'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  REU: {
    iso2: 're',
    old_id: 117,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Réunion',
      fr: 'La Réunion',
      de: 'Réunion',
      es: 'Reunión',
      pt: 'Ilha Reunião',
      pl: 'Réunion',
      'pt-BR': 'Réunion',
      sv: 'Réunion',
      zh: '留尼汪',
      ko: 'Réunion',
      ja: 'Réunion'
    },
    permalinks: {
      en: 'reunion',
      fr: 'la-reunion',
      de: 'reunion',
      es: 'reunion',
      pt: 'ilha-reuniao',
      pl: 'reunion',
      'pt-BR': 'reunion',
      sv: 'reunion'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  ROU: {
    iso2: 'ro',
    old_id: 74,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Romania',
      fr: 'Roumanie',
      de: 'Rumänien',
      es: 'Rumanía',
      pt: 'Roménia',
      pl: 'Romania',
      'pt-BR': 'Romania',
      sv: 'Rumänien',
      zh: '罗马尼亚',
      ko: 'Romania',
      ja: 'Romania'
    },
    permalinks: {
      en: 'romania',
      fr: 'roumanie',
      de: 'rumanien',
      es: 'rumania',
      pt: 'romenia',
      pl: 'romania',
      'pt-BR': 'romania',
      sv: 'rumanien'
    },
    hasRegion: false,
    ins: {}
  },
  RUS: {
    iso2: 'ru',
    old_id: 75,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Russia',
      fr: 'Russie',
      de: 'Russische Föderation',
      es: 'Federación Rusa',
      pt: 'Federação Russa',
      pl: 'Russia',
      'pt-BR': 'Russia',
      sv: 'Ryska federationen',
      zh: '俄罗斯',
      ko: 'Russia',
      ja: 'Russia'
    },
    permalinks: {
      en: 'russia',
      fr: 'russie',
      de: 'russische-foderation',
      es: 'federacion-rusa',
      pt: 'federacao-russa',
      pl: 'russia',
      'pt-BR': 'russia',
      sv: 'ryska-federationen'
    },
    hasRegion: false,
    ins: {}
  },
  RWA: {
    iso2: 'rw',
    old_id: 76,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Rwanda',
      fr: 'Rwanda',
      de: 'Ruanda',
      es: 'Ruanda',
      pt: 'Ruanda',
      pl: 'Rwanda',
      'pt-BR': 'Rwanda',
      sv: 'Rwanda',
      zh: '卢旺达',
      ko: 'Rwanda',
      ja: 'Rwanda'
    },
    permalinks: {
      en: 'rwanda',
      fr: 'rwanda',
      de: 'ruanda',
      es: 'ruanda',
      pt: 'ruanda',
      pl: 'rwanda',
      'pt-BR': 'rwanda',
      sv: 'rwanda'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SAU: {
    iso2: 'sa',
    old_id: 144,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Saudi Arabia',
      fr: 'Arabie Saoudite',
      de: 'Saudi-Arabien',
      es: 'Arabia Saudí',
      pt: 'Arábia Saudita',
      pl: 'Saudi Arabia',
      'pt-BR': 'Saudi Arabia',
      sv: 'Saudiarabien',
      zh: '沙特阿拉伯',
      ko: 'Saudi Arabia',
      ja: 'Saudi Arabia'
    },
    permalinks: {
      en: 'saudi-arabia',
      fr: 'arabie-saoudite',
      de: 'saudi-arabien',
      es: 'arabia-saudi',
      pt: 'arabia-saudita',
      pl: 'saudi-arabia',
      'pt-BR': 'saudi-arabia',
      sv: 'saudiarabien'
    },
    hasRegion: false,
    ins: {}
  },
  SDN: {
    iso2: 'sd',
    old_id: 244,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Sudan',
      fr: 'Soudan',
      de: 'Sudan',
      es: 'Sudán',
      pt: 'Sudão',
      pl: 'Sudan',
      'pt-BR': 'Sudan',
      sv: 'Sudan',
      zh: '苏丹',
      ko: 'Sudan',
      ja: 'Sudan'
    },
    permalinks: {
      en: 'sudan',
      fr: 'soudan',
      de: 'sudan',
      es: 'sudan',
      pt: 'sudao',
      pl: 'sudan',
      'pt-BR': 'sudan',
      sv: 'sudan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SEN: {
    iso2: 'sn',
    old_id: 112,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Senegal',
      fr: 'Sénégal',
      de: 'Senegal',
      es: 'Senegal',
      pt: 'Senegal',
      pl: 'Senegal',
      'pt-BR': 'Senegal',
      sv: 'Senegal',
      zh: '塞内加尔',
      ko: 'Senegal',
      ja: 'Senegal'
    },
    permalinks: {
      en: 'senegal',
      fr: 'senegal',
      de: 'senegal',
      es: 'senegal',
      pt: 'senegal',
      pl: 'senegal',
      'pt-BR': 'senegal',
      sv: 'senegal'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SGP: {
    iso2: 'sg',
    old_id: 79,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Singapore',
      fr: 'Singapour',
      de: 'Singapur',
      es: 'Singapur',
      pt: 'Singapura',
      pl: 'Singapore',
      'pt-BR': 'Singapore',
      sv: 'Singapore',
      zh: '新加坡',
      ko: 'Singapore',
      ja: 'Singapore'
    },
    permalinks: {
      en: 'singapore',
      fr: 'singapour',
      de: 'singapur',
      es: 'singapur',
      pt: 'singapura',
      pl: 'singapore',
      'pt-BR': 'singapore',
      sv: 'singapore'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SGS: {
    iso2: 'gs',
    old_id: 456,
    subContinent: null,
    continent: 5,
    names: {
      en: 'South Georgia and the South Sandwich Islands',
      fr: 'Géorgie du Sud et les îles Sandwich du Sud',
      de: 'South Georgia und die Südlichen Sandwichinseln',
      es: 'Georgia del Sur e Islas Sandwitch del Sur',
      pt: 'Geórgia do Sul e Ilhas Sandwich do Sul',
      pl: 'South Georgia and the South Sandwich Islands',
      'pt-BR': 'South Georgia and the South Sandwich Islands',
      sv: 'Sydgeorgien och södra Sandwichöarna',
      zh: '南乔治亚岛和南桑德韦奇岛',
      ko: 'South Georgia and the South Sandwich Islands',
      ja: 'South Georgia and the South Sandwich Islands'
    },
    permalinks: {
      en: 'south-georgia-and-the-south-sandwich-islands',
      fr: 'georgie-du-sud-et-les-iles-sandwich-du-sud',
      de: 'south-georgia-und-die-sudlichen-sandwichinseln',
      es: 'georgia-del-sur-e-islas-sandwitch-del-sur',
      pt: 'georgia-do-sul-e-ilhas-sandwich-do-sul',
      pl: 'south-georgia-and-the-south-sandwich-islands',
      'pt-BR': 'south-georgia-and-the-south-sandwich-islands',
      sv: 'sydgeorgien-och-sodra-sandwichoarna'
    },
    hasRegion: false,
    ins: { fr: 'en Géorgie du Sud et  dans les îles Sandwich du Sud' }
  },
  SHN: {
    iso2: 'sh',
    old_id: 169,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Saint Helena',
      fr: 'Saint Hélène',
      de: 'St. Helena, Ascension und Tristan da Cunha',
      es: 'Santa Elena, Ascensión y Tristán de Acuña',
      pt: 'Santa Helema, Ascenção e Tristão da Cunha',
      pl: 'Saint Helena',
      'pt-BR': 'Saint Helena',
      sv: 'Saint Helena, Ascension och Tristan da Cunha',
      zh: '圣赫勒拿-阿森松-特里斯坦达库尼亚',
      ko: 'Saint Helena',
      ja: 'Saint Helena'
    },
    permalinks: {
      en: 'saint-helena',
      fr: 'saint-helene',
      de: 'st-helena-ascension-und-tristan-da-cunha',
      es: 'santa-elena-ascension-y-tristan-de-acuna',
      pt: 'santa-helema-ascencao-e-tristao-da-cunha',
      pl: 'saint-helena',
      'pt-BR': 'saint-helena',
      sv: 'saint-helena-ascension-och-tristan-da-cunha'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SJM: {
    iso2: 'sj',
    old_id: 458,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Svalbard and Jan Mayen',
      fr: 'Svalbard et île Jan Mayen',
      de: 'Svalbard und Jan Mayen',
      es: 'Svalbard y Jan Mayen',
      pt: 'Svalbard e Jan Mayen',
      pl: 'Svalbard and Jan Mayen',
      'pt-BR': 'Svalbard and Jan Mayen',
      sv: 'Svalbard och Jan Mayen',
      zh: '斯瓦尔巴特和扬马延岛',
      ko: 'Svalbard and Jan Mayen',
      ja: 'Svalbard and Jan Mayen'
    },
    permalinks: {
      en: 'svalbard-and-jan-mayen',
      fr: 'svalbard-et-ile-jan-mayen',
      de: 'svalbard-und-jan-mayen',
      es: 'svalbard-y-jan-mayen',
      pt: 'svalbard-e-jan-mayen',
      pl: 'svalbard-and-jan-mayen',
      'pt-BR': 'svalbard-and-jan-mayen',
      sv: 'svalbard-och-jan-mayen'
    },
    hasRegion: false,
    ins: { fr: 'au Svalbard et dans île Jan Mayen' }
  },
  SLB: {
    iso2: 'sb',
    old_id: 247,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Solomon Islands',
      fr: 'Salomon',
      de: 'Salomoninseln',
      es: 'Islas Salomón',
      pt: 'Ilhas Salomão',
      pl: 'Solomon Islands',
      'pt-BR': 'Solomon Islands',
      sv: 'Salomonöarna',
      zh: '所罗门群岛',
      ko: 'Solomon Islands',
      ja: 'Solomon Islands'
    },
    permalinks: {
      en: 'solomon-islands',
      fr: 'salomon',
      de: 'salomoninseln',
      es: 'islas-salomon',
      pt: 'ilhas-salomao',
      pl: 'solomon-islands',
      'pt-BR': 'solomon-islands',
      sv: 'salomonoarna'
    },
    hasRegion: false,
    ins: { en: 'in the' }
  },
  SLE: {
    iso2: 'sl',
    old_id: 167,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Sierra Leone',
      fr: 'Sierra Leone',
      de: 'Sierra Leone',
      es: 'Sierra Leona',
      pt: 'Serra Leoa',
      pl: 'Sierra Leone',
      'pt-BR': 'Sierra Leone',
      sv: 'Sierra Leone',
      zh: '塞拉利昂',
      ko: 'Sierra Leone',
      ja: 'Sierra Leone'
    },
    permalinks: {
      en: 'sierra-leone',
      fr: 'sierra-leone',
      de: 'sierra-leone',
      es: 'sierra-leona',
      pt: 'serra-leoa',
      pl: 'sierra-leone',
      'pt-BR': 'sierra-leone',
      sv: 'sierra-leone'
    },
    hasRegion: false,
    ins: {}
  },
  SLV: {
    iso2: 'sv',
    old_id: 135,
    subContinent: 13,
    continent: 3,
    names: {
      en: 'El Salvador',
      fr: 'Salvador',
      de: 'El Salvador',
      es: 'El Salvador',
      pt: 'El Salvador',
      pl: 'El Salvador',
      'pt-BR': 'El Salvador',
      sv: 'El Salvador',
      zh: '萨尔瓦多',
      ko: 'El Salvador',
      ja: 'El Salvador'
    },
    permalinks: {
      en: 'el-salvador',
      fr: 'salvador',
      de: 'el-salvador',
      es: 'el-salvador',
      pt: 'el-salvador',
      pl: 'el-salvador',
      'pt-BR': 'el-salvador',
      sv: 'el-salvador'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SMR: {
    iso2: 'sm',
    old_id: 250,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'San Marino',
      fr: 'Saint-Marin',
      de: 'San Marino',
      es: 'San Marino',
      pt: 'San Marino',
      pl: 'San Marino',
      'pt-BR': 'San Marino',
      sv: 'San Marino',
      zh: '圣马力诺',
      ko: 'San Marino',
      ja: 'San Marino'
    },
    permalinks: {
      en: 'san-marino',
      fr: 'saint-marin',
      de: 'san-marino',
      es: 'san-marino',
      pt: 'san-marino',
      pl: 'san-marino',
      'pt-BR': 'san-marino',
      sv: 'san-marino'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SOM: {
    iso2: 'so',
    old_id: 246,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Somalia',
      fr: 'Somalie',
      de: 'Somalia',
      es: 'Somalia',
      pt: 'Somália',
      pl: 'Somalia',
      'pt-BR': 'Somalia',
      sv: 'Somalia',
      zh: '索马里',
      ko: 'Somalia',
      ja: 'Somalia'
    },
    permalinks: {
      en: 'somalia',
      fr: 'somalie',
      de: 'somalia',
      es: 'somalia',
      pt: 'somalia',
      pl: 'somalia',
      'pt-BR': 'somalia',
      sv: 'somalia'
    },
    hasRegion: false,
    ins: {}
  },
  SPM: {
    iso2: 'pm',
    old_id: 252,
    subContinent: null,
    continent: 3,
    names: {
      en: 'Saint Pierre and Miquelon',
      fr: 'Saint Pierre et Miquelon',
      de: 'St. Pierre und Miquelon',
      es: 'San Pedro y Miquelon',
      pt: 'Saint Pierre e Miquelon',
      pl: 'Saint Pierre and Miquelon',
      'pt-BR': 'Saint Pierre and Miquelon',
      sv: 'Sankt Pierre och Miquelon',
      zh: '圣皮埃尔和密克隆',
      ko: 'Saint Pierre and Miquelon',
      ja: 'Saint Pierre and Miquelon'
    },
    permalinks: {
      en: 'saint-pierre-and-miquelon',
      fr: 'saint-pierre-et-miquelon',
      de: 'st-pierre-und-miquelon',
      es: 'san-pedro-y-miquelon',
      pt: 'saint-pierre-e-miquelon',
      pl: 'saint-pierre-and-miquelon',
      'pt-BR': 'saint-pierre-and-miquelon',
      sv: 'sankt-pierre-och-miquelon'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SRB: {
    iso2: 'rs',
    old_id: 78,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Serbia',
      fr: 'Serbie',
      de: 'Serbien',
      es: 'Serbia',
      pt: 'Sérvia',
      pl: 'Serbia',
      'pt-BR': 'Serbia',
      sv: 'Serbien',
      zh: '塞尔维亚',
      ko: 'Serbia',
      ja: 'Serbia'
    },
    permalinks: {
      en: 'serbia',
      fr: 'serbie',
      de: 'serbien',
      es: 'serbia',
      pt: 'servia',
      pl: 'serbia',
      'pt-BR': 'serbia',
      sv: 'serbien'
    },
    hasRegion: false,
    ins: {}
  },
  SSD: {
    iso2: 'ss',
    old_id: 245,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'South Sudan',
      fr: 'Soudan du Sud',
      de: 'Südsudan',
      es: 'Sudán del Sur',
      pt: 'República do Sudão',
      pl: 'South Sudan',
      'pt-BR': 'South Sudan',
      sv: 'Sydsudan',
      zh: '南苏丹',
      ko: 'South Sudan',
      ja: 'South Sudan'
    },
    permalinks: {
      en: 'south-sudan',
      fr: 'soudan-du-sud',
      de: 'sudsudan',
      es: 'sudan-del-sur',
      pt: 'republica-do-sudao',
      pl: 'south-sudan',
      'pt-BR': 'south-sudan',
      sv: 'sydsudan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  STP: {
    iso2: 'st',
    old_id: 249,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'São Tomé and Príncipe',
      fr: 'Sao Tomé-et-Principe',
      de: 'São Tomé und Príncipe',
      es: 'Santo Tomé y Príncipe',
      pt: 'São Tomé e Príncipe',
      pl: 'São Tomé and Príncipe',
      'pt-BR': 'São Tomé and Príncipe',
      sv: 'São Tomé och Príncipe',
      zh: '圣多美和普林西比',
      ko: 'São Tomé and Príncipe',
      ja: 'São Tomé and Príncipe'
    },
    permalinks: {
      en: 'sao-tome-and-principe',
      fr: 'sao-tome-et-principe',
      de: 'sao-tome-und-principe',
      es: 'santo-tome-y-principe',
      pt: 'sao-tome-e-principe',
      pl: 'sao-tome-and-principe',
      'pt-BR': 'sao-tome-and-principe',
      sv: 'sao-tome-och-principe'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SUR: {
    iso2: 'sr',
    old_id: 459,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Suriname',
      fr: 'Surinam',
      de: 'Suriname',
      es: 'Surinám',
      pt: 'Suriname',
      pl: 'Suriname',
      'pt-BR': 'Suriname',
      sv: 'Surinam',
      zh: '苏里南',
      ko: 'Suriname',
      ja: 'Suriname'
    },
    permalinks: {
      en: 'suriname',
      fr: 'surinam',
      de: 'suriname',
      es: 'surinam',
      pt: 'suriname',
      pl: 'suriname',
      'pt-BR': 'suriname',
      sv: 'surinam'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SVK: {
    iso2: 'sk',
    old_id: 80,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Slovakia',
      fr: 'Slovaquie',
      de: 'Slowakei',
      es: 'Eslovaquia',
      pt: 'Eslováquia',
      pl: 'Slovakia',
      'pt-BR': 'Slovakia',
      sv: 'Slovakien',
      zh: '斯洛伐克',
      ko: 'Slovakia',
      ja: 'Slovakia'
    },
    permalinks: {
      en: 'slovakia',
      fr: 'slovaquie',
      de: 'slowakei',
      es: 'eslovaquia',
      pt: 'eslovaquia',
      pl: 'slovakia',
      'pt-BR': 'slovakia',
      sv: 'slovakien'
    },
    hasRegion: false,
    ins: {}
  },
  SVN: {
    iso2: 'si',
    old_id: 81,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Slovenia',
      fr: 'Slovenie',
      de: 'Slowenien',
      es: 'Eslovenia',
      pt: 'Eslovénia',
      pl: 'Slovenia',
      'pt-BR': 'Slovenia',
      sv: 'Slovenien',
      zh: '斯洛文尼亚',
      ko: 'Slovenia',
      ja: 'Slovenia'
    },
    permalinks: {
      en: 'slovenia',
      fr: 'slovenie',
      de: 'slowenien',
      es: 'eslovenia',
      pt: 'eslovenia',
      pl: 'slovenia',
      'pt-BR': 'slovenia',
      sv: 'slovenien'
    },
    hasRegion: false,
    ins: {}
  },
  SWE: {
    iso2: 'se',
    old_id: 84,
    subContinent: 154,
    continent: 1,
    names: {
      en: 'Sweden',
      fr: 'Suède',
      de: 'Schweden',
      es: 'Suecia',
      pt: 'Suécia',
      pl: 'Sweden',
      'pt-BR': 'Sweden',
      sv: 'Sverige',
      zh: '瑞典',
      ko: 'Sweden',
      ja: 'Sweden'
    },
    permalinks: {
      en: 'sweden',
      fr: 'suede',
      de: 'schweden',
      es: 'suecia',
      pt: 'suecia',
      pl: 'sweden',
      'pt-BR': 'sweden',
      sv: 'sverige'
    },
    hasRegion: false,
    ins: {}
  },
  SWZ: {
    iso2: 'sz',
    old_id: 243,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'Swaziland',
      fr: 'Swaziland',
      de: 'Swasiland',
      es: 'Swazilandia',
      pt: 'Suazilândia',
      pl: 'Swaziland',
      'pt-BR': 'Swaziland',
      sv: 'Swaziland',
      zh: '斯威士兰',
      ko: 'Swaziland',
      ja: 'Swaziland'
    },
    permalinks: {
      en: 'swaziland',
      fr: 'swaziland',
      de: 'swasiland',
      es: 'swazilandia',
      pt: 'suazilandia',
      pl: 'swaziland',
      'pt-BR': 'swaziland',
      sv: 'swaziland'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  SXM: {
    iso2: 'sx',
    old_id: 253,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Sint Maarten',
      fr: 'Saint-Martin (Royaume des Pays-Bas)',
      de: 'Saint-Martin (Niederländischer Teil)',
      es: 'Isla de San Martín (zona holandsea)',
      pt: 'São Martinho (Países Baixos)',
      pl: 'Sint Maarten',
      'pt-BR': 'Sint Maarten',
      sv: 'Sint Maarten (nederländska delen)',
      zh: '荷属圣马丁',
      ko: 'Sint Maarten',
      ja: 'Sint Maarten'
    },
    permalinks: {
      en: 'sint-maarten',
      fr: 'saint-martin-royaume-des-pays-bas',
      de: 'saint-martin-niederlandischer-teil',
      es: 'isla-de-san-martin-zona-holandsea',
      pt: 'sao-martinho-paises-baixos',
      pl: 'sint-maarten',
      'pt-BR': 'sint-maarten',
      sv: 'sint-maarten-nederlandska-delen'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  SYC: {
    iso2: 'sc',
    old_id: 248,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Seychelles',
      fr: 'Seychelles',
      de: 'Seychellen',
      es: 'Seychelles',
      pt: 'Seychelles',
      pl: 'Seychelles',
      'pt-BR': 'Seychelles',
      sv: 'Seychellerna',
      zh: '塞舌尔',
      ko: 'Seychelles',
      ja: 'Seychelles'
    },
    permalinks: {
      en: 'seychelles',
      fr: 'seychelles',
      de: 'seychellen',
      es: 'seychelles',
      pt: 'seychelles',
      pl: 'seychelles',
      'pt-BR': 'seychelles',
      sv: 'seychellerna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  SYR: {
    iso2: 'sy',
    old_id: 242,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Syria',
      fr: 'Syrie',
      de: 'Syrien, Arabische Republik',
      es: 'República árabe de Siria',
      pt: 'República Árabe Síria',
      pl: 'Syria',
      'pt-BR': 'Syria',
      sv: 'Syriska arabrepubliken',
      zh: '叙利亚',
      ko: 'Syria',
      ja: 'Syria'
    },
    permalinks: {
      en: 'syria',
      fr: 'syrie',
      de: 'syrien-arabische-republik',
      es: 'republica-arabe-de-siria',
      pt: 'republica-arabe-siria',
      pl: 'syria',
      'pt-BR': 'syria',
      sv: 'syriska-arabrepubliken'
    },
    hasRegion: false,
    ins: {}
  },
  TCA: {
    iso2: 'tc',
    old_id: 236,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Turks and Caicos Islands',
      fr: 'Îles Turques-et-Caïques',
      de: 'Turks- und Caicosinseln',
      es: 'Turks y Caicos, Islas',
      pt: 'Ilhas Turks e Caicos',
      pl: 'Turks and Caicos Islands',
      'pt-BR': 'Turks and Caicos Islands',
      sv: 'Turks- och Caicosöarna',
      zh: '特克斯和凯科斯群岛',
      ko: 'Turks and Caicos Islands',
      ja: 'Turks and Caicos Islands'
    },
    permalinks: {
      en: 'turks-and-caicos-islands',
      fr: 'iles-turques-et-caiques',
      de: 'turks-und-caicosinseln',
      es: 'turks-y-caicos-islas',
      pt: 'ilhas-turks-e-caicos',
      pl: 'turks-and-caicos-islands',
      'pt-BR': 'turks-and-caicos-islands',
      sv: 'turks-och-caicosoarna'
    },
    hasRegion: false,
    ins: { en: 'in the', fr: 'aux' }
  },
  TCD: {
    iso2: 'td',
    old_id: 185,
    subContinent: 17,
    continent: 7,
    names: {
      en: 'Chad',
      fr: 'Tchad',
      de: 'Tschad',
      es: 'Chad',
      pt: 'Chade',
      pl: 'Chad',
      'pt-BR': 'Chad',
      sv: 'Tchad',
      zh: '乍得',
      ko: 'Chad',
      ja: 'Chad'
    },
    permalinks: {
      en: 'chad',
      fr: 'tchad',
      de: 'tschad',
      es: 'chad',
      pt: 'chade',
      pl: 'chad',
      'pt-BR': 'chad',
      sv: 'tchad'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TGO: {
    iso2: 'tg',
    old_id: 240,
    subContinent: 11,
    continent: 7,
    names: {
      en: 'Togo',
      fr: 'Togo',
      de: 'Togo',
      es: 'Togo',
      pt: 'Togo',
      pl: 'Togo',
      'pt-BR': 'Togo',
      sv: 'Togo',
      zh: '多哥',
      ko: 'Togo',
      ja: 'Togo'
    },
    permalinks: { en: 'togo', fr: 'togo', de: 'togo', es: 'togo', pt: 'togo', pl: 'togo', 'pt-BR': 'togo', sv: 'togo' },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  THA: {
    iso2: 'th',
    old_id: 88,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Thailand',
      fr: 'Thailande',
      de: 'Thailand',
      es: 'Tailandia',
      pt: 'Tailândia',
      pl: 'Thailand',
      'pt-BR': 'Thailand',
      sv: 'Thailand',
      zh: '泰国',
      ko: 'Thailand',
      ja: 'Thailand'
    },
    permalinks: {
      en: 'thailand',
      fr: 'thailande',
      de: 'thailand',
      es: 'tailandia',
      pt: 'tailandia',
      pl: 'thailand',
      'pt-BR': 'thailand',
      sv: 'thailand'
    },
    hasRegion: false,
    ins: {}
  },
  TJK: {
    iso2: 'tj',
    old_id: 241,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Tajikistan',
      fr: 'Tajikistan',
      de: 'Tadschikistan',
      es: 'Tadjikistán',
      pt: 'Tadjaquistão',
      pl: 'Tajikistan',
      'pt-BR': 'Tajikistan',
      sv: 'Tadzjikistan',
      zh: '塔吉克斯坦',
      ko: 'Tajikistan',
      ja: 'Tajikistan'
    },
    permalinks: {
      en: 'tajikistan',
      fr: 'tajikistan',
      de: 'tadschikistan',
      es: 'tadjikistan',
      pt: 'tadjaquistao',
      pl: 'tajikistan',
      'pt-BR': 'tajikistan',
      sv: 'tadzjikistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TKL: {
    iso2: 'tk',
    old_id: 239,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Tokelau',
      fr: 'Tokelau',
      de: 'Tokelau',
      es: 'Tokelau',
      pt: 'Tokelau',
      pl: 'Tokelau',
      'pt-BR': 'Tokelau',
      sv: 'Tokelau',
      zh: '托克劳群岛',
      ko: 'Tokelau',
      ja: 'Tokelau'
    },
    permalinks: {
      en: 'tokelau',
      fr: 'tokelau',
      de: 'tokelau',
      es: 'tokelau',
      pt: 'tokelau',
      pl: 'tokelau',
      'pt-BR': 'tokelau',
      sv: 'tokelau'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TKM: {
    iso2: 'tm',
    old_id: 237,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Turkmenistan',
      fr: 'Turkmenistan',
      de: 'Turkmenistan',
      es: 'Turkmenistán',
      pt: 'Turcomenistão',
      pl: 'Turkmenistan',
      'pt-BR': 'Turkmenistan',
      sv: 'Turkmenistan',
      zh: '土库曼斯坦',
      ko: 'Turkmenistan',
      ja: 'Turkmenistan'
    },
    permalinks: {
      en: 'turkmenistan',
      fr: 'turkmenistan',
      de: 'turkmenistan',
      es: 'turkmenistan',
      pt: 'turcomenistao',
      pl: 'turkmenistan',
      'pt-BR': 'turkmenistan',
      sv: 'turkmenistan'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TLS: {
    iso2: 'tl',
    old_id: 128,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'East Timor',
      fr: 'Timor oriental',
      de: 'Timor-Leste',
      es: 'Timor Oriental',
      pt: 'Timor-Leste',
      pl: 'East Timor',
      'pt-BR': 'East Timor',
      sv: 'Östtimor',
      zh: '东帝汶',
      ko: 'East Timor',
      ja: 'East Timor'
    },
    permalinks: {
      en: 'east-timor',
      fr: 'timor-oriental',
      de: 'timor-leste',
      es: 'timor-oriental',
      pt: 'timor-leste',
      pl: 'east-timor',
      'pt-BR': 'east-timor',
      sv: 'osttimor'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TON: {
    iso2: 'to',
    old_id: 238,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Tonga',
      fr: 'Tonga',
      de: 'Tonga',
      es: 'Tonga',
      pt: 'Tonga',
      pl: 'Tonga',
      'pt-BR': 'Tonga',
      sv: 'Tonga',
      zh: '汤加',
      ko: 'Tonga',
      ja: 'Tonga'
    },
    permalinks: {
      en: 'tonga',
      fr: 'tonga',
      de: 'tonga',
      es: 'tonga',
      pt: 'tonga',
      pl: 'tonga',
      'pt-BR': 'tonga',
      sv: 'tonga'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  TTO: {
    iso2: 'tt',
    old_id: 89,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Trinidad and Tobago',
      fr: 'Trinidad et Tobago',
      de: 'Trinidad und Tobago',
      es: 'Trinidad y Tobago',
      pt: 'Trinidade e Tobago',
      pl: 'Trinidad and Tobago',
      'pt-BR': 'Trinidad and Tobago',
      sv: 'Trinidad och Tobago',
      zh: '特里尼达和多巴哥',
      ko: 'Trinidad and Tobago',
      ja: 'Trinidad and Tobago'
    },
    permalinks: {
      en: 'trinidad-and-tobago',
      fr: 'trinidad-et-tobago',
      de: 'trinidad-und-tobago',
      es: 'trinidad-y-tobago',
      pt: 'trinidade-e-tobago',
      pl: 'trinidad-and-tobago',
      'pt-BR': 'trinidad-and-tobago',
      sv: 'trinidad-och-tobago'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  TUN: {
    iso2: 'tn',
    old_id: 90,
    subContinent: 15,
    continent: 7,
    names: {
      en: 'Tunisia',
      fr: 'Tunisie',
      de: 'Tunesien',
      es: 'Tunez',
      pt: 'Tunísia',
      pl: 'Tunisia',
      'pt-BR': 'Tunisia',
      sv: 'Tunisien',
      zh: '突尼斯',
      ko: 'Tunisia',
      ja: 'Tunisia'
    },
    permalinks: {
      en: 'tunisia',
      fr: 'tunisie',
      de: 'tunesien',
      es: 'tunez',
      pt: 'tunisia',
      pl: 'tunisia',
      'pt-BR': 'tunisia',
      sv: 'tunisien'
    },
    hasRegion: false,
    ins: {}
  },
  TUR: {
    iso2: 'tr',
    old_id: 91,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Turkey',
      fr: 'Turquie',
      de: 'Türkei',
      es: 'Turquía',
      pt: 'Turquia',
      pl: 'Turkey',
      'pt-BR': 'Turkey',
      sv: 'Turkiet',
      zh: '土耳其',
      ko: 'Turkey',
      ja: 'Turkey'
    },
    permalinks: {
      en: 'turkey',
      fr: 'turquie',
      de: 'turkei',
      es: 'turquia',
      pt: 'turquia',
      pl: 'turkey',
      'pt-BR': 'turkey',
      sv: 'turkiet'
    },
    hasRegion: false,
    ins: {}
  },
  TUV: {
    iso2: 'tv',
    old_id: 235,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Tuvalu',
      fr: 'Tuvalu',
      de: 'Tuvalu',
      es: 'Tuvalu',
      pt: 'Tuvalu',
      pl: 'Tuvalu',
      'pt-BR': 'Tuvalu',
      sv: 'Tuvalu',
      zh: '图瓦鲁',
      ko: 'Tuvalu',
      ja: 'Tuvalu'
    },
    permalinks: {
      en: 'tuvalu',
      fr: 'tuvalu',
      de: 'tuvalu',
      es: 'tuvalu',
      pt: 'tuvalu',
      pl: 'tuvalu',
      'pt-BR': 'tuvalu',
      sv: 'tuvalu'
    },
    hasRegion: false,
    ins: {}
  },
  TWN: {
    iso2: 'tw',
    old_id: 86,
    subContinent: 30,
    continent: 4,
    names: {
      en: 'Taiwan',
      fr: 'Taiwan',
      de: 'Taiwan',
      es: 'Taiwán',
      pt: 'Taiwan',
      pl: 'Taiwan',
      'pt-BR': 'Taiwan',
      sv: 'Taiwan',
      zh: '台湾',
      ko: 'Taiwan',
      ja: 'Taiwan'
    },
    permalinks: {
      en: 'taiwan',
      fr: 'taiwan',
      de: 'taiwan',
      es: 'taiwan',
      pt: 'taiwan',
      pl: 'taiwan',
      'pt-BR': 'taiwan',
      sv: 'taiwan'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  TZA: {
    iso2: 'tz',
    old_id: 87,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Tanzania',
      fr: 'Tanzanie',
      de: 'Tansania, Vereinigte Republik',
      es: 'Tanzania, República unida de',
      pt: 'Tanzânia, República Unida da',
      pl: 'Tanzania',
      'pt-BR': 'Tanzania',
      sv: 'Tanzania, förenade republiken',
      zh: '坦桑尼亚',
      ko: 'Tanzania',
      ja: 'Tanzania'
    },
    permalinks: {
      en: 'tanzania',
      fr: 'tanzanie',
      de: 'tansania-vereinigte-republik',
      es: 'tanzania-republica-unida-de',
      pt: 'tanzania-republica-unida-da',
      pl: 'tanzania',
      'pt-BR': 'tanzania',
      sv: 'tanzania-forenade-republiken'
    },
    hasRegion: false,
    ins: {}
  },
  UGA: {
    iso2: 'ug',
    old_id: 92,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Uganda',
      fr: 'Ouganda',
      de: 'Uganda',
      es: 'Uganda',
      pt: 'Uganda',
      pl: 'Uganda',
      'pt-BR': 'Uganda',
      sv: 'Uganda',
      zh: '乌干达',
      ko: 'Uganda',
      ja: 'Uganda'
    },
    permalinks: {
      en: 'uganda',
      fr: 'ouganda',
      de: 'uganda',
      es: 'uganda',
      pt: 'uganda',
      pl: 'uganda',
      'pt-BR': 'uganda',
      sv: 'uganda'
    },
    hasRegion: false,
    ins: {}
  },
  UKR: {
    iso2: 'ua',
    old_id: 147,
    subContinent: 151,
    continent: 1,
    names: {
      en: 'Ukraine',
      fr: 'Ukraine',
      de: 'Ukraine',
      es: 'Ucrania',
      pt: 'Ucrânia',
      pl: 'Ukraine',
      'pt-BR': 'Ukraine',
      sv: 'Ukraina',
      zh: '乌克兰',
      ko: 'Ukraine',
      ja: 'Ukraine'
    },
    permalinks: {
      en: 'ukraine',
      fr: 'ukraine',
      de: 'ukraine',
      es: 'ucrania',
      pt: 'ucrania',
      pl: 'ukraine',
      'pt-BR': 'ukraine',
      sv: 'ukraina'
    },
    hasRegion: false,
    ins: {}
  },
  UMI: {
    iso2: 'um',
    old_id: 234,
    subContinent: 57,
    continent: 6,
    names: {
      en: 'United States Minor Outlying Islands',
      fr: 'Îles mineures éloignées des États-Unis',
      de: 'United States Minor Outlying Islands',
      es: 'Islas menores exteriores de Estados Unidos',
      pt: 'Ilhas Menores Exteriores dos Estados Unidos',
      pl: 'United States Minor Outlying Islands',
      'pt-BR': 'United States Minor Outlying Islands',
      sv: 'USA:s avlägsna mindre öar',
      zh: '美国本土外小岛屿',
      ko: 'United States Minor Outlying Islands',
      ja: 'United States Minor Outlying Islands'
    },
    permalinks: {
      en: 'united-states-minor-outlying-islands',
      fr: 'iles-mineures-eloignees-des-etats-unis',
      de: 'united-states-minor-outlying-islands',
      es: 'islas-menores-exteriores-de-estados-unidos',
      pt: 'ilhas-menores-exteriores-dos-estados-unidos',
      pl: 'united-states-minor-outlying-islands',
      'pt-BR': 'united-states-minor-outlying-islands',
      sv: 'usa-s-avlagsna-mindre-oar'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  URY: {
    iso2: 'uy',
    old_id: 98,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Uruguay',
      fr: 'Uruguay',
      de: 'Uruguay',
      es: 'Uruguay',
      pt: 'Uruguai',
      pl: 'Uruguay',
      'pt-BR': 'Uruguay',
      sv: 'Uruguay',
      zh: '乌拉圭',
      ko: 'Uruguay',
      ja: 'Uruguay'
    },
    permalinks: {
      en: 'uruguay',
      fr: 'uruguay',
      de: 'uruguay',
      es: 'uruguay',
      pt: 'uruguai',
      pl: 'uruguay',
      'pt-BR': 'uruguay',
      sv: 'uruguay'
    },
    hasRegion: false,
    ins: {}
  },
  USA: {
    iso2: 'us',
    old_id: 95,
    subContinent: null,
    continent: 3,
    names: {
      en: 'USA',
      fr: 'USA',
      de: 'Vereinigte Staaten',
      es: 'Estados Unidos',
      pt: 'Estados Unidos',
      pl: 'USA',
      'pt-BR': 'USA',
      sv: 'USA',
      zh: '美国',
      ko: 'USA',
      ja: 'USA'
    },
    permalinks: {
      en: 'usa',
      fr: 'usa',
      de: 'vereinigte-staaten',
      es: 'estados-unidos',
      pt: 'estados-unidos',
      pl: 'usa',
      'pt-BR': 'usa',
      sv: 'usa'
    },
    hasRegion: true,
    ins: { en: 'in the ', fr: 'aux' }
  },
  UZB: {
    iso2: 'uz',
    old_id: 233,
    subContinent: 143,
    continent: 4,
    names: {
      en: 'Uzbekistan',
      fr: 'Uzbekistan',
      de: 'Usbekistan',
      es: 'Uzbekistán',
      pt: 'Uzbequistão',
      pl: 'Uzbekistan',
      'pt-BR': 'Uzbekistan',
      sv: 'Uzbekistan',
      zh: '乌兹别克斯坦',
      ko: 'Uzbekistan',
      ja: 'Uzbekistan'
    },
    permalinks: {
      en: 'uzbekistan',
      fr: 'uzbekistan',
      de: 'usbekistan',
      es: 'uzbekistan',
      pt: 'uzbequistao',
      pl: 'uzbekistan',
      'pt-BR': 'uzbekistan',
      sv: 'uzbekistan'
    },
    hasRegion: false,
    ins: {}
  },
  VAT: {
    iso2: 'va',
    old_id: 202,
    subContinent: 39,
    continent: 1,
    names: {
      en: 'Vatican',
      fr: 'Vatican',
      de: 'Heiliger Stuhl (Staat Vatikanstadt)',
      es: 'Santa Sede (Ciudad Estado del Vaticano)',
      pt: 'Santa Sé (Estado Cidade do Vaticano)',
      pl: 'Vatican',
      'pt-BR': 'Vatican',
      sv: 'Vatikanstaten',
      zh: '梵地冈',
      ko: 'Vatican',
      ja: 'Vatican'
    },
    permalinks: {
      en: 'vatican',
      fr: 'vatican',
      de: 'heiliger-stuhl-staat-vatikanstadt',
      es: 'santa-sede-ciudad-estado-del-vaticano',
      pt: 'santa-se-estado-cidade-do-vaticano',
      pl: 'vatican',
      'pt-BR': 'vatican',
      sv: 'vatikanstaten'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  VCT: {
    iso2: 'vc',
    old_id: 251,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Saint Vincent and the Grenadines',
      fr: 'Saint-Vincent-et-les-Grenadines',
      de: 'St. Vincent und die Grenadinen',
      es: 'San Vicente y las Granadinas',
      pt: 'São Vicente e Grenadinas',
      pl: 'Saint Vincent and the Grenadines',
      'pt-BR': 'Saint Vincent and the Grenadines',
      sv: 'Sankt Vincent och Grenadinerna',
      zh: '圣文森特和格林纳丁斯',
      ko: 'Saint Vincent and the Grenadines',
      ja: 'Saint Vincent and the Grenadines'
    },
    permalinks: {
      en: 'saint-vincent-and-the-grenadines',
      fr: 'saint-vincent-et-les-grenadines',
      de: 'st-vincent-und-die-grenadinen',
      es: 'san-vicente-y-las-granadinas',
      pt: 'sao-vicente-e-grenadinas',
      pl: 'saint-vincent-and-the-grenadines',
      'pt-BR': 'saint-vincent-and-the-grenadines',
      sv: 'sankt-vincent-och-grenadinerna'
    },
    hasRegion: false,
    ins: { fr: 'à' }
  },
  VEN: {
    iso2: 've',
    old_id: 149,
    subContinent: null,
    continent: 2,
    names: {
      en: 'Venezuela',
      fr: 'Venezuela',
      de: 'Venezuela, Bolivarische Republik',
      es: 'Venezuela, República Bolivariana de',
      pt: 'Venezuela, República Bolivariana da',
      pl: 'Venezuela',
      'pt-BR': 'Venezuela',
      sv: 'Venezuela, Bolivarianska republiken',
      zh: '委内瑞拉玻利瓦尔共和国',
      ko: 'Venezuela',
      ja: 'Venezuela'
    },
    permalinks: {
      en: 'venezuela',
      fr: 'venezuela',
      de: 'venezuela-bolivarische-republik',
      es: 'venezuela-republica-bolivariana-de',
      pt: 'venezuela-republica-bolivariana-da',
      pl: 'venezuela',
      'pt-BR': 'venezuela',
      sv: 'venezuela-bolivarianska-republiken'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  VGB: {
    iso2: 'vg',
    old_id: 232,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'British Virgin Islands',
      fr: 'Îles Vierges britanniques',
      de: 'Britische Jungferninseln',
      es: 'Islas Vírgenes, Británicas',
      pt: 'Ilhas Virgens, Britânicas',
      pl: 'British Virgin Islands',
      'pt-BR': 'British Virgin Islands',
      sv: 'Jungfruöarna, brittiska',
      zh: '英属维京群岛',
      ko: 'British Virgin Islands',
      ja: 'British Virgin Islands'
    },
    permalinks: {
      en: 'british-virgin-islands',
      fr: 'iles-vierges-britanniques',
      de: 'britische-jungferninseln',
      es: 'islas-virgenes-britanicas',
      pt: 'ilhas-virgens-britanicas',
      pl: 'british-virgin-islands',
      'pt-BR': 'british-virgin-islands',
      sv: 'jungfruoarna-brittiska'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'dans les' }
  },
  VIR: {
    iso2: 'vi',
    old_id: 96,
    subContinent: 29,
    continent: 3,
    names: {
      en: 'Virgin Islands (USA)',
      fr: 'Iles Vierges (US)',
      de: 'Amerikanische Jungferninseln',
      es: 'Islas Vírgenes, de EEUU',
      pt: 'Ilhas Virgens, Estados Unidos',
      pl: 'Virgin Islands (USA)',
      'pt-BR': 'Virgin Islands (USA)',
      sv: 'Jungfruöarna, amerikanska',
      zh: '美属维京群岛',
      ko: 'Virgin Islands (USA)',
      ja: 'Virgin Islands (USA)'
    },
    permalinks: {
      en: 'virgin-islands-usa',
      fr: 'iles-vierges-us',
      de: 'amerikanische-jungferninseln',
      es: 'islas-virgenes-de-eeuu',
      pt: 'ilhas-virgens-estados-unidos',
      pl: 'virgin-islands-usa',
      'pt-BR': 'virgin-islands-usa',
      sv: 'jungfruoarna-amerikanska'
    },
    hasRegion: false,
    ins: { en: 'in the ', fr: 'aux' }
  },
  VNM: {
    iso2: 'vn',
    old_id: 166,
    subContinent: 35,
    continent: 4,
    names: {
      en: 'Vietnam',
      fr: 'Viêt Nam',
      de: 'Vietnam',
      es: 'Vietnam',
      pt: 'Vietname',
      pl: 'Vietnam',
      'pt-BR': 'Vietnam',
      sv: 'Vietnam',
      zh: '越南',
      ko: 'Vietnam',
      ja: 'Vietnam'
    },
    permalinks: {
      en: 'vietnam',
      fr: 'viet-nam',
      de: 'vietnam',
      es: 'vietnam',
      pt: 'vietname',
      pl: 'vietnam',
      'pt-BR': 'vietnam',
      sv: 'vietnam'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  VUT: {
    iso2: 'vu',
    old_id: 138,
    subContinent: 54,
    continent: 6,
    names: {
      en: 'Vanuatu',
      fr: 'Vanuatu',
      de: 'Vanuatu',
      es: 'Vanuatu',
      pt: 'Vanuatu',
      pl: 'Vanuatu',
      'pt-BR': 'Vanuatu',
      sv: 'Vanuatu',
      zh: '瓦努阿图',
      ko: 'Vanuatu',
      ja: 'Vanuatu'
    },
    permalinks: {
      en: 'vanuatu',
      fr: 'vanuatu',
      de: 'vanuatu',
      es: 'vanuatu',
      pt: 'vanuatu',
      pl: 'vanuatu',
      'pt-BR': 'vanuatu',
      sv: 'vanuatu'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  WLF: {
    iso2: 'wf',
    old_id: 231,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Wallis and Futuna',
      fr: 'Wallis et Futuna',
      de: 'Wallis und Futuna',
      es: 'Wallis y Futuna',
      pt: 'Wallis e Futuna',
      pl: 'Wallis and Futuna',
      'pt-BR': 'Wallis and Futuna',
      sv: 'Wallis och Futuna',
      zh: '瓦利斯和富图纳',
      ko: 'Wallis and Futuna',
      ja: 'Wallis and Futuna'
    },
    permalinks: {
      en: 'wallis-and-futuna',
      fr: 'wallis-et-futuna',
      de: 'wallis-und-futuna',
      es: 'wallis-y-futuna',
      pt: 'wallis-e-futuna',
      pl: 'wallis-and-futuna',
      'pt-BR': 'wallis-and-futuna',
      sv: 'wallis-och-futuna'
    },
    hasRegion: false,
    ins: { fr: 'à ' }
  },
  WSM: {
    iso2: 'ws',
    old_id: 77,
    subContinent: 61,
    continent: 6,
    names: {
      en: 'Samoa',
      fr: 'Samoa',
      de: 'Samoa',
      es: 'Samoa',
      pt: 'Samoa',
      pl: 'Samoa',
      'pt-BR': 'Samoa',
      sv: 'Samoa',
      zh: '萨摩亚',
      ko: 'Samoa',
      ja: 'Samoa'
    },
    permalinks: {
      en: 'samoa',
      fr: 'samoa',
      de: 'samoa',
      es: 'samoa',
      pt: 'samoa',
      pl: 'samoa',
      'pt-BR': 'samoa',
      sv: 'samoa'
    },
    hasRegion: false,
    ins: { fr: 'aux' }
  },
  XKX: {
    iso2: 'XK',
    old_id: null,
    subContinent: null,
    continent: 1,
    names: {
      en: 'Kosovo',
      fr: 'Kosovo',
      de: 'Kosovo',
      es: 'Kosovo',
      pt: 'Kosovo',
      pl: 'Kosovo',
      'pt-BR': 'Kosovo',
      sv: 'Kosovo',
      zh: 'Kosovo',
      ko: 'Kosovo',
      ja: 'Kosovo'
    },
    permalinks: {
      en: 'kosovo',
      fr: 'kosovo',
      de: 'kosovo',
      es: 'kosovo',
      pt: 'kosovo',
      pl: 'kosovo',
      'pt-BR': 'kosovo',
      sv: 'kosovo'
    },
    hasRegion: false,
    ins: {}
  },
  YEM: {
    iso2: 'ye',
    old_id: 230,
    subContinent: 145,
    continent: 4,
    names: {
      en: 'Yemen',
      fr: 'Yemen',
      de: 'Jemen',
      es: 'Yemen',
      pt: 'Yémen',
      pl: 'Yemen',
      'pt-BR': 'Yemen',
      sv: 'Yemen',
      zh: '也门',
      ko: 'Yemen',
      ja: 'Yemen'
    },
    permalinks: {
      en: 'yemen',
      fr: 'yemen',
      de: 'jemen',
      es: 'yemen',
      pt: 'yemen',
      pl: 'yemen',
      'pt-BR': 'yemen',
      sv: 'yemen'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  ZAF: {
    iso2: 'za',
    old_id: 82,
    subContinent: 18,
    continent: 7,
    names: {
      en: 'South Africa',
      fr: 'Afrique du Sud',
      de: 'Südafrika',
      es: 'Suráfrica',
      pt: 'África do Sul',
      pl: 'South Africa',
      'pt-BR': 'South Africa',
      sv: 'Sydafrika',
      zh: '南非',
      ko: 'South Africa',
      ja: 'South Africa'
    },
    permalinks: {
      en: 'south-africa',
      fr: 'afrique-du-sud',
      de: 'sudafrika',
      es: 'surafrica',
      pt: 'africa-do-sul',
      pl: 'south-africa',
      'pt-BR': 'south-africa',
      sv: 'sydafrika'
    },
    hasRegion: false,
    ins: {}
  },
  ZMB: {
    iso2: 'zm',
    old_id: 229,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Zambia',
      fr: 'Zambie',
      de: 'Sambia',
      es: 'Zambia',
      pt: 'Zâmbia',
      pl: 'Zambia',
      'pt-BR': 'Zambia',
      sv: 'Zambia',
      zh: '赞比亚',
      ko: 'Zambia',
      ja: 'Zambia'
    },
    permalinks: {
      en: 'zambia',
      fr: 'zambie',
      de: 'sambia',
      es: 'zambia',
      pt: 'zambia',
      pl: 'zambia',
      'pt-BR': 'zambia',
      sv: 'zambia'
    },
    hasRegion: false,
    ins: {}
  },
  ZWE: {
    iso2: 'zw',
    old_id: 97,
    subContinent: 14,
    continent: 7,
    names: {
      en: 'Zimbabwe',
      fr: 'Zimbabwe',
      de: 'Simbabwe',
      es: 'Zimbabue',
      pt: 'Zimbabwe',
      pl: 'Zimbabwe',
      'pt-BR': 'Zimbabwe',
      sv: 'Zimbabwe',
      zh: '津巴布韦',
      ko: 'Zimbabwe',
      ja: 'Zimbabwe'
    },
    permalinks: {
      en: 'zimbabwe',
      fr: 'zimbabwe',
      de: 'simbabwe',
      es: 'zimbabue',
      pt: 'zimbabwe',
      pl: 'zimbabwe',
      'pt-BR': 'zimbabwe',
      sv: 'zimbabwe'
    },
    hasRegion: false,
    ins: { fr: 'au' }
  },
  afg: {
    iso2: 'af',
    old_id: 139,
    subContinent: 34,
    continent: 4,
    names: {
      en: 'Afghanistan',
      fr: 'Afghanistan',
      de: 'Afghanistan',
      es: 'Afganistán',
      pt: 'Afeganistão',
      pl: 'Afghanistan',
      'pt-BR': 'Afghanistan',
      sv: 'Afghanistan',
      zh: '阿富汗',
      ko: 'Afghanistan',
      ja: 'Afghanistan'
    },
    permalinks: {
      en: 'afghanistan',
      fr: 'afghanistan',
      de: 'afghanistan',
      es: 'afganistan',
      pt: 'afeganistao',
      pl: 'afghanistan',
      'pt-BR': 'afghanistan',
      sv: 'afghanistan'
    },
    hasRegion: false,
    ins: { en: '' }
  }
};
export default dict;
