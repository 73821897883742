import { User } from '../contexts/UserProvider/UserContext';
import { getLocalizedProperty } from '../lib/localization';
import CountryRef from '../references/country';
import { Member, MemberCountry } from '../types';

const getCountry = (countryId: number, locale: string): MemberCountry | null => {
  const countryCode = Object.keys(CountryRef).find(key => CountryRef[key].old_id === countryId);

  if (countryCode) {
    const { continent, subContinent, old_id, names, iso2 } = CountryRef[countryCode];

    return {
      continent,
      name: getLocalizedProperty(names, locale),
      old_id,
      sub_continent: subContinent,
      iso2
    };
  }

  return null;
};

const getAvatar = (member: Member) => {
  return member.avatar.includes('no-avatar.png') ? '' : member.avatar;
};

const getUserLocation = ({ country_names, city }: User, locale: string): string => {
  let location = '';
  const country = getLocalizedProperty(country_names, locale);

  if (country && city) {
    location = `${country} / ${city}`;
  } else {
    location = country || city;
  }

  return location;
};

const getUserSocialLinks = (profile: User) => {
  const { facebook, twitter, youtube, instagram, strava } = profile;

  return {
    facebook,
    instagram,
    strava,
    twitter,
    youtube
  };
};

const transformProfileData = (profile: User, locale = 'en'): User => {
  const { country_id, roles } = profile;

  return {
    ...profile,
    avatar: getAvatar(profile),
    country: getCountry(country_id, locale),
    location: getUserLocation(profile, locale),
    organizations: profile.organizations.map(org => ({
      ...org,
      avatar: getAvatar(org),
      country: getCountry(org.country_id, locale)
    })),
    role: roles?.includes('organizer') ? 'Organiser' : 'Athlete', //TODO: Hotfix for user that do not have a role
    socialMedia: getUserSocialLinks(profile),
    racesInBucketList: profile['race_logs'].map(rl => {
      return { id: rl.id, raceId: rl['a_race_id'] };
    })
  };
};

export { getUserLocation, getUserSocialLinks, transformProfileData };
