export interface CountryReference {
  [key: string]: CountryReferenceElement;
}
export enum SPORT_ID {
  RUN = '0',
  WALK = '1',
  NORDIC_WALK = '2',
  MTB = '3',
  CANOEING = '4',
  KAYAKING = '5',
  OBSTACLE_RACE = '6',
  RUN_AND_BIKE = '7',
  DUATHLON = '8',
  TRIATHLON = '9',
  MULTISPORT_RAID = '10',
  CYCLING = '11',
  SWIM_RUN = '12',
  AQUATHLON = '13',
  DOG_RUN = '14',
  WHEELCHAIR = '15',
  ORIENTEERING = '16',
  SWIMMING = '17',
  INLINE_SKATING = '18',
  SUP = '19',
  AQUABIKE = '20',
  RAFTING = '21',
  ALPINE_SKIING = '22',
  TELEMARK = '23',
  XC_SKIING = '24',
  SKI_ORIENTEERING = 'ski_orienteering',
  SKIMO = '26',
  SKI_JUMPING = '27',
  CYCLO_CROSS = '28',
  NORDIC_ICE_SKATING = '29',
  TRAIL_RUN = '30',
  GRAVEL = '31',
  PADDLE_TRIATHLON = '32'
}

export enum UNIT_ID {
  KM = '1',
  M = '2',
  MI = '3',
  FT = '4',
  HOUR = '5',
  DAY = '6',
  STEP = '7',
  STORY = '8',
  YARD = '9',
  MIN = '10'
}

export interface CountryReferenceElement {
  iso2: string;
  old_id: number;
  continent: number;
  names: {
    [key: string]: string;
  };
  permalinks: {
    [key: string]: string;
  };
  subContinent: number;
  hasRegion: boolean;
  ins: {
    [key: string]: string;
  };
  name?: string;
}

export interface SubContinentReference {
  [key: string]: {
    names: {
      [key: string]: string;
    };
    permalinks: {
      [key: string]: string;
    };
    continentId?: number;
    ins: {
      [key: string]: string;
    };
  };
}

export interface AllCountryReference {
  [key: string]: string[];
}

export interface SportReference {
  [key: string]: {
    code: string;
    display: string;
    names: {
      [key: string]: string;
    };
    id: number;
    permalinks: {
      [key: string]: string;
    };
    order: number;
    group: string;
    isFilter: boolean;
    type: SportType | string;
    titles: {
      [key: string]: string;
    };
  };
}
export enum SportType {
  RUN = 'type_run',
  WATER = 'type_water',
  MULTI_SPORT = 'type_multi_sport',
  WINTER = 'type_winter',
  OTHER = 'type_other',
  CYCLING = 'type_cycling'
}

export interface LanguageReference {
  [key: number]: {
    code: string;
    names: {
      [key: string]: string;
    };
    permalinks: {
      [key: string]: string;
    };
  };
}

export interface StandardReference {
  [key: string]: {
    names: {
      [key: string]: string;
    };
    longNames?: {
      [key: string]: string;
    };
    permalinks: {
      [key: string]: string;
    };
    order?: number;
    code?: string;
    group?: string;
    isFilter?: boolean;
    ins?: {
      [key: string]: string;
    };
    plurals?: {
      [key: string]: string;
    };
  };
}

export interface UnitReference {
  [key: string]: {
    id: number;
    coefToKm: number;
    code: string;
    names: {
      [key: string]: string;
    };
    plurals: {
      [key: string]: string;
    };
    short: {
      [key: string]: string;
    };
    type: string;
  };
}

export interface RegionReference {
  [key: string]: {
    code: string;
    country: string;
    parent: number;
    names: {
      [key: string]: string;
    };
    permalinks: {
      [key: string]: string;
    };
    ins: {
      [key: string]: string;
    };
  };
}

export interface OrderReference {
  filterCategory: string[];
  sportType: string[];
}

export interface DestinationReference {
  [key: string]: {
    code: string;
    names: {
      [key: string]: string;
    };
    permalinks: {
      [key: string]: string;
    };
    status: string;
    category: string;
    continent: number;
    subContinent: number;
    country: string;
    region1: number;
    region2: number;
  };
}
