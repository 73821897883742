import { Icon } from '../../types/Icon';

export const AhotwoArrowRightIcon: Icon = ({ className, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill={fill} viewBox="0 0 24 24">
    <path
      fill={fill}
      d="m12.363 5.17-.795.796 5.284 5.284H4.875v1.125h11.977l-5.284 5.284.795.796 6.643-6.642-6.643-6.643Z"
    />
  </svg>
);

export const ArrowLeftAhotuIcon: Icon = ({ fill = '#565D64', className }) => (
  <svg
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    className={className}>
    <g id="_x36_4px_boxes">
      <rect fill="none" width="64" height="64" />
    </g>
    <g id="Production">
      <polygon
        fill={fill}
        points="51,30 19.0605,30 33.1523,15.9082 31.0312,13.7871 13.3184,31.5 31.0312,49.2129 33.1523,47.0918
		19.0605,33 51,33 	"
      />
    </g>
  </svg>
);

export const ArrowRightAhotuIcon: Icon = ({ fill = '#565D64', className }) => (
  <svg
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    className={className}>
    <g id="_x36_4px_boxes">
      <rect fill="none" width="64" height="64" />
    </g>
    <g id="Production">
      <polygon
        fill={fill}
        points="32.9688,13.7871 30.8477,15.9082 44.9395,30 13,30 13,33 44.9395,33 30.8477,47.0918
		32.9688,49.2129 50.6816,31.5 	"
      />
    </g>
  </svg>
);

export const ArrowDropDownIcon: Icon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className}>
    <path d="M24 31.1 12.15 19.3h23.7Z" />
  </svg>
);

export const ArrowRightThinIcon: Icon = ({ className, fill = '#202228' }) => (
  <svg viewBox="0 0 24 24" className={className} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="m8.637 19.533-.774-.816L14.933 12l-7.07-6.717.774-.816L16.567 12l-7.93 7.533Z" fill={fill} />
  </svg>
);

export const ArrowLeftThinIcon: Icon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.363 19.533 7.433 12l7.93-7.533.774.816L9.067 12l7.07 6.717-.774.816Z" fill="#202228" />
  </svg>
);

export const ArrowDownThinIcon: Icon = ({ className, fill = '#565D64' }) => (
  <svg version="1.2" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path fill="none" d="M0 0h64v64H0z" />
    <path fill={fill} d="M32 44.178 11.913 23.033l2.174-2.066L32 39.822l17.913-18.855 2.174 2.066z" />
  </svg>
);

export const ArrowUpThinIcon: Icon = ({ className, fill = '#565D64' }) => (
  <svg version="1.2" className={className} viewBox="0 0 64 64">
    <path fill="none" d="M0 0h64v64H0z" />
    <path fill={fill} d="M32 19.822 11.913 40.967l2.174 2.066L32 24.178l17.913 18.855 2.174-2.066z" />
  </svg>
);

export const ElevationDropIcon: Icon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className={className} viewBox="0 0 24 25">
    <path
      fill="#000"
      fillRule="evenodd"
      d="m21.421 17.468-8.02-8.02L9.648 13.2 2.036 5.59l-.53.53 8.142 8.143 3.753-3.754 7.532 7.532H16.21v.75h5.96V12.79h-.75v4.677Z"
      clipRule="evenodd"
    />
  </svg>
);
export const ElevationIcon: Icon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" className={className} viewBox="0 0 24 24">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M20.933 6.245H16.21v-.75h5.96v5.998h-.75V6.817l-8.02 8.02-3.753-3.754-7.612 7.612-.53-.53 8.142-8.142 3.753 3.753 7.532-7.531Z"
      clipRule="evenodd"
    />
  </svg>
);
