'use strict';
import Image from 'next/image';

import config from '../../../lib/config';

type imageMap = { url: string; alt: string };

export const Images = ({ images, padding, isFirstContentBlock }: ContentImages) => {
  if (images.length === 1) {
    return (
      <div className={`relative w-full ${isFirstContentBlock ? 'pt-[34%]' : 'pt-[50%]'}`}>
        <Image
          src={`${config.CMS_ASSETS_URL}${images[0].url}`}
          layout="fill"
          alt={images[0].alt}
          className="object-cover"
        />
      </div>
    );
  } else if (images.length > 1) {
    if (padding)
      return (
        <div className="grid grid-cols-2 gap-4 px-4 lg:gap-10 lg:px-10">
          {images.map((image, index) => {
            return (
              <div key={index} className={`relative w-full pt-[100%]`}>
                <Image
                  src={`${config.CMS_ASSETS_URL}${image.url}`}
                  layout="fill"
                  alt={image.alt}
                  className="object-cover"
                />
              </div>
            );
          })}
        </div>
      );

    return (
      <div className="flex flex-row justify-center items-center">
        {images.map((image, index) => {
          return (
            <div
              key={index}
              className={`relative w-full ${isFirstContentBlock ? 'pt-[56.25%] sm:pt-[34%]' : 'pt-[50%]'}`}>
              <Image
                src={`${config.CMS_ASSETS_URL}${image.url}`}
                layout="fill"
                alt={image.alt}
                className="object-cover"
              />
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export type ContentImages = {
  images: imageMap[];
  padding: boolean;
  isFirstContentBlock: boolean;
};
