import { Side } from '../../types/enums';
import { Icon } from '../../types/Icon';
import AhotwoProperty from '../atoms/AhotwoProperty';

interface ISettingButtonProps {
  symbole?: string;
  iconRight?: Icon;
  iconLeft?: Icon;
  text: string;
}

const SettingButton = ({ iconRight, iconLeft, symbole, text }: ISettingButtonProps) => {
  const IconComp = iconLeft;
  return (
    <AhotwoProperty iconPosition={Side.right} className="min-w-[150px] w-fit justify-between" icon={iconRight}>
      <div className="flex space-x-2">
        {symbole && <span className="w-6 h-6 text-center">{symbole}</span>}
        {iconLeft && <IconComp className="w-6 h-6" />}
        <span>{text}</span>
      </div>
    </AhotwoProperty>
  );
};

export default SettingButton;
