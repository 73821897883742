import { getCookie } from 'cookies-next';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import getConfig from 'next/config';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { memo, useMemo, useState } from 'react';

import { config } from '~/src/lib/config';
import { PartnerLogo } from '~/src/lib/getPartnerLogos';
import { landingPageRouteLocalizations } from '~/src/pages/landing/[landingPageSlug]';

import { ComposantId, MenuLevel } from '../../lib/globale/constantes';
import { getLocalizedProperty } from '../../lib/localization';
import { DropdownLink as DropdownLinkType } from '../../types/eventPageTypes';
import { TopNavigationMenu } from '../../types/homePageTypes';
import { routes } from '../../utils/routes';
import translatedUrl from '../../utils/translatedUrl';
import useMediaQuery from '../../utils/useMediaQuery';
import AhotuUI from '../AhotuUI';
import AhotuLink from '../atoms/AhotuLink';
import DropdownCurrency from '../atoms/DropdownCurrency';
import DropdownLink from '../atoms/DropdownLink';
import PanelSliderMobile from '../atoms/PanelSliderMobile';
import { Paragraph } from '../atoms/Paragraph';
import { XIcon } from '../icons/icons';
import { GlobeIcon } from '../icons/icons';
import SecondLevelMobileNav from '../molecules/navigation/SecondLevelMobileNav';
import SettingButton from '../molecules/SettingButton';
import NewsletterSection from './NewsletterSection';

interface AhotuFooterProps {
  otherLanguagesUrls: DropdownLinkType[];
  menu: TopNavigationMenu;
  partnerLogos: PartnerLogo[];
}

const { publicRuntimeConfig } = getConfig();

//TODO: use the menu object rather than the context
const AhotuFooter = ({ otherLanguagesUrls, menu, partnerLogos }: AhotuFooterProps) => {
  const { asPath, locale } = useRouter();

  const isMobile = useMediaQuery('md');
  const { t } = useTranslation();

  const sportList = useMemo(
    //Leave each last element of subMenus: the include all sports
    () => flatten(Object.keys(menu).map(key => menu[key].subMenus.slice(0, menu[key].subMenus.length - 1))),
    [menu]
  );

  const currency = getCookie('currency');

  const currentLocale = otherLanguagesUrls.find(option => option.locale === locale)?.name;

  //Add # to scroll to the footer after locale update
  otherLanguagesUrls = otherLanguagesUrls.map(option => ({ ...option, href: option.href + '#footerSettings' }));

  const chunkValue = useMemo(() => Math.ceil(sportList.length / 4), [sportList]);

  const showNewsletterSection = !(
    asPath.match('members') ||
    asPath.match('membres') ||
    asPath.match('profile') ||
    asPath.match('profil')
  );
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mobileMenuLevel, setMobileMenuLevel] = useState('');

  const showLocaleSliderMenu = () => {
    setMobileMenuLevel(MenuLevel.LOCALES);
    setShowMobileMenu(true);
  };

  const showCurrenciesSliderMenu = () => {
    setMobileMenuLevel(MenuLevel.CURRENCIES);
    setShowMobileMenu(true);
  };

  const closeFooterSliderMenu = () => setShowMobileMenu(false);

  return (
    <div className="mt-16">
      {showNewsletterSection && <NewsletterSection />}
      <div className="flex flex-row justify-center flex-wrap h-full w-full py-10 pt-8 bg-gray-5 ">
        <div className="w-full mx-6 lg:mx-0 lg:w-4/6">
          {partnerLogos.length > 0 && <FooterLogos partnerLogos={partnerLogos} />}
          <div className=" mb-4 mt-4 md:mt-0 flex justify-start">
            <span className="font-normal text-lg md:text-xl">{t('footer:sports')}</span>
          </div>
          <div className="w-full flex flex-wrap md:flex-row">
            {chunk(sportList, chunkValue).map((chunk, i) => (
              <div key={i} className="w-1/2 lg:w-1/4 space-y-3 mb-10 lg:mb-0">
                {chunk.map(sport => (
                  <Link key={sport.name} href={translatedUrl('sport', locale, sport.id)} passHref>
                    <a className="flex items-center justify-start">
                      <Paragraph className="cursor-pointer hover:underline hover:underline-offset-2  mb-0 pb-0">
                        {sport.name}
                      </Paragraph>
                    </a>
                  </Link>
                ))}
              </div>
            ))}
          </div>
          <div className="w-full flex flex-wrap lg:flex-row mt-20 lg:mt-10 ">
            <div className="w-1/2 lg:w-1/4 flex flex-col items-start justify-between  mb-10 lg:mb-0">
              <div className="flex flex-col space-y-4">
                <span className="font-normal text-lg md:text-xl">{t('footer:the-company')}</span>
                <div>
                  <AhotuLink className="hover:underline" href="/about-us">
                    {t('footer:about-us')}
                  </AhotuLink>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <span className="font-normal text-lg md:text-xl">{t('footer:stay-updated')}</span>
                <div>
                  <AhotuLink className="hover:underline" href={getLocalizedProperty(routes.news, locale)}>
                    News
                  </AhotuLink>
                </div>
              </div>
            </div>
            <div className="w-1/2 lg:w-1/4 flex flex-col items-start mb-10 lg:mb-0">
              <span className="font-normal text-lg md:text-xl mb-5">{t('footer:support')}</span>
              <div className="space-y-3">
                <a
                  href=" https://help.ahotu.com/"
                  className="cursor-pointer m-0 p-0 antialiased hover:underline hover:underline-offset-2 "
                  target="_blank"
                  rel="noreferrer">
                  {t('footer:help-center')}
                </a>
                <Paragraph className="cursor-pointer mb-0">
                  <a
                    target="_blank"
                    href={getLocalizedProperty(routes.privacyPolicy, locale)}
                    rel="noreferrer"
                    className="hover:underline hover:underline-offset-2 ">
                    {t('footer:privacy-policy')}
                  </a>
                </Paragraph>
                <Paragraph className="cursor-pointer mb-0">
                  <a
                    target="_blank"
                    href={getLocalizedProperty(routes.cookiePolicy, locale)}
                    rel="noreferrer"
                    className="hover:underline hover:underline-offset-2 ">
                    {t('footer:cookie-policy')}
                  </a>
                </Paragraph>
                <Paragraph className="cursor-pointer mb-0">
                  <a
                    target="_blank"
                    href="https://help.ahotu.com/article/53-terms-of-service"
                    rel="noreferrer"
                    className="hover:underline hover:underline-offset-2 ">
                    {t('footer:terms-of-service')}
                  </a>
                </Paragraph>
              </div>
            </div>
            <div className="w-1/2 lg:w-1/4 flex flex-col items-start">
              <span className="font-normal mb-5 text-lg md:text-xl">{t('footer:follow-us')}</span>
              <div className="space-y-3 flex flex-col">
                <a
                  href="https://www.facebook.com/ahotu.official/"
                  className="cursor-pointer m-0 p-0 antialiased hover:underline hover:underline-offset-2 "
                  target="_blank"
                  rel="noreferrer">
                  Facebook
                </a>
                <a
                  href="https://www.instagram.com/ahotu_/"
                  className="cursor-pointer m-0 p-0 antialiased hover:underline hover:underline-offset-2 "
                  target="_blank"
                  rel="noreferrer">
                  Instagram
                </a>
                <a
                  href="https://twitter.com/ahotu"
                  className="cursor-pointer mb-0 antialiased hover:underline hover:underline-offset-2 "
                  target="_blank"
                  rel="noreferrer">
                  Twitter
                </a>
                <a
                  href="https://www.strava.com/clubs/530651"
                  className="cursor-pointer mb-0 antialiased hover:underline hover:underline-offset-2 "
                  target="_blank"
                  rel="noreferrer">
                  Strava
                </a>
              </div>
            </div>
            <div className="w-1/2 lg:w-1/4 flex flex-col items-start">
              <span className="font-normal mb-5 text-lg md:text-xl">{t('footer:settings')}</span>
              <div className="space-y-3" id={ComposantId.FOOTER_SETTINGS}>
                {isMobile ? (
                  <>
                    <div onClick={showLocaleSliderMenu}>
                      <SettingButton iconLeft={GlobeIcon} text={currentLocale} />
                    </div>
                    <div onClick={showCurrenciesSliderMenu}>
                      <SettingButton symbole="$" text={currency} />
                    </div>
                  </>
                ) : (
                  <>
                    <DropdownLink
                      active={currentLocale}
                      links={otherLanguagesUrls}
                      className="right-auto left-0"
                      icon={GlobeIcon}
                    />
                    <DropdownCurrency />
                  </>
                )}
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a href="#" className="cky-banner-element block">
                    Cookies
                  </a>
                }
              </div>
            </div>
          </div>
          <div className="text-xs mt-14">
            <p className="text-gray-50 mb-6 ">{t('footer:currency-disclaimer')}</p>
            <p className="text-gray-80">{t('footer:legal-statement')}</p>
            <p className="text-gray-80">{t('footer:version', publicRuntimeConfig)}</p>
          </div>
        </div>
        <div className="1/6"></div>

        {showMobileMenu && (
          <PanelSliderMobile side="right">
            <div className={` bg-white z-20 p-6`}>
              <div className="flex flex-col w-[100%]">
                <div className="flex flex-row justify-end" onClick={closeFooterSliderMenu}>
                  <XIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <SecondLevelMobileNav
                    setMobileMenu={setShowMobileMenu}
                    setLevel={setMobileMenuLevel}
                    level={mobileMenuLevel}
                    title={`$ ${t('footer:currency')}`}
                    showReturnButton={false}
                    shouldScrollTo={ComposantId.FOOTER_SETTINGS}
                  />
                </div>
              </div>
            </div>
          </PanelSliderMobile>
        )}
      </div>
    </div>
  );
};

export default memo(AhotuFooter);

function FooterLogos({ partnerLogos }: { partnerLogos: PartnerLogo[] }) {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <div className={'py-8'}>
      <p className={`text-center mb-6 xl:mb-8 ${AhotuUI.typography.body['b-16']}`}>{t('footer:partners-header')}</p>
      <div className="flex justify-center gap-[72px]">
        {partnerLogos
          .sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
          .map((logo, index) =>
            logo.href && logo.href !== '' && logo.isClickable ? (
              <Link key={index} href={`/${landingPageRouteLocalizations['/'][locale]}/${logo.href}`}>
                <div className="relative h-8 w-[70px] lg:h-[64px] lg:w-[140px] cursor-pointer">
                  <Image
                    alt={logo.alt}
                    src={`${config.CMS_ASSETS_URL}${logo.imageId}`}
                    className={'h-10 w-10 object-contain'}
                    layout={'fill'}
                    width={'50vw'}
                  />
                </div>
              </Link>
            ) : (
              <div key={index} className="relative h-8 w-[70px] lg:h-[64px] lg:w-[140px]">
                <Image
                  alt={logo.alt}
                  src={`${config.CMS_ASSETS_URL}${logo.imageId}`}
                  className={'h-10 w-10 object-contain'}
                  layout={'fill'}
                  width={'50vw'}
                />
              </div>
            )
          )}
      </div>
    </div>
  );
}
