import clsxm from '../../lib/clsxm';
import { Size, StatusLevel } from '../../types/enums';

interface IStatus {
  level: StatusLevel.info | StatusLevel.warning;
  children: React.ReactNode;
  className?: string;
  size?: Size.sm | Size.base;
}

const LevelMapper = {
  [StatusLevel.info]: 'bg-blue-5',
  [StatusLevel.warning]: 'bg-purple-10'
};

const SizeMapper = {
  [Size.sm]: 'p-2',
  [Size.base]: 'p-4'
};

const Status = ({ level = StatusLevel.info, children, className, size = Size.sm }: IStatus) => {
  return <div className={clsxm('w-full p-4 rounded', LevelMapper[level], SizeMapper[size], className)}>{children}</div>;
};

export default Status;
